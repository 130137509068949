import { createLegacyInstance } from "druva-axios-services";
import { CSRF_METHODS } from "constants/common";
import { ROUTE_LOGIN } from "constants/routes";

const baseURL =
  process.env.REACT_APP_MOCK_SERVER === "true"
    ? "/api"
    : process.env.NODE_ENV === "test"
      ? "http://localhost/api/"
      : process.env.REACT_APP_API_BASE_URL;

const apiInstance = createLegacyInstance(baseURL);

const accessTokenFetcher = () =>
  apiInstance
    .post("commonlogin/session", {
      request: { method: "GetAccessToken" }
    })
    .then((result) => {
      return result.accessToken;
    });

const loginUrlFetcher = () => Promise.resolve(ROUTE_LOGIN);

apiInstance.setupAccessTokenFetcher(accessTokenFetcher);

apiInstance.setupLoginUrlFetcher(loginUrlFetcher);

apiInstance.setUnauthorizationDecider((config) => {
  const requestMethod = config.method;
  let apiMethod = null;

  if (requestMethod === "get") {
    apiMethod = config?.params?.input?.request?.method;
  } else if (requestMethod === "post") {
    if (typeof config?.data === "string") {
      const json = JSON.parse(config?.data);
      apiMethod = json?.request?.method;
    } else {
      apiMethod = config?.data?.request?.method;
    }
  }

  return !CSRF_METHODS.includes(apiMethod);
});

export { apiInstance };
