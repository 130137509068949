import errorMap from "helpers/errorMap";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useToast } from "slices/toast.slice";

const UrlErrorHandler = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { notifyError } = useToast();
  const errorCode = searchParams.get("error");

  React.useEffect(() => {
    if (errorCode) {
      errorMap[errorCode] && notifyError(errorMap[errorCode]);
      searchParams.delete("error");
      setSearchParams(searchParams);
    }
  }, [errorCode, searchParams, setSearchParams, notifyError]);

  return null;
};

export default UrlErrorHandler;
