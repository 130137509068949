const state = {
  initial: "idle",
  states: {
    idle: {
      invoke: {
        id: "doSamlLogin",
        src: "loginUsingSaml",
        input: ({ context }) => {
          const authResponse = context.authTypeResponse;
          const customer = context.adminIdentifier;
          let account = null;

          if (authResponse?.accounts?.length === 1) {
            account = authResponse?.accounts[0];
          } else if (customer && authResponse?.accounts?.length > 1) {
            account = authResponse?.accounts.find(
              (acc) => acc.adminIdentifier === customer.adminIdentifier
            );
          } else if (customer) {
            account = customer;
          }
          return {
            account,
            callerProductId: context.callerProductId,
            sourceUrl: context.sourceUrl
          };
        },

        onDone: {
          target: "success",
          actions: "assignSamlLoginResponse"
        },
        onError: {
          target: "failure",
          actions: "assignSamlLoginError"
        }
      }
    },
    success: {
      entry: "redirectToSamlProvider"
    },
    failure: {
      entry: "notifySamlLoginError",
      always: "#login.emailScene"
    }
  }
};

export default state;
