import { useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Label from "orion/lib/label";
import Input from "orion/lib/input";
import Form from "orion/lib/form";
import FormGroup from "orion/lib/formGroup";
import Button from "orion/lib/button";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";

import { isElementInvalid } from "helpers/utils";
import { notifyInfo } from "slices/toast.slice";
import { useFetchInsyncProductUrlMutation } from "queries/login.queries";
import { useLoginMachine, useOtherAccount } from "./LoginMachineProvider";
import AuthCard from "shared/AuthCard";
import LoginButton from "shared/LoginButton";
import useProductConfig from "hooks/useProductConfig";

const EmailFormFields = ({ validationProps, loginForOtherAcc }) => {
  const inputRef = useRef(null);
  const { send, state } = useLoginMachine();
  const isLoading =
    state.matches("emailScene.loading") ||
    state.matches("emailScene.loggingOut");

  const submitForm = useCallback(
    (email, notUsingSSO) => {
      if (loginForOtherAcc) {
        send({
          type: "LOGOUT"
        });
      } else {
        send({
          type: "SUBMIT",
          email,
          notUsingSSO
        });
      }
    },
    [loginForOtherAcc, send]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <FormGroup className="oui-w-100 oui-mb-3 oui-mt-2 form-group-height">
        {loginForOtherAcc ? (
          <Label text={validationProps.values.email} dataTestId="email-label" />
        ) : (
          <Input
            data-testid="email-input"
            innerRef={inputRef}
            type="text"
            placeholder="Email Address"
            name="email"
            validationProps={validationProps}
            isInvalid={isElementInvalid(validationProps, "email")}
            autoComplete="off"
          />
        )}
      </FormGroup>
      <LoginButton
        dataTestId="submit-btn"
        label="Next"
        className="oui-mr-0 oui-mt-2 oui-mb-4"
        isLoading={isLoading}
        disabled={isElementInvalid(validationProps, "email") || isLoading}
        onClick={
          isElementInvalid(validationProps, "email")
            ? null
            : () => submitForm(validationProps.values.email, false)
        }
      />
      {!loginForOtherAcc && (
        <div className="oui-text-center">
          <a
            data-testid="not-using-sso-link"
            href="/"
            onClick={(e) => {
              e.preventDefault();

              if (!isElementInvalid(validationProps, "email")) {
                submitForm(validationProps.values.email, true);
              }
            }}
          >
            Not using Single Sign-On?
          </a>
        </div>
      )}
    </>
  );
};

const FormFields = ({ loginForOtherAcc }) => {
  const { state } = useLoginMachine();
  const { newProductNames, oldProductNames } = useOtherAccount();

  const { mutate: fetchInsyncUrl } = useFetchInsyncProductUrlMutation();
  return (
    <Form
      dataTestId="email-form"
      withValidation
      initialValues={{ email: state.context.email }}
      enableReinitialize
      validationSchema={(Yup) =>
        Yup.object().shape({
          email: Yup.string()
            .required("Enter an email address.")
            .email("Enter a valid email address.")
        })
      }
      renderChildren={(validationProps) => {
        return (
          <AuthCard
            dataTestId="email-form-card"
            title={
              loginForOtherAcc
                ? `Sign in to your ${newProductNames} account`
                : "Sign in to your account"
            }
            hasWarningFooter={loginForOtherAcc}
            footer={() =>
              loginForOtherAcc ? (
                <div
                  className="oui-py-1 oui-px-3 oui-d-flex"
                  data-testid="email-form-card-footer"
                >
                  <div className="oui-flex-grow-1">
                    <InfoGreyOutline className="oui-mr-2" />
                  </div>
                  <div>
                    Switching to your {newProductNames} account will terminate
                    your active {oldProductNames} session
                  </div>
                </div>
              ) : (
                <Button
                  type="link"
                  disabled={!!validationProps.errors.email}
                  dataTestId="sign-in-as-insync-user"
                  className="footer-link oui-p-0 oui-h-auto"
                  onClick={() => fetchInsyncUrl(validationProps.values?.email)}
                  label="Sign in as inSync End User"
                />
              )
            }
          >
            <h4 data-testid="card-title">
              {loginForOtherAcc
                ? `Sign in to your ${newProductNames} account`
                : "Sign in to your account"}
            </h4>
            <div>
              <EmailFormFields
                validationProps={validationProps}
                loginForOtherAcc={loginForOtherAcc}
              />
            </div>
            <div className="oui-py-3" />
          </AuthCard>
        );
      }}
    />
  );
};

const EmailScene = ({ loginForOtherAcc }) => {
  const { state, send } = useLoginMachine();
  const dispatch = useDispatch();
  const { isDell } = useProductConfig();

  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const { callerProductId, sourceUrl } = state.context;
  // Show looks like you have dell/druva acc message
  useEffect(() => {
    if (email) {
      dispatch(
        notifyInfo(
          `Looks like you have a ${
            isDell ? "Dell EMC" : "Druva"
          } account. Continue to sign in here.`
        )
      );
      setSearchParams({
        ...(callerProductId ? { caller_product_id: callerProductId } : {}),
        ...(sourceUrl ? { source_url: sourceUrl } : {})
      });
      send({
        type: "SUBMIT",
        email
      });
    }
  }, [
    email,
    dispatch,
    isDell,
    send,
    setSearchParams,
    callerProductId,
    sourceUrl
  ]);

  return <FormFields loginForOtherAcc={loginForOtherAcc} />;
};

export default EmailScene;
