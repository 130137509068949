import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "orion/lib/loader";
import ReactCardFlip from "react-card-flip";
import {
  LoginMachineProvider,
  useLoginMachine,
  useOtherAccount
} from "./LoginMachineProvider";
import { setRedirectURL } from "slices/app.slice";
import AuthLayout from "components/AuthLayout";
import EmailScene from "./EmailScene";
import ChooseProductScene from "./ChooseProductScene";
import PasswordScene from "./PasswordScene";
import AccountLockedScene from "./AccountLockedScene";
import SetupTOTPScene from "./SetupTOTPScene";
import EnterOTPScene from "./EnterOTPScene";
import ChangePassword from "components/ChangePassword";
import LicenseAgreement from "components/LicenseAgreement";
import { FedrampDisclaimerWithMachine } from "components/FedrampDisclaimer";
import ForgotPasswordSuccessScene from "./ForgotPasswordSuccessScene";
import ForgotPasswordErrorScene from "./ForgotPasswordErrorScene";
import RegisterPhoneNumberScene from "./RegisterPhoneNumberScene";

const LoginScenes = () => {
  const [frontScene, setFrontScene] = useState(null);
  const [backScene, setBackScene] = useState(null);
  const [flipped, setFlipped] = useState(false);
  const dispatch = useDispatch();
  const { state } = useLoginMachine();
  const otherAccounts = useSelector((state) => state.app.otherAccounts);
  const loginForOtherAcc = otherAccounts !== null;
  const redirectUrl = state.context.loginResponse?.redirectUrl;
  const pageLoadState = useRef(state);
  let scene = null;

  const isSceneEmail = state.matches("emailScene");
  const isSceneSaml = state.matches("samlScene");
  const isSceneChooseProd = state.matches("chooseProductScene");
  const isScenePassword = state.matches("passwordScene");
  const isSceneAccLocked = state.matches("accountLockedScene");
  const isSceneSetupTotp = state.matches("setupTOTPScene");
  const isSceneEnterOtp = state.matches("enterOTPScene");
  const isSceneChangePassword = state.matches("changePasswordScene");
  const isSceneFedrampDisclaimer = state.matches("fedrampDisclaimerScene");
  const isSceneTermsAndConditions = state.matches("termsAndConditionsScene");
  const isSceneForgotPasswordSuccess = state.matches(
    "forgotPasswordSuccessScene"
  );
  const isSceneForgotPasswordError = state.matches("forgotPasswordErrorScene");
  const isSceneSetupSMS = state.matches("setupSMSScene");

  if (isSceneEmail) {
    scene = <EmailScene loginForOtherAcc={loginForOtherAcc} />;
  }

  if (isSceneSaml) {
    scene = (
      <Loader isLoading>
        <EmailScene />
      </Loader>
    );
  }

  if (isSceneChooseProd) {
    scene = <ChooseProductScene />;
  }

  if (isScenePassword) {
    scene = <PasswordScene loginForOtherAcc={loginForOtherAcc} />;
  }

  if (isSceneAccLocked) {
    scene = <AccountLockedScene />;
  }

  if (isSceneSetupTotp) {
    scene = <SetupTOTPScene />;
  }

  if (isSceneEnterOtp) {
    scene = <EnterOTPScene />;
  }

  if (isSceneChangePassword) {
    scene = <ChangePassword />;
  }

  if (isSceneFedrampDisclaimer) {
    scene = <FedrampDisclaimerWithMachine />;
  }

  if (isSceneForgotPasswordSuccess) {
    scene = <ForgotPasswordSuccessScene />;
  }

  if (isSceneForgotPasswordError) {
    scene = <ForgotPasswordErrorScene />;
  }
  if (isSceneSetupSMS) {
    scene = <RegisterPhoneNumberScene />;
  }
  if (isSceneTermsAndConditions) {
    scene = <LicenseAgreement />;
  }

  useEffect(() => {
    if (
      (!pageLoadState.current.matches("emailScene.idle") && isSceneEmail) ||
      isScenePassword ||
      isSceneEnterOtp ||
      isSceneChooseProd
    ) {
      if (flipped) {
        setFrontScene(scene);
      } else {
        setBackScene(scene);
      }
      setFlipped(!flipped);
    } else {
      if (flipped) {
        setBackScene(scene);
      } else {
        setFrontScene(scene);
      }
    }
    pageLoadState.current = state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSceneEmail,
    isSceneSaml,
    isSceneChooseProd,
    isScenePassword,
    isSceneAccLocked,
    isSceneSetupTotp,
    isSceneEnterOtp,
    isSceneChangePassword,
    isSceneFedrampDisclaimer,
    isSceneForgotPasswordSuccess,
    isSceneForgotPasswordError
  ]);

  useEffect(() => {
    dispatch(setRedirectURL(redirectUrl));
  }, [redirectUrl, dispatch]);

  if (state.matches("loginSuccessScene")) {
    return <Loader fullPage isLoading />;
  }

  if (process.env.NODE_ENV === "test") {
    return pageLoadState.current.matches("fedrampDisclaimerScene") ||
      pageLoadState.current.matches("termsAndConditionsScene") ? (
      scene
    ) : (
      <AuthLayout>{scene}</AuthLayout>
    );
  }

  return pageLoadState.current.matches("fedrampDisclaimerScene") ||
    pageLoadState.current.matches("termsAndConditionsScene") ? (
    scene
  ) : (
    <AuthLayout>
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
        <>{frontScene}</>
        <>{backScene}</>
      </ReactCardFlip>
    </AuthLayout>
  );
};

const LoginPage = () => {
  const { initialContext } = useOtherAccount();
  const [searchParams] = useSearchParams();
  let callerProductId = searchParams.get("caller_product_id");
  callerProductId = callerProductId ? parseInt(callerProductId, 10) : null;
  const sourceUrl = searchParams.get("source_url");
  const email = searchParams.get("email");

  if (email) {
    initialContext.email = email;
  }

  return (
    <LoginMachineProvider
      key={initialContext.email}
      initialContext={{ ...initialContext, callerProductId, sourceUrl }}
    >
      <LoginScenes />
    </LoginMachineProvider>
  );
};

export default LoginPage;
