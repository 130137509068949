import Label from "orion/lib/label";
import AuthCard from "shared/AuthCard";
import { ReactComponent as TooManyAttempts } from "assets/svgs/password-token-expired.svg";
import BackToLogin from "./BackToLogin";

const ErrorTooManyAttempts = () => {
  return (
    <AuthCard
      dataTestId="forgot-password-success-card"
      cardContentClass="oui-px-2"
    >
      <div className="oui-text-center">
        <TooManyAttempts
          style={{ width: "72px" }}
          className="oui-mt-4 oui-mb-4"
        />
        <strong className="oui-d-block">Too Many Attempts</strong>
        <Label
          className="oui-mt-2"
          muted
          text="Unavailable because of too many attempts were made. Please try after some time."
        />
      </div>
      <div className="oui-py-3">
        <BackToLogin />
      </div>
    </AuthCard>
  );
};

export default ErrorTooManyAttempts;
