import { assign } from "xstate";
import { store } from "../../../store";
import { notifyError } from "slices/toast.slice";
import errorMap from "helpers/errorMap";

const notifyAuthTypeError = ({ context: { authTypeError } }) => {
  const code = authTypeError?.code;

  if (errorMap[code]) {
    store.dispatch(notifyError(errorMap[code]));
  }
};

const notifyLogoutForOtherAccError = ({
  context: { logoutForOtherAccError }
}) => {
  const code = logoutForOtherAccError?.code;

  if (errorMap[code]) {
    store.dispatch(notifyError(errorMap[code]));
  }
};

const assignEmail = assign({
  email: ({ event }) => event.email,
  notUsingSSO: ({ event }) => event.notUsingSSO,
  adminIdentifier: null
});

const assignAuthTypeResponse = assign({
  authTypeResponse: ({ event }) => event.output
});

const assignAuthTypeError = assign({
  authTypeError: ({ event }) => event.error
});

const assignLogoutForOtherAccError = assign({
  logoutForOtherAccError: ({ event }) => event.error
});

const shouldRedirectToDellOrDruva = ({ context }) => {
  if (context.authTypeResponse?.redirectUrl) {
    window.location.href = context.authTypeResponse?.redirectUrl;
  }
};

export {
  assignEmail,
  assignAuthTypeResponse,
  assignAuthTypeError,
  notifyAuthTypeError,
  assignLogoutForOtherAccError,
  notifyLogoutForOtherAccError,
  shouldRedirectToDellOrDruva
};
