import "orion/lib/styles/orion.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import queryClient from "helpers/queryClient";
import ToastManager from "shared/ToastManager";
import { loadGainsightScript, loadGoogleTagManager } from "helpers/script";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import AppRoutes from "./AppRoutes";
import AppProvider from "components/AppProvider";
import UrlErrorHandler from "shared/UrlErrorHandler";

loadGainsightScript();
loadGoogleTagManager();

async function main() {
  if (process.env.REACT_APP_MOCK_SERVER === "true") {
    if (window.location.pathname === "/login") {
      window.location.pathname = "/login/";
      return;
    }
    const { worker } = require("./mocks/browser");
    await worker.start({
      serviceWorker: {
        url: `${process.env.PUBLIC_URL}/mockServiceWorker.js`
      }
    });
  }

  const root = createRoot(document.getElementById("root"));

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <AppRoutes />
            <UrlErrorHandler />
          </AppProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
        <ToastManager />
      </Provider>
    </BrowserRouter>
  );
}
main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
