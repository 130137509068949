import { useEffect, useRef } from "react";
import cx from "classnames";
import Input from "orion/lib/input";
import Label from "orion/lib/label";
import Form from "orion/lib/form";
import Button from "orion/lib/button";
import Loader from "orion/lib/loader";
import Tooltip from "orion/lib/tooltip";
import FormGroup from "orion/lib/formGroup";
import { ReactComponent as LeftArrow } from "assets/svgs/back.svg";
import { isElementInvalid } from "helpers/utils";
import { useLoginMachine, useOtherAccount } from "./LoginMachineProvider";
import AuthCard from "shared/AuthCard";
import LoginButton from "shared/LoginButton";

const checkIfForgotPasswordIsAllowed = (
  email,
  adminIdentifier,
  authResponse
) => {
  if (authResponse?.accounts?.length > 1) {
    return authResponse?.accounts.find(
      (acc) => acc.adminIdentifier === adminIdentifier
    )?.isForgotPasswordAllowed;
  }

  if (authResponse?.accounts?.length === 1) {
    return authResponse?.accounts[0].isForgotPasswordAllowed;
  }

  return authResponse?.isForgotPasswordAllowed;
};

const FormFields = ({ loginForOtherAcc }) => {
  const inputRef = useRef(null);
  const { state, send } = useLoginMachine();
  const isLoading = state.matches("passwordScene.loading");
  const emailIdentifier = state.context?.adminIdentifier?.adminIdentifier;
  const email = emailIdentifier
    ? emailIdentifier.replace(/([^:]*:){2}/, "")
    : state.context.email;
  const isForgotPasswordAllowed = checkIfForgotPasswordIsAllowed(
    email,
    emailIdentifier,
    state.context?.authTypeResponse
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Form
      dataTestId="password-form"
      withValidation
      initialValues={{ password: "" }}
      validationSchema={(Yup) =>
        Yup.object().shape({
          password: Yup.string().required("This field is required")
        })
      }
      onSubmit={({ password }) => {
        send({
          type: "SUBMIT",
          password
        });
      }}
      renderChildren={(validationProps) => {
        return (
          <>
            <Tooltip text={email} placement="auto" overflowOnly>
              <Label
                dataTestId="email-label"
                text={email}
                className={cx(
                  "oui-w-100",
                  "oui-mt-1",
                  "oui-text-truncate",
                  isForgotPasswordAllowed ? "oui-mb-2" : "oui-mb-3"
                )}
              />
            </Tooltip>

            <FormGroup
              className={cx(
                "oui-w-100",
                "oui-mt-2",
                "form-group-height-sm",
                isForgotPasswordAllowed ? "oui-mb-2" : "oui-mb-4"
              )}
            >
              <Input
                data-testid="password-input"
                innerRef={inputRef}
                name="password"
                type="password"
                placeholder="Password"
                validationProps={validationProps}
                isInvalid={isElementInvalid(validationProps, "password")}
              />
            </FormGroup>
            {isForgotPasswordAllowed && (
              <FormGroup className="oui-mb-4 oui-text-right">
                <Button
                  type="link"
                  label="Forgot Password?"
                  className="oui-p-0 oui-h-auto"
                  dataTestId="forgot-password-button"
                  onClick={(e) => {
                    e.preventDefault();
                    send({
                      type: "FORGOT_PASSWORD"
                    });
                  }}
                />
              </FormGroup>
            )}
            <LoginButton
              dataTestId="submit-btn"
              label="Sign in"
              className="oui-mr-0 oui-mt-0"
              isLoading={isLoading}
              disabled={
                isElementInvalid(validationProps, "password") || isLoading
              }
              onClick={
                isElementInvalid(validationProps, "password")
                  ? null
                  : validationProps.submitForm
              }
            />
          </>
        );
      }}
    />
  );
};

const PasswordScene = ({ loginForOtherAcc }) => {
  const { newProductNames } = useOtherAccount();
  const { send, state } = useLoginMachine();
  const isForgotPasswordLoading = state.matches(
    "passwordScene.initiateForgotPassword"
  );
  return (
    <Loader isLoading={isForgotPasswordLoading}>
      <AuthCard
        dataTestId="password-form-card"
        loginForOtherAcc={loginForOtherAcc}
      >
        <h4 data-testid="card-title">
          {loginForOtherAcc
            ? `Sign in to your ${newProductNames} account`
            : "Sign in to your account"}
        </h4>
        <div>
          <FormFields loginForOtherAcc={loginForOtherAcc} />
        </div>
        <div className="oui-py-3">
          {!loginForOtherAcc && (
            <div className="oui-text-center">
              <div
                data-testid="back-btn"
                className="back-btn"
                onClick={() => {
                  send({ type: "BACK" });
                }}
              >
                <LeftArrow width={12} />
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Back
                </a>
              </div>
            </div>
          )}
        </div>
      </AuthCard>
    </Loader>
  );
};

export default PasswordScene;
