const state = {
  initial: "idle",
  states: {
    idle: {
      on: {
        RESEND: {
          target: "loading"
        }
      }
    },
    loading: {
      invoke: {
        id: "forgotPasswordRetry",
        src: "forgotPassword",
        input: ({ context: { email, adminIdentifier } }) => ({
          email,
          adminIdentifier
        }),
        onDone: {
          target: "idle",
          actions: "assignForgotPasswordResponse"
        },
        onError: {
          target: "#login.forgotPasswordErrorScene",
          actions: "assignForgotPasswordError"
        }
      }
    }
  }
};

export default state;
