import React from "react";
import Label from "orion/lib/label";
import Loader from "orion/lib/loader";
import Form from "orion/lib/form";
import FormGroup from "orion/lib/formGroup";
import PhoneInput from "orion/lib/phoneInput";

import { ReactComponent as LeftArrow } from "assets/svgs/back.svg";
import { useLoginMachine } from "./LoginMachineProvider";
import AuthCard from "shared/AuthCard";
import LoginButton from "shared/LoginButton";
import AuthLayout from "components/AuthLayout";
import { invalidPhoneNumberPattern } from "constants/common";

const FormFields = () => {
  const { state, send } = useLoginMachine();
  const isLoading = state.matches("setupSMSScene.updatePhoneNumber");
  const handleSubmit = (e) => {
    send({ type: "UPDATE", phoneNumber: `+${e.phoneNumber}` });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      withValidation
      initialValues={{ phoneNumber: "" }}
      validationSchema={(Yup) =>
        Yup.object().shape({
          phoneNumber: Yup.string().matches(
            invalidPhoneNumberPattern,
            "Invalid phone number"
          )
        })
      }
      renderChildren={(props) => {
        const {
          values: { phoneNumber },
          touched,
          errors
        } = props;

        return (
          <React.Fragment>
            <h4 data-testid="card-title">Register mobile number</h4>
            <FormGroup className="oui-w-100 oui-mb-4 form-group-height">
              <Label text="SMS based Multifactor Authentication is enabled for your organization. Register your mobile number to receive OTP." />
            </FormGroup>
            <FormGroup className="oui-w-100 form-group-height">
              <PhoneInput
                value={phoneNumber}
                isInvalid={!!touched.phoneNumber && !!errors.phoneNumber}
                name="phoneNumber"
                validationProps={props}
              />
            </FormGroup>
            <FormGroup className="oui-w-100 oui-mb-1 form-group-height">
              <LoginButton
                dataTestId="submit-btn"
                label="Send OTP"
                className="oui-mt-0 "
                disabled={
                  isLoading ||
                  Object.keys(errors).length > 0 ||
                  phoneNumber.length === 0
                }
              />
            </FormGroup>
          </React.Fragment>
        );
      }}
    ></Form>
  );
};

const RegisterPhoneNumberScene = () => {
  const { state, send } = useLoginMachine();
  const isLoading = state.matches("setupSMSScene.updatePhoneNumber");
  return (
    <AuthLayout>
      <Loader isLoading={isLoading}>
        <AuthCard
          dataTestId="register-phone-number-form-card"
          className="oui-h-auto"
        >
          <FormFields />
          <div className="oui-text-center oui-mb-4">
            <div
              data-testid="back-btn"
              className="back-btn"
              onClick={() => {
                send({ type: "BACK" });
              }}
            >
              <LeftArrow width={12} />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Back
              </a>
            </div>
          </div>
        </AuthCard>
      </Loader>
    </AuthLayout>
  );
};

export default RegisterPhoneNumberScene;
