export const ERR_PASSWORD_TOKEN_UNAUTHORIZED = 4296015912;
export const ERR_PASSWORD_TOKEN_NOT_PROVIDED = 4296015881;
export const ERR_SESSION_EXPIRED = 4294971397;
export const ERR_INVALID_OTP = 4297064474;
export const ERR_ACCOUNT_LOCKED = 4296015893;
export const ERR_INVALID_CREDENTIALS = 4295032832;
export const ERR_INCORRECT_OLD_PASSWORD = 4296015889;
export const ERR_LOGGED_OFF = 4294971396;
export const ERR_PRODUCT_NOT_LICENSED = 4296015883;
export const ERR_UNKNOWN_PRODUCT_NAME = 4296015877;
export const ERR_CLIENT_IP_NOT_ALLOWED = 4296015903;
export const ERR_ACCESS_TOKEN_EXPIRED = 4294971402;
export const ERR_INTERNAL_APP_ERROR = 4294967297;
export const ERR_PASSWORD_POLICY_UNMACHED = 4296015895;
export const ERR_STASH_SAML_RESPONSE = 4297064458;
export const ERR_ECUSTOMERACCESS_RESTRICTED = 4296015908;

export const ERR_FPWD_TOKEN_INVALID = 4297064486;
export const ERR_FPWD_TOKEN_EXPIRED = 4297064487;
export const ERR_FPWD_TOO_MANY_ATTEMPTS = 4297064484;
export const ERR_FPWD_RESET_NEEDED = 4297064488;
export const ERR_FPWD_USER_INACTIVE = 4297064485;
export const ERR_FPWD_POLICY_UNMATCHED = 4296015895;
export const ERR_NO_PRODUCT_LICENSE_ATTACHED = 4296015956;
export const ERR_SAML_IDP_SETTINGS_UNDEFINED = 4297064448;
export const ERR_API_NOT_IMPLEMENTED = 4294971403;
export const ERR_PHONE_NUMBER_ALREADY_REGISTERED = 4297064492;
export const ERR_ACTIVATION_LINK_EXPIRED = 4297064469;
