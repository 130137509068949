import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, Outlet } from "react-router-dom";
import Loader from "orion/lib/loader";
import { useCheckGlobalSessionQuery } from "queries/session.queries";
import {
  setCurrentAccount,
  setOtherAccounts,
  setRedirection
} from "slices/app.slice";

const CheckGlobalSession = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sourceUrl = searchParams.get("source_url");
  let callerProductId = searchParams.get("caller_product_id");
  callerProductId = callerProductId ? parseInt(callerProductId, 10) : null;

  const {
    data: sessionResponse,
    isFetching,
    error
  } = useCheckGlobalSessionQuery({
    caller_product_id: callerProductId,
    source_url: sourceUrl
  });

  // Set current user in redux if returned
  useEffect(() => {
    if (sessionResponse?.currentAccount) {
      dispatch(setCurrentAccount(sessionResponse?.currentAccount));
    }

    if (sessionResponse?.otherAccounts) {
      dispatch(setOtherAccounts(sessionResponse?.otherAccounts));
    }
  }, [sessionResponse, dispatch]);

  // Set caller product id and source url for further usage
  useEffect(() => {
    if (callerProductId || sourceUrl) {
      dispatch(
        setRedirection({
          callerProductId: parseInt(callerProductId, 10),
          sourceUrl
        })
      );
    }
  }, [dispatch, callerProductId, sourceUrl]);

  if (isFetching) {
    return <Loader fullPage isLoading />;
  }

  if (sessionResponse?.warning || error || sessionResponse?.otherAccounts) {
    return <Outlet />;
  }

  return null;
};

export default CheckGlobalSession;
