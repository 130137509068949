const state = {
  initial: "idle",
  states: {
    idle: {
      entry: [
        "redirectToDashboardFromPasswordScene",
        "redirectToDashboardFromOTPScene",
        "redirectToDashboardFromFedrampScene"
      ]
    }
  }
};

export default state;
