import { rest } from "msw";

const GetStashedSAMLResponse = {
  method: "GetStashedSAMLResponse",
  response: {
    samlResponse: "This is saml response"
  }
};

const getHandler = rest.get("/api/commonlogin/samlconsume", (req, res, ctx) => {
  return res(ctx.status(200), ctx.json(GetStashedSAMLResponse));
});

export { getHandler };
