import Label from "orion/lib/label";
import AuthCard from "shared/AuthCard";
import { ReactComponent as ServiceUnavailable } from "assets/svgs/service-unavailable.svg";
import BackToLogin from "./BackToLogin";

const ErrorPasswordResetNeeded = () => {
  return (
    <AuthCard
      dataTestId="forgot-password-success-card"
      cardContentClass="oui-px-2"
    >
      <div className="oui-text-center">
        <ServiceUnavailable className="oui-mt-4 oui-mb-4" />
        <strong className="oui-d-block">Something Went Wrong</strong>
        <Label
          className="oui-mt-2"
          muted
          text="Password cannot be reset due to error in the backend. Kindly initiate the password reset request again."
        />
      </div>
      <div className="oui-py-3">
        <BackToLogin />
      </div>
    </AuthCard>
  );
};

export default ErrorPasswordResetNeeded;
