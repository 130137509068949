import { assign } from "xstate";
import { store } from "../../../store";
import { notifyError } from "slices/toast.slice";
import errorMap from "helpers/errorMap";
import { ERR_SESSION_EXPIRED, ERR_INVALID_OTP } from "constants/errors";
import { ROUTE_LOGIN } from "constants/routes";
import { buildRemainingAttemptsMessageForOTP } from "utils/errorMessages";

const notifySubmitOTPError = ({ context: { submitOTPError } }) => {
  const code = submitOTPError?.code;

  if (code === ERR_INVALID_OTP && submitOTPError.error) {
    const message = buildRemainingAttemptsMessageForOTP(submitOTPError.error);
    store.dispatch(notifyError(message));
  } else if (errorMap[code]) {
    store.dispatch(notifyError(errorMap[code]));
  }

  if (code === ERR_SESSION_EXPIRED) {
    window.location.href = ROUTE_LOGIN;
  }
};

const assignOTP = assign({
  otp: ({ event }) => parseInt(event.otp, 10)
});

const clearOTP = assign({ otp: "" });

const assignSubmitOTPResponse = assign({
  submitOTPResponse: ({ event }) => event.output
});

const assignSubmitOTPError = assign({
  submitOTPError: ({ event }) => event.error
});

const expireOTPAfterSeconds = assign({
  expireOTPAfterSeconds: (_, event) => event.seconds
});

export {
  assignOTP,
  clearOTP,
  assignSubmitOTPResponse,
  assignSubmitOTPError,
  expireOTPAfterSeconds,
  notifySubmitOTPError
};
