import { rest } from "msw";

const checkGlobalSession = rest.get(
  "/api/commonlogin/session",
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        method: "CheckGlobalSession",
        error: {
          validationFailures: [],
          code: 4294971396,
          extraData: null,
          message: "You have been logged off. Please login again. (#100001004)",
          type: "AUTH_ERR",
          isTemporary: false
        }
      })
    );
  }
);

const getAccessToen = rest.post("/api/commonlogin/session", (req, res, ctx) => {
  const method = req.body.request.method;

  if (method === "GetAccessToken") {
    return res(
      ctx.status(200),
      ctx.json({
        method: "GetAccessToken",
        response: {
          accessToken:
            "YmFmOGFiM2NiODUzM2QyZTAzNDRDNzpzai1KaExEbXB0MnJhTTdhd2YxVk1BPT0="
        }
      })
    );
  }
});

const handlers = [checkGlobalSession, getAccessToen];

export default handlers;
