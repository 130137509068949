import { QueryClient, setLogger } from "react-query";

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {}
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default queryClient;
