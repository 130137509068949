const MAX_PASSWORD_LENGTH = 64;

const MaxCharsFeedback = ({ password = "" }) => {
  const remainingChars = Math.max(0, MAX_PASSWORD_LENGTH - password.length);

  return (
    <div className="custom-input-feedback oui-text-muted oui-py-1">
      Maximum {MAX_PASSWORD_LENGTH} characters{" "}
      {remainingChars !== MAX_PASSWORD_LENGTH && (
        <div
          className={
            remainingChars === 0
              ? "oui-text-danger oui-d-inline-block"
              : "oui-d-inline-block"
          }
        >
          ({remainingChars} remaining)
        </div>
      )}
    </div>
  );
};

export default MaxCharsFeedback;
