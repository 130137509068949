import { assign } from "xstate";

const assignSoftwareTokenResponse = assign({
  softwareTokenResponse: ({ event }) => event.output
});

const assignSoftwareTokenError = assign({
  softwareTokenError: ({ event }) => event.error
});

export { assignSoftwareTokenResponse, assignSoftwareTokenError };
