import useProductConfig from "hooks/useProductConfig";

const FooterLinks = () => {
  const { footerLinks } = useProductConfig();
  const year = new Date().getFullYear();

  return (
    <div className="auth-layout__content--footer">
      &copy; {year}{" "}
      <a
        href="https://www.druva.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Druva Inc.
      </a>{" "}
      All rights reserved.
      <ul>
        <li>
          <a
            href={footerLinks?.privacyPolicyLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <span id="teconsent" />
        </li>
        <li>
          <a
            href={footerLinks?.supportLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterLinks;
