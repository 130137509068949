import { rest } from "msw";

const passwordPolicyResponse = {
  method: "GetPolicy",
  response: {
    minSymbols: 1,
    minUpperChars: 1,
    minChangeInPassword: 0,
    minLowerChars: 1,
    minLength: 12,
    id: 62,
    accountLockDuration: 1800,
    forceStrongPassword: true,
    minPasswordAge: 1,
    passwordHistorySize: 24,
    policyEnabled: true,
    forceChangeTempPassword: true,
    policyMandatory: true,
    maxPasswordAge: 1,
    minDigits: 1,
    wrongPasswordRetryThreshold: 5,
    customerId: 62,
    minPasswordLength: 12,
    isIdmgmtMigrated: true
  }
};

const handler = rest.get(
  "/api/commonlogin/customer/passwordpolicy",
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(passwordPolicyResponse));
  }
);

export default handler;
