import { apiInstance } from "helpers/request.helpers";

const setPassword = (args) =>
  apiInstance.post("commonlogin/admin", {
    request: {
      method: "ChangePasswordUsingToken",
      args
    }
  });

const changePassword = (args) =>
  apiInstance.post("commonlogin/admin", {
    request: {
      method: "ChangePassword",
      args
    }
  });

const fetchAuthType = (email, callerProductId, sourceUrl) =>
  apiInstance.get("commonlogin/admin", {
    params: {
      input: {
        request: {
          method: "GetAuthType",
          args: [email, callerProductId, sourceUrl]
        }
      }
    }
  });

const acceptLicenseAgreement = (redirectUrl) => {
  return apiInstance.post("commonlogin/admin", {
    request: {
      method: "AcceptLogin",
      args: [redirectUrl, false]
    }
  });
};

const acceptFedrampDisclaimer = (redirectUrl) => {
  return apiInstance.post("commonlogin/admin", {
    request: {
      method: "PostLoginSplash",
      args: [redirectUrl, false]
    }
  });
};

const validateForgotPasswordToken = ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, { token }] = queryKey;
  return apiInstance.post("commonlogin/admin", {
    request: {
      method: "ValidateForgotPasswordToken",
      kwargs: {
        token
      }
    }
  });
};

const confirmForgotPassword = ({ password, token }) => {
  return apiInstance.post("commonlogin/admin", {
    request: {
      method: "ConfirmForgotPassword",
      kwargs: {
        token,
        new_password: password
      }
    }
  });
};

export {
  setPassword,
  changePassword,
  fetchAuthType,
  acceptLicenseAgreement,
  acceptFedrampDisclaimer,
  validateForgotPasswordToken,
  confirmForgotPassword
};
