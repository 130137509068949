const state = {
  initial: "idle",
  on: {
    SUBMIT: {
      target: ".loading",
      actions: "assignEmail"
    },
    LOGOUT: {
      target: ".loggingOut"
    }
  },
  states: {
    idle: {},
    loading: {
      invoke: {
        id: "getAuthType",
        input: ({ context: { email, callerProductId, sourceUrl } }) => ({
          email,
          callerProductId,
          sourceUrl
        }),
        src: "fetchAuthType",
        onDone: {
          target: "success",
          actions: "assignAuthTypeResponse"
        },
        onError: {
          target: "failure",
          actions: "assignAuthTypeError"
        }
      }
    },
    success: {
      always: [
        {
          target: "#login.passwordScene",
          guard: "shouldLoginUsingPassword"
        },
        { target: "#login.samlScene", guard: "shouldLoginUsingSaml" },
        {
          target: "#login.chooseProductScene",
          guard: "shouldShowChooseProduct"
        }
      ],
      entry: ["shouldRedirectToDellOrDruva"]
    },
    failure: {
      entry: "notifyAuthTypeError"
    },
    loggingOut: {
      invoke: {
        id: "logoutForOtherAcc",
        src: "logout",
        onDone: {
          target: "logoutSuccess"
        },
        onError: {
          target: "logoutFailure",
          actions: "assignLogoutForOtherAccError"
        }
      }
    },
    logoutSuccess: {
      always: [
        {
          target: "#login.passwordScene",
          guard: "otherAccShouldLoginUsingPassword"
        },
        { target: "#login.samlScene", guard: "otherAccShouldLoginUsingSaml" }
      ]
    },
    logoutFailure: {
      entry: "notifyLogoutForOtherAccError"
    }
  }
};

export default state;
