import useProductConfig from "hooks/useProductConfig";
import AuthBody from "./AuthBody";
import FooterBanner from "./FooterBanner";
import "./style.scss";
import "./ccm.scss";

const AuthLayout = ({ children, noFooterLinks, noFooterBanners }) => {
  const { showFooterBanners } = useProductConfig();
  return (
    <div className="auth-layout">
      <AuthBody noFooterLinks={noFooterLinks}>{children}</AuthBody>
      {showFooterBanners && !noFooterBanners && <FooterBanner />}
    </div>
  );
};

export default AuthLayout;
