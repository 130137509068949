import { useLoginMachine } from "./LoginMachineProvider";
import ErrorServiceUnavailable from "./ErrorPasswordResetNeeded";
import ErrorUserNotFound from "./ErrorUserNotFound";
import ErrorTooManyAttempts from "./ErrorTooManyAttempts";
import {
  ERR_FPWD_TOO_MANY_ATTEMPTS,
  ERR_FPWD_RESET_NEEDED,
  ERR_FPWD_USER_INACTIVE
} from "constants/errors";

const ForgotPasswordErrorScene = () => {
  const { state } = useLoginMachine();
  const forgotPasswordError = state.context?.forgotPasswordError?.code;

  if (forgotPasswordError === ERR_FPWD_TOO_MANY_ATTEMPTS) {
    return <ErrorTooManyAttempts />;
  }

  if (forgotPasswordError === ERR_FPWD_RESET_NEEDED) {
    return <ErrorServiceUnavailable />;
  }

  if (forgotPasswordError === ERR_FPWD_USER_INACTIVE) {
    return <ErrorUserNotFound />;
  }

  return <ErrorServiceUnavailable />;
};

export default ForgotPasswordErrorScene;
