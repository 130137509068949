import * as emailSceneGuards from "./emailScene/guards";
import * as passwordSceneGuards from "./passwordScene/guards";
import * as chooseProductSceneGuards from "./chooseProductScene/guards";
import * as enterOTPGuards from "./enterOTPScene/guards";
import * as fedrampDisclaimerGuards from "./fedrampDisclaimerScene/guards";

const guards = {
  ...emailSceneGuards,
  ...passwordSceneGuards,
  ...chooseProductSceneGuards,
  ...enterOTPGuards,
  ...fedrampDisclaimerGuards
};

export default guards;
