import { assign } from "xstate";

const assignAcceptFedrampResponse = assign({
  acceptFedrampResponse: ({ event }) => event.output
});

const assignAcceptFedrampError = assign({
  acceptFedrampError: ({ event }) => event.error
});

export { assignAcceptFedrampResponse, assignAcceptFedrampError };
