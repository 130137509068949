import {
  NUMBER_REGEX,
  LOWER_CASE_REGEX,
  UPPER_CASE_REGEX,
  SPECIAL_CHAR_REGEX,
  CSRF_METHODS
} from "constants/common";

export const getRandomInt = (min, max) => {
  const newMin = Math.ceil(min);
  const newMax = Math.floor(max);
  return Math.floor(Math.random() * (newMax - newMin + 1)) + newMin;
};

export const toHms = (secs) => {
  const sec_num = parseInt(secs, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

export const isElementInvalid = (validationProps, name) => {
  return !!(validationProps.touched[name] && validationProps.errors[name]);
};

export const checkPasswordStrength = (password) => {
  return {
    hasValidLength: password?.length >= 12,
    containsNumbers: new RegExp(NUMBER_REGEX).test(password),
    containsUpperLetters: new RegExp(LOWER_CASE_REGEX).test(password),
    containsLowerLetters: new RegExp(UPPER_CASE_REGEX).test(password),
    containsSpecialChars: new RegExp(SPECIAL_CHAR_REGEX).test(password)
  };
};

export const isStrongPassword = (password) => {
  const passwordStrength = checkPasswordStrength(password);
  return (
    passwordStrength.hasValidLength &&
    passwordStrength.containsNumbers &&
    passwordStrength.containsUpperLetters &&
    passwordStrength.containsLowerLetters &&
    passwordStrength.containsSpecialChars
  );
};

export const isFunction = (fn) => typeof fn === "function";

/* istanbul ignore next */
export const isCSRFApi = (config) => {
  const requestMethod = config.method;
  let apiMethod = null;

  if (requestMethod.toLowerCase() === "get") {
    apiMethod = config?.params?.input?.request?.method;
  } else if (requestMethod.toLowerCase() === "post") {
    if (typeof config?.data === "string") {
      const json = JSON.parse(config?.data);
      apiMethod = json?.request?.method;
    } else {
      apiMethod = config?.data?.request?.method;
    }
  }

  return CSRF_METHODS.includes(apiMethod);
};
