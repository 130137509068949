import { useSearchParams } from "react-router-dom";
import Loader from "orion/lib/loader";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";

import { useValidateForgotPasswordTokenMutation } from "queries/login.queries";
import AuthLayout from "components/AuthLayout";
import AuthCard from "shared/AuthCard";
import AppProvider from "components/AppProvider";
import FormFields from "./FormFields";
import ForgotTokenExpired from "./ForgotTokenExpired";

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("forgot_password_token");
  const { data, isFetching, error } =
    useValidateForgotPasswordTokenMutation(token);
  const { passwordPolicy, email } = data || {};
  const { policyEnabled, passwordHistorySize } = passwordPolicy || {};
  let component;

  if (isFetching) {
    component = <Loader fullPage isLoading />;
  } else if (error) {
    component = <ForgotTokenExpired />;
  } else {
    component = (
      <AuthLayout>
        <AuthCard
          dataTestId="change-password-form-card"
          className="oui-h-auto"
          hasWarningFooter
          footer={
            policyEnabled && passwordHistorySize > 0
              ? () => (
                  <div className="oui-py-1 oui-px-1 oui-d-flex">
                    <InfoGreyOutline className="oui-mr-2" />
                    <div>
                      New password should not match previous{" "}
                      {passwordHistorySize} passwords
                    </div>
                  </div>
                )
              : null
          }
        >
          <h4>Reset Password</h4>
          <FormFields passwordPolicy={passwordPolicy} email={email} />
        </AuthCard>
      </AuthLayout>
    );
  }

  return <AppProvider>{component}</AppProvider>;
};

export default ForgotPassword;
