import cx from "classnames";
import Card from "orion/lib/card";
import AuthCardBody from "./AuthCardBody";
import "./style.scss";

const AuthCard = ({
  hasWarningFooter,
  title,
  children,
  footer,
  className,
  dataTestId,
  cardContentClass
}) => {
  return (
    <Card
      dataTestId={dataTestId}
      className={cx("auth-card", className, {
        "has-warning-footer": hasWarningFooter
      })}
      footer={footer}
      body={() => (
        <AuthCardBody title={title} cardContentClass={cardContentClass}>
          {children}
        </AuthCardBody>
      )}
    />
  );
};

export default AuthCard;
