import { apiInstance } from "helpers/request.helpers";

const fetchStashSamlResponse = ({ queryKey }) => {
  return apiInstance.get("commonlogin/samlconsume", {
    params: {
      input: {
        request: { method: "GetStashedSAMLResponse", args: [queryKey[1]] }
      }
    }
  });
};

export { fetchStashSamlResponse };
