import { ERR_ACCOUNT_LOCKED } from "constants/errors";
import {
  WARN_FEDRAMP_DISCLAIMER,
  WARN_PASSWORD_EXPIRED,
  WARN_SETUP_TOTP,
  WARN_PROVIDE_EMAIL_OTP,
  WARN_ENTER_TOTP_OTP,
  WARN_SETUP_SMS_OTP,
  WARN_ENTER_SMS_OTP,
  WARN_ACCEPT_TERMS
} from "constants/warnings";

// Idel guards
const shouldGoToEmailScene = ({ context: { adminIdentifier } }) =>
  adminIdentifier === null;

const shouldGoToChooseProductScene = ({ context: { adminIdentifier } }) =>
  adminIdentifier !== null;

// Failure guards
const accountLockedFromPassword = ({ context: { loginError } }) =>
  loginError?.code === ERR_ACCOUNT_LOCKED;

// Success gaurds
const changePasswordRequiredFromPasswordScreen = ({
  context: { loginResponse }
}) => loginResponse?.warning?.code === WARN_PASSWORD_EXPIRED;

const showFedrampDisclaimerFromPasswordScreen = ({
  context: { loginResponse }
}) => loginResponse?.warning?.code === WARN_FEDRAMP_DISCLAIMER;

const showTermsAndConditionsFromPasswordScreen = ({
  context: { loginResponse }
}) => loginResponse?.warning?.code === WARN_ACCEPT_TERMS;

const isOTPRequired = ({ context: { loginResponse } }) =>
  loginResponse?.warning?.code === WARN_PROVIDE_EMAIL_OTP ||
  loginResponse?.warning?.code === WARN_ENTER_TOTP_OTP ||
  loginResponse?.warning?.code === WARN_ENTER_SMS_OTP;

const isTOTPSetupPending = ({ context: { loginResponse } }) =>
  loginResponse?.warning?.code === WARN_SETUP_TOTP;

const isSMSSetupPending = ({ context: { loginResponse } }) =>
  loginResponse?.warning?.code === WARN_SETUP_SMS_OTP;

const isSuccessfullyLoggedInFromPassword = ({ context: { loginResponse } }) =>
  !loginResponse?.warning &&
  !loginResponse?.error &&
  loginResponse?.redirectUrl;

export {
  shouldGoToEmailScene,
  shouldGoToChooseProductScene,
  accountLockedFromPassword,
  changePasswordRequiredFromPasswordScreen,
  isOTPRequired,
  isTOTPSetupPending,
  isSMSSetupPending,
  isSuccessfullyLoggedInFromPassword,
  showFedrampDisclaimerFromPasswordScreen,
  showTermsAndConditionsFromPasswordScreen
};
