const state = {
  initial: "idle",
  states: {
    idle: {
      on: {
        SUBMIT: {
          target: "loading",
          actions: "assignOTP"
        },
        RESEND: {
          actions: "expireOTPAfterSeconds",
          target: "resendingOTP"
        }
      }
    },
    loading: {
      invoke: {
        id: "submitOTP",
        src: "validateSecurityCode",
        input: ({ context }) => ({
          otp: context.otp,
          redirectUrl: context?.loginResponse?.redirectUrl
        }),
        onDone: {
          target: "success",
          actions: "assignSubmitOTPResponse"
        },
        onError: {
          target: "failure",
          actions: "assignSubmitOTPError"
        }
      }
    },
    success: {
      always: [
        {
          target: "#login.changePasswordScene",
          guard: "changePasswordRequiredFromOTP"
        },
        {
          target: "#login.loginSuccessScene",
          guard: "isSuccessfullyLoggedInFromOTP"
        },
        {
          target: "#login.fedrampDisclaimerScene",
          guard: "showFedrampDisclaimerFromOTPScreen"
        },
        {
          target: "#login.termsAndConditionsScene",
          guard: "showTermsAndConditionsFromPasswordScreen"
        }
      ]
    },
    failure: {
      entry: ["notifySubmitOTPError", "clearOTP"],
      always: [
        {
          target: "#login.accountLockedScene",
          guard: "accountLockedFromOTP"
        }
      ],
      on: {
        SUBMIT: {
          target: "loading",
          actions: "assignOTP"
        },
        RESEND: {
          target: "resendingOTP"
        }
      }
    },
    resendingOTP: {
      invoke: {
        id: "resendEmailOTP",
        src: "resendEmailOTP",
        onDone: {
          target: "resendOTPSuccess"
        }
      }
    },
    resendOTPSuccess: {
      on: {
        SUBMIT: {
          target: "loading"
        }
      }
    }
  }
};

export default state;
