const BACK = [
  {
    target: "#login.emailScene",
    guard: "shouldGoToEmailScene",
    actions: ["clearPassword"]
  },
  {
    target: "#login.chooseProductScene",
    guard: "shouldGoToChooseProductScene",
    actions: ["clearPassword"]
  }
];

const on = {
  SUBMIT: {
    target: "loading",
    actions: "assignPassword"
  },
  FORGOT_PASSWORD: {
    target: "initiateForgotPassword"
  },
  BACK
};

const state = {
  initial: "idle",
  states: {
    idle: {
      on
    },
    loading: {
      invoke: {
        id: "doLogin",
        src: "loginUsingPassword",
        input: ({
          context: {
            email,
            password,
            adminIdentifier,
            callerProductId,
            sourceUrl
          }
        }) => ({
          email,
          password,
          adminIdentifier,
          callerProductId,
          sourceUrl
        }),
        onDone: {
          target: "success",
          actions: "assignLoginResponse"
        },
        onError: {
          target: "failure",
          actions: "assignLoginError"
        }
      }
    },
    initiateForgotPassword: {
      invoke: {
        id: "forgotPassword",
        src: "forgotPassword",
        input: ({ context: { email, adminIdentifier } }) => ({
          email,
          adminIdentifier
        }),
        onDone: {
          target: "#login.forgotPasswordSuccessScene",
          actions: "assignForgotPasswordResponse"
        },
        onError: {
          target: "#login.forgotPasswordErrorScene",
          actions: "assignForgotPasswordError"
        }
      }
    },
    success: {
      always: [
        {
          target: "#login.changePasswordScene",
          guard: "changePasswordRequiredFromPasswordScreen"
        },
        { target: "#login.setupTOTPScene", guard: "isTOTPSetupPending" },
        {
          target: "#login.enterOTPScene",
          guard: "isOTPRequired"
        },
        {
          target: "#login.setupSMSScene",
          guard: "isSMSSetupPending"
        },

        {
          target: "#login.loginSuccessScene",
          guard: "isSuccessfullyLoggedInFromPassword"
        },
        {
          target: "#login.fedrampDisclaimerScene",
          guard: "showFedrampDisclaimerFromPasswordScreen"
        },
        {
          target: "#login.termsAndConditionsScene",
          guard: "showTermsAndConditionsFromPasswordScreen"
        }
      ],
      on: {
        BACK
      }
    },
    failure: {
      entry: "notifyLoginError",
      always: [
        {
          target: "#login.accountLockedScene",
          guard: "accountLockedFromPassword"
        }
      ],
      on
    }
  }
};

export default state;
