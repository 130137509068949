import Form from "orion/lib/form";
import FormGroup from "orion/lib/formGroup";
import { useChangePasswordMutation } from "queries/admin.queries";
import { isStrongPassword } from "helpers/utils";
import LoginButton from "shared/LoginButton";
import PasswordField from "shared/PasswordField";
import { useLoginMachine } from "../Login/LoginMachineProvider";

const FormFields = ({ data = {} }) => {
  const { send } = useLoginMachine();
  const { mutate: changePassword, isLoading } = useChangePasswordMutation(send);
  const { forceStrongPassword } = data;

  return (
    <Form
      dataTestId="change-password-form"
      withValidation
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      }}
      validationSchema={(Yup) =>
        Yup.object().shape({
          oldPassword: Yup.string().required("This field is required"),
          newPassword: Yup.string()
            .required("This field is required")
            .test(
              "password-strength-check",
              "New password does not meet password policy criteria.",
              (password) =>
                forceStrongPassword ? isStrongPassword(password) : true
            ),
          confirmPassword: Yup.string()
            .required("This field is required")
            .oneOf(
              [Yup.ref("newPassword"), null],
              "New password and confirm password must match"
            )
        })
      }
      onSubmit={({ oldPassword, newPassword }) => {
        changePassword([oldPassword, newPassword, "", false]);
      }}
      renderChildren={(validationProps) => {
        return (
          <>
            <FormGroup className="oui-w-100 form-group-height">
              <PasswordField
                dataTestId="old-password-input"
                name="oldPassword"
                placeholder="Old Password"
                validationProps={validationProps}
              />
            </FormGroup>
            <FormGroup className="oui-w-100 form-group-height">
              <PasswordField
                dataTestId="new-password-input"
                name="newPassword"
                placeholder="New Password"
                validationProps={validationProps}
                showPasswordStrength
                forceStrongPassword={forceStrongPassword}
              />
            </FormGroup>
            <FormGroup className="oui-w-100 form-group-height">
              <PasswordField
                dataTestId="confirm-password-input"
                name="confirmPassword"
                placeholder="Confirm Password"
                validationProps={validationProps}
                forceStrongPassword={forceStrongPassword}
              />
            </FormGroup>
            <FormGroup className="oui-w-100 oui-m-0">
              <LoginButton
                dataTestId="submit-btn"
                label="Change Password"
                className="oui-mt-0 oui-mb-4"
                isLoading={isLoading}
                disabled={
                  isLoading || Object.keys(validationProps.errors).length > 0
                }
              />
            </FormGroup>
          </>
        );
      }}
    />
  );
};

export default FormFields;
