import { useLoginMachine } from "components/Login/LoginMachineProvider";
import { useLogoutMutation } from "queries/login.queries";
import FedrampDisclaimerContent from "./FedrampDisclaimerContent";

const FedrampDisclaimerWithMachine = () => {
  const { send, state } = useLoginMachine();
  const isAccepting = state.matches("fedrampDisclaimerScene.accepting");
  const { mutate: decline, isLoading: isDeclining } = useLogoutMutation();

  const accept = () =>
    send({
      type: "ACCEPT"
    });
  return (
    <FedrampDisclaimerContent
      isAccepting={isAccepting}
      accept={accept}
      isDeclining={isDeclining}
      decline={decline}
    />
  );
};

export default FedrampDisclaimerWithMachine;
