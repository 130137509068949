import * as errors from "constants/errors";

const errorMap = {
  [errors.ERR_INVALID_OTP]:
    "Invalid security code. Please provide a valid OTP.",
  [errors.ERR_SESSION_EXPIRED]: "Session has expired. Login again.",
  [errors.ERR_ACCOUNT_LOCKED]: "Your account has been locked.",
  [errors.ERR_INVALID_CREDENTIALS]: "Invalid email address or password.",
  [errors.ERR_INCORRECT_OLD_PASSWORD]: "Incorrect old password.",
  [errors.ERR_UNKNOWN_PRODUCT_NAME]: "Unknown product",
  [errors.ERR_INTERNAL_APP_ERROR]: "Internal application error.",
  [errors.ERR_CLIENT_IP_NOT_ALLOWED]: "Client IP is not allowed to login.",
  [errors.ERR_PASSWORD_POLICY_UNMACHED]:
    "The new password does not match password policy.",
  [errors.ERR_PRODUCT_NOT_LICENSED]: "Product is not licensed.",
  [errors.ERR_STASH_SAML_RESPONSE]:
    "Failed to receive SAML authentication response.",
  [errors.ERR_LOGGED_OFF]: "You have been logged off. Please login again.",
  [errors.ERR_PASSWORD_POLICY_UNMACHED]: "Invalid new password",
  [errors.ERR_ECUSTOMERACCESS_RESTRICTED]: "Product license expired.",
  [errors.ERR_NO_PRODUCT_LICENSE_ATTACHED]:
    "No Product License attached to the customer",
  [errors.ERR_SAML_IDP_SETTINGS_UNDEFINED]: "SAML IDP Settings not defined.",
  [errors.ERR_API_NOT_IMPLEMENTED]: "API not implemented",
  [errors.ERR_PHONE_NUMBER_ALREADY_REGISTERED]:
    "Phone number is already registered."
};

export default errorMap;
