import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modals: []
};

export const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const modalExists = state.modals.find(
        (modal) => modal.key === action.payload.key
      );

      if (!modalExists) {
        state.modals.push(action.payload);
      }
    },

    closeModal: (state, action) => {
      state.modals = state.modals.filter(
        (modal) => modal.key !== action.payload
      );
    }
  }
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
