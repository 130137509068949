import uuidv4 from "uuid-v4";
import TagManager from "@druva-apps/google-tag-manager";

import {
  LOAD_GAINSIGHT,
  GAINSIGHT_SCRIPT_URL,
  IS_FEDRAMP,
  IS_FIPS,
  DRUVA_LOGIN_UI_DOMAIN
} from "constants/common";
import { ROUTE_LOGIN, ROUTE_SET_PASSWORD } from "constants/routes";

const dynamicallyLoadScript = function (url, callback) {
  let script = document.querySelector(`script[src="${url}"]`);

  // Check if it is already there
  if (!script) {
    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.defer = true;
    if (callback) {
      script.onload = callback;
    }
    document.body.appendChild(script);
  }
};

const loadGainsightScript = () => {
  if (LOAD_GAINSIGHT) {
    dynamicallyLoadScript(GAINSIGHT_SCRIPT_URL, initializeGainsight);
  }
};

export const initializeGainsight = () => {
  const uniqueId = uuidv4();

  if (window.inProductExperience) {
    window.inProductExperience?.loadAndInitialize({
      id: uniqueId
    });
  }
};

const loadCCMScript = () => {
  if (!IS_FEDRAMP && !IS_FIPS) {
    dynamicallyLoadScript(
      "//consent.truste.com/notice?domain=druva.com&c=teconsent&js=nj&noticeType=bb&gtm=1&text=true&pcookie"
    );
  }
};

const loadGoogleTagManager = () => {
  const pathsToBeTracked = [ROUTE_LOGIN, ROUTE_SET_PASSWORD];
  if (
    pathsToBeTracked.includes(window.location.pathname) &&
    process.env.REACT_APP_GTM_ID &&
    window.location.hostname === DRUVA_LOGIN_UI_DOMAIN
  ) {
    // Not to be loaded in QA, Gov & Dell environments
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    };
    TagManager.initialize(tagManagerArgs);
  }
};

export { loadGainsightScript, loadCCMScript, loadGoogleTagManager };
