import Loader from "orion/lib/loader";
import { Navigate, useLocation } from "react-router-dom";
import { useLogoutQuery } from "queries/login.queries";
import { ROUTE_LOGIN } from "constants/routes";

const Logout = () => {
  const { isFetching } = useLogoutQuery();
  const { search: queryString } = useLocation();

  if (isFetching) {
    return <Loader fullPage isLoading />;
  }

  return <Navigate to={`${ROUTE_LOGIN}${queryString}`} />;
};

export default Logout;
