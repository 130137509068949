import { useMutation } from "hooks/reactQuery";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "slices/toast.slice";
import {
  setPassword,
  changePassword,
  acceptLicenseAgreement,
  acceptFedrampDisclaimer
} from "apis/admin.apis";
import errorMap from "helpers/errorMap";
import { ERR_LOGGED_OFF } from "constants/errors";
import { ROUTE_LOGIN } from "constants/routes";

const useSetPasswordMutation = () => {
  const { notifySuccess, notifyError } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isNewAdmin = searchParams.get("is_new_admin");
  const isNewAdminBool = isNewAdmin && isNewAdmin?.toLowerCase() === "true";

  return useMutation({
    mutationKey: "set-password",
    mutationFn: setPassword,
    onSuccess: () => {
      notifySuccess(
        isNewAdminBool
          ? "Account activated successfully. Sign in using the new password."
          : "Password changed successfully. Sign in again using the new password."
      );
      navigate(ROUTE_LOGIN);
    },
    onError(code) {
      if (errorMap[code]) {
        notifyError(errorMap[code]);
      }
    }
  });
};

const useChangePasswordMutation = (send) => {
  const { notifySuccess, notifyError } = useToast();

  return useMutation({
    mutationKey: "change-password",
    mutationFn: changePassword,
    onSuccess() {
      notifySuccess(
        "Password changed successfully. Sign in again using the new password."
      );
      send({ type: "GO_TO_LOGIN" });
    },
    onError(code) {
      if (errorMap[code]) {
        notifyError(errorMap[code]);
      }

      if (code === ERR_LOGGED_OFF) {
        send({ type: "GO_TO_LOGIN" });
      }
    }
  });
};

const useAcceptLicenseAgreementMutation = () => {
  return useMutation({
    mutationKey: "accept-license-agreement",
    mutationFn: acceptLicenseAgreement,
    onSuccess: (result) => {
      if (result?.redirectUrl) {
        window.location.href = result?.redirectUrl;
      }
    }
  });
};

const useAcceptFedrampDisclaimerMutation = () => {
  return useMutation({
    mutationKey: "accept-fedramp-disclaimer",
    mutationFn: acceptFedrampDisclaimer,
    onSuccess: async (result) => {
      if (!result?.warning && result?.redirectUrl) {
        window.location.href = result?.redirectUrl;
      }
    }
  });
};

export {
  useSetPasswordMutation,
  useChangePasswordMutation,
  useAcceptLicenseAgreementMutation,
  useAcceptFedrampDisclaimerMutation
};
