import { useSelector } from "react-redux";
import { WARN_ACCEPT_TERMS, WARN_PASSWORD_EXPIRED } from "constants/warnings";
import { useAcceptFedrampDisclaimerMutation } from "queries/admin.queries";
import { useLogoutMutation } from "queries/login.queries";
import LicenseAgreement from "components/LicenseAgreement";
import SetPasswordForm from "components/SetPassword/SetPasswordForm";
import FedrampDisclaimerContent from "./FedrampDisclaimerContent";
import { useSearchParams } from "react-router-dom";

const FedrampDisclaimerWithoutMachine = () => {
  const [searchParams] = useSearchParams();
  const targetUrl = searchParams.get("target_url");
  const redirectUrl =
    useSelector((state) => state.app.redirectUrl) || targetUrl;
  const {
    mutate: accept,
    isLoading: isAccepting,
    data
  } = useAcceptFedrampDisclaimerMutation();
  const { mutate: decline, isLoading: isDeclining } = useLogoutMutation();
  const warningCode = data?.warning?.code;

  if (warningCode === WARN_ACCEPT_TERMS) {
    return <LicenseAgreement />;
  } else if (warningCode === WARN_PASSWORD_EXPIRED) {
    return <SetPasswordForm />;
  }

  return (
    <FedrampDisclaimerContent
      isAccepting={isAccepting}
      accept={() => accept(redirectUrl)}
      isDeclining={isDeclining}
      decline={decline}
    />
  );
};

export default FedrampDisclaimerWithoutMachine;
