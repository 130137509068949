import useProductConfig from "hooks/useProductConfig";
import { ReactComponent as PoweredByDruva } from "assets/svgs/druva-powered-by.svg";

const FooterLinks = () => {
  const { footerLinks } = useProductConfig();
  const year = new Date().getFullYear();

  return (
    <>
      <div className="auth-layout__content--footer">
        Powered by{" "}
        <PoweredByDruva style={{ marginTop: "-8px", marginLeft: "0.125rem" }} />
        <ul>
          <li>
            <a
              href={footerLinks?.privacyPolicyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href={footerLinks?.legalNoticeLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Legal Notice
            </a>
          </li>
          <li>
            <a
              href={footerLinks?.supportLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a>
          </li>
        </ul>
      </div>
      <div className="auth-layout__content--footer oui-mt-3">
        &copy; {year}{" "}
        <a
          href="https://www.druva.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Druva Inc.
        </a>{" "}
        All rights reserved.
      </div>
    </>
  );
};

export default FooterLinks;
