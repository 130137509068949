import { apiInstance } from "helpers/request.helpers";

const fetchUiCustomizations = () => {
  return apiInstance.get("commonlogin/ping", {
    params: {
      input: { request: { method: "GetUXProfile", kwargs: {} } }
    }
  });
};

export { fetchUiCustomizations };
