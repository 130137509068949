import React from "react";

import useProductConfig from "hooks/useProductConfig";
import DruvaFooterLinks from "./DruvaFooterLinks";
import DellFooterLinks from "./DellFooterLinks";
import { loadCCMScript } from "helpers/script";

const AuthBody = ({ children, noFooterLinks }) => {
  const { isDell, isMsp } = useProductConfig();

  React.useEffect(() => {
    if (!isDell && !isMsp) {
      loadCCMScript();
    }
  }, [isDell, isMsp]);

  return (
    <div className="auth-layout__content">
      <div className="oui-d-flex oui-flex-column oui-align-items-center">
        <div className="oui-flex-grow">{children}</div>
        {!noFooterLinks ? (
          isDell ? (
            <DellFooterLinks />
          ) : (
            <DruvaFooterLinks />
          )
        ) : null}
      </div>
      <div id="consent_blackbar"></div>
    </div>
  );
};

export default AuthBody;
