import { MSP_BRAND_DRUVA, MSP_BRAND_DELL } from "orion/lib/lib/mspBrands";
import { useSelector } from "react-redux";

const getOrionBrand = (parentType) => {
  switch (parentType) {
    case 0:
      return MSP_BRAND_DRUVA;
    case 1:
      return MSP_BRAND_DELL;
    default:
      return MSP_BRAND_DRUVA;
  }
};

const getBrandTitle = (parentType) => {
  switch (parentType) {
    case 0:
      return "Druva";
    case 1:
      return "PowerProtect Backup Service";
    default:
      return "Druva";
  }
};

const getBrandName = (parentType) => {
  switch (parentType) {
    case 0:
      return "Druva";
    case 1:
      return "Dell EMC";
    default:
      return "Druva";
  }
};

function useProductConfig() {
  const productConfig = useSelector((state) => state.app.productConfig);
  const parentType = productConfig?.parentDetails?.parentType;

  return {
    orionBrand: getOrionBrand(parentType),
    brandName: getBrandName(parentType),
    brandTitle: getBrandTitle(parentType),
    logo: productConfig?.uiCustomizations?.brand?.brandIconUrl,
    isDruva: parentType === 0,
    isDell: parentType === 1,
    isMsp: parentType === 2,
    footerLinks: productConfig?.uiCustomizations?.links,
    showFooterBanners: productConfig?.uiCustomizations?.showMarketingBanners,
    theme: productConfig?.uiCustomizations?.theme,
    brand: productConfig?.uiCustomizations?.brand
  };
}

export default useProductConfig;
