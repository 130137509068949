import * as admin from "./admin";
import ping from "./ping";
import product from "./product";
import login from "./login";
import session from "./session";
import customer from "./customer";
import * as samlconsume from "./samlconsume";

export const handlers = [
  admin.getHandler,
  admin.postHandler,
  ping,
  product,
  login,
  customer,
  samlconsume.getHandler,
  ...session
];
