import Page from "orion/lib/page";
import Input from "orion/lib/input";
import Button from "orion/lib/button";
import Loader from "orion/lib/loader";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ReactComponent as LeftArrow } from "assets/svgs/back.svg";
import useProductConfig from "hooks/useProductConfig";
import { ReactComponent as SamlErrorSvg } from "assets/svgs/saml-error.svg";
import { useFetchStashSamlResponseQuery } from "queries/saml.queries";
import AppLayout from "components/AppLayout";
import AppProvider from "components/AppProvider";
import { ROUTE_LOGIN } from "constants/routes";
import copyToClipboard from "helpers/copyToClipboard";
import "./style.scss";

const SAMLError = () => {
  const navigate = useNavigate();
  const { footerLinks } = useProductConfig();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("saml_response_token");
  const { data, isFetching } = useFetchStashSamlResponseQuery(token);

  if (isFetching) {
    return <Loader fullPage isLoading />;
  }

  return (
    <AppProvider>
      <AppLayout>
        <Page.Main>
          <Page.Body className="oui-d-flex oui-align-items-center oui-justify-content-center oui-flex-column">
            <div
              className="oui-d-flex oui-flex-column oui-align-items-center"
              style={{ width: "100%" }}
            >
              <SamlErrorSvg
                title="Password Token Expired"
                width={120}
                height={120}
              />
              <h5 className="oui-mt-3">Authentication Failed</h5>
              <p className="oui-text-muted oui-text-center oui-mt-2 oui-mb-0">
                We apologize for the inconvenience caused and appreciate your
                patience.
              </p>
              <p className="oui-text-muted oui-text-center">
                For assistance in resolving this issue, contact{" "}
                <a
                  href={footerLinks?.supportLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>{" "}
                with the following SAML response.
              </p>
            </div>

            <div style={{ width: "450px" }} className="oui-mt-2">
              <Input
                type="textarea"
                rows={6}
                readOnly
                value={data?.samlResponse}
              />
              <div className="oui-mt-3 oui-d-flex oui-justify-content-between">
                <div
                  data-testid="back-btn"
                  className="back-btn"
                  onClick={() => {
                    navigate(ROUTE_LOGIN);
                  }}
                >
                  <LeftArrow width={12} />
                  <a href="/">Back</a>
                </div>
                <Button
                  size="sm"
                  label="Copy To Clipboard"
                  onClick={() => copyToClipboard(data?.samlResponse)}
                />
              </div>
            </div>
          </Page.Body>
        </Page.Main>
      </AppLayout>
    </AppProvider>
  );
};

export default SAMLError;
