import Loader from "orion/lib/loader";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import { useSearchParams } from "react-router-dom";

import { useFetchPasswordPolicyQuery } from "queries/login.queries";
import AuthLayout from "components/AuthLayout";
import AuthCard from "shared/AuthCard";
import FormFields from "./FormFields";

const SetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const { data, isFetching } = useFetchPasswordPolicyQuery();
  const { passwordHistorySize } = data || {};
  const isNewAdmin = searchParams.get("is_new_admin");

  if (isFetching) {
    return <Loader isLoading fullPage />;
  }

  if (data) {
    return (
      <AuthLayout noFooterLinks noFooterBanners>
        <AuthCard
          dataTestId="set-password-form-card"
          className="oui-h-auto"
          hasWarningFooter
          footer={
            passwordHistorySize &&
            isNewAdmin &&
            isNewAdmin.toLocaleLowerCase() === "false"
              ? () => (
                  <div className="oui-py-1 oui-px-1 oui-d-flex">
                    <InfoGreyOutline className="oui-mr-2" />
                    <div>
                      New password should not match previous{" "}
                      {passwordHistorySize} passwords
                    </div>
                  </div>
                )
              : null
          }
        >
          <h4>Set your password</h4>
          <FormFields data={data} />
        </AuthCard>
      </AuthLayout>
    );
  }

  return null;
};

export default SetPasswordForm;
