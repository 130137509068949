import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const initialState = {
  toasts: []
};

export const toastSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    notifySuccess: (state, action) => {
      state.toasts.push({
        type: "success",
        message: action.payload
      });
    },

    notifyError: (state, action) => {
      state.toasts.push({
        type: "danger",
        message: action.payload
      });
    },

    notifyWarning: (state, action) => {
      state.toasts.push({
        type: "warning",
        message: action.payload
      });
    },

    notifyInfo: (state, action) => {
      state.toasts.push({
        type: "info",
        message: action.payload
      });
    },

    hideToast: (state, action) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.message !== action.payload
      );
    }
  }
});

export const useToast = () => {
  const dispatch = useDispatch();
  const notifySuccess = (p) => dispatch(toastSlice.actions.notifySuccess(p));
  const notifyWarning = (p) => dispatch(toastSlice.actions.notifyWarning(p));
  const notifyError = (p) => dispatch(toastSlice.actions.notifyError(p));
  const notifyInfo = (p) => dispatch(toastSlice.actions.notifyInfo(p));

  return { notifySuccess, notifyWarning, notifyError, notifyInfo };
};

export const {
  notifySuccess,
  notifyWarning,
  notifyError,
  notifyInfo,
  hideToast
} = toastSlice.actions;

export default toastSlice.reducer;
