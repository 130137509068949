import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "hooks/reactQuery";
import { useToast } from "slices/toast.slice";
import {
  loginUsingPasswordToken,
  logout,
  fetchInsyncProductUrl,
  fetchPasswordPolicy
} from "apis/login.apis";
import {
  validateForgotPasswordToken,
  confirmForgotPassword
} from "apis/admin.apis";
import {
  ERR_SESSION_EXPIRED,
  ERR_FPWD_TOKEN_INVALID,
  ERR_FPWD_TOKEN_EXPIRED,
  ERR_FPWD_RESET_NEEDED,
  ERR_FPWD_POLICY_UNMATCHED
} from "constants/errors";
import { ROUTE_LOGIN } from "constants/routes";

const useLoginUsingPasswordTokenQuery = (passwordToken) => {
  return useQuery({
    queryKey: ["login-using-password-token", passwordToken],
    queryFn: loginUsingPasswordToken,
    retry: false
  });
};

const useLogoutMutation = () => {
  const { search: queryString } = useLocation();

  return useMutation({
    mutationKey: "logout",
    mutationFn: logout,
    onSuccess: () => {
      window.location.href = `${ROUTE_LOGIN}${queryString}`;
    },
    onError: (err) => {
      if (err?.code === ERR_SESSION_EXPIRED) {
        window.location.href = `${ROUTE_LOGIN}${queryString}`;
      }
    }
  });
};
/* istanbul ignore next*/
const useLogoutQuery = () => {
  const { search: queryString } = useLocation();

  return useQuery({
    queryKey: "logout",
    queryFn: logout,
    onSuccess: () => {
      window.location.href = `${ROUTE_LOGIN}${queryString}`;
    },
    onError: (err) => {
      if (err?.code === ERR_SESSION_EXPIRED) {
        window.location.href = `${ROUTE_LOGIN}${queryString}`;
      }
    }
  });
};

const useFetchPasswordPolicyQuery = () => {
  return useQuery({
    queryKey: "fetch-password-policy",
    queryFn: fetchPasswordPolicy,
    refetchOnMount: false
  });
};

const useFetchInsyncProductUrlMutation = (email) => {
  return useMutation({
    mutationKey: "fetch-insync-product-url",
    mutationFn: fetchInsyncProductUrl,
    onSuccess: (response) => {
      window.location.href = response?.redirectUrl;
    }
  });
};

const useValidateForgotPasswordTokenMutation = (token) =>
  useQuery({
    queryKey: ["validate-forgot-password-token", { token }],
    queryFn: validateForgotPasswordToken,
    refetchOnMount: false,
    retry: false
  });

const useConfirmForgotPasswordMutation = () => {
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useToast();

  return useMutation({
    mutationKey: "confirm-forgot-password",
    mutationFn: confirmForgotPassword,
    onError: (code) => {
      let message;

      switch (code) {
        case ERR_FPWD_TOKEN_INVALID:
        case ERR_FPWD_TOKEN_EXPIRED:
          message = "The reset password token is invalid or expired.";
          break;
        case ERR_FPWD_RESET_NEEDED:
          message = "Internal Application Error. Please contact to support.";
          break;
        case ERR_FPWD_POLICY_UNMATCHED:
          message = "Password does not match password policy.";
          break;
        default:
          message = "Internal application error";
      }
      notifyError(message);
    },
    onSuccess: () => {
      notifySuccess(
        "Password changed successfully. Login again with new password"
      );
      setTimeout(() => {
        navigate(ROUTE_LOGIN);
      }, 500);
    }
  });
};

export {
  useLoginUsingPasswordTokenQuery,
  useLogoutMutation,
  useLogoutQuery,
  useFetchPasswordPolicyQuery,
  useFetchInsyncProductUrlMutation,
  useValidateForgotPasswordTokenMutation,
  useConfirmForgotPasswordMutation
};
