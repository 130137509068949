import { useMemo } from "react";
import { getRandomInt } from "helpers/utils";
import GsuiteSVG from "assets/svgs/banners/gsuite.svg";
import M365SVG from "assets/svgs/banners/m365.svg";
import NasSVG from "assets/svgs/banners/nas.svg";
import VMwareSVG from "assets/svgs/banners/vmware.svg";
import RR_SVG from "assets/svgs/banners/ransomware-recovery.svg";
import SalesforceSVG from "assets/svgs/banners/salesforce.svg";
import Integrations from "assets/svgs/banners/integrations.svg";

const bannerImages = [
  {
    image: GsuiteSVG,
    link: "https://www.druva.com/products/saas-backup/"
  },
  {
    image: M365SVG,
    link: "https://www.druva.com/solutions/microsoft-365-backup/"
  },
  {
    image: NasSVG,
    link: "https://www.druva.com/solutions/nas-backup/"
  },
  {
    image: VMwareSVG,
    link: "https://www.druva.com/solutions/vmware-cloud-backup/"
  },
  {
    image: RR_SVG,
    link: "https://www.druva.com/solutions/ransomware/"
  },
  {
    image: SalesforceSVG,
    link: "https://www.druva.com/solutions/salesforce/"
  },
  {
    image: Integrations,
    link: "https://developer.druva.com/page/partner-integrations/"
  }
];

const FooterBanner = () => {
  const selectedImg = useMemo(() => {
    return bannerImages[getRandomInt(0, 6)];
  }, []);

  return (
    <a href={selectedImg.link} target="_blank" rel="noopener noreferrer">
      <img alt="" className="oui-w-100 oui-h-auto" src={selectedImg.image} />
    </a>
  );
};

export default FooterBanner;
