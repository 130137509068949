import { WARN_PASSWORD_EXPIRED } from "constants/warnings";

const changePasswordRequiredFromFedrampAccept = ({
  context: { acceptFedrampResponse }
}) => acceptFedrampResponse?.warning?.code === WARN_PASSWORD_EXPIRED;

const isSuccessfullyLoggedInFromAcceptFedramp = ({
  context: { acceptFedrampResponse }
}) =>
  !acceptFedrampResponse?.warning &&
  !acceptFedrampResponse?.error &&
  acceptFedrampResponse?.redirectUrl;

export {
  changePasswordRequiredFromFedrampAccept,
  isSuccessfullyLoggedInFromAcceptFedramp
};
