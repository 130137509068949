import Page from "orion/lib/page";

import { ReactComponent as PasswordTokenExpiredSvg } from "assets/svgs/password-token-expired.svg";
import useProductConfig from "hooks/useProductConfig";
import AppLayout from "components/AppLayout";

const ProductNotLicensed = () => {
  const { footerLinks } = useProductConfig();
  return (
    <AppLayout>
      <Page.Main>
        <Page.Body className="oui-d-flex oui-align-items-center oui-justify-content-center">
          <div className="oui-d-flex oui-flex-column oui-align-items-center">
            <PasswordTokenExpiredSvg title="Password Token Expired" />
            <h5>License Configuration is in Progress.</h5>
            <div className="oui-mb-0 oui-text-muted">
              <span>
                Please try login after sometime. Contact &nbsp;
                <a
                  href={footerLinks?.supportLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>
                &nbsp; if the issue still persists.
              </span>
            </div>
          </div>
        </Page.Body>
      </Page.Main>
    </AppLayout>
  );
};

export default ProductNotLicensed;
