import { rest } from "msw";

export const inSyncProductResponse = (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      method: "GetProductData",
      response: {
        inSync: {
          endUserLoginURL: "https://insync-devset2.drtst.in/home"
        }
      }
    })
  );
};

const handler = rest.get("/api/commonlogin/product", inSyncProductResponse);

export default handler;
