import Page from "orion/lib/page";
import TopNav from "./TopNav";
import useProductConfig from "hooks/useProductConfig";

const AppLayout = ({ children, noFooter }) => {
  const { footerLinks, copyrightText, isDell } = useProductConfig();

  return (
    <>
      <TopNav />
      <Page>
        {children}
        {isDell && !noFooter && (
          <Page.Footer
            links={[
              {
                label: "Privacy Policy",
                href: footerLinks?.privacyPolicyLink,
                target: "_blank"
              },
              {
                label: "Support",
                href: footerLinks?.supportLink
              }
            ]}
            copyrightText={copyrightText}
          />
        )}
      </Page>
    </>
  );
};

export default AppLayout;
