import { assign } from "xstate";

export * from "./chooseProductScene/actions";
export * from "./emailScene/actions";
export * from "./enterOTPScene/actions";
export * from "./loginSuccessScene/actions";
export * from "./passwordScene/actions";
export * from "./setupTOTPScene/actions";
export * from "./samlScene/actions";
export * from "./fedrampDisclaimerScene/actions";
export * from "./setupSMSScene/actions";

const assignForgotPasswordResponse = assign({
  forgotPasswordResponse: ({ event }) => event.output
});

const assignForgotPasswordError = assign({
  forgotPasswordError: ({ event }) => event.error
});

export { assignForgotPasswordResponse, assignForgotPasswordError };
