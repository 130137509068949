import { useState } from "react";
import cx from "classnames";
import Label from "orion/lib/label";
import { ReactComponent as LeftArrow } from "assets/svgs/back.svg";
import { useLoginMachine } from "./LoginMachineProvider";
import AuthCard from "shared/AuthCard";
import LoginButton from "shared/LoginButton";

const FormFields = () => {
  const { state, send } = useLoginMachine();
  const authTypeResponse = state.context.authTypeResponse;
  const [adminIdentifier, setAdminIdentifier] = useState(
    state.context.adminIdentifier
  );
  const chooseAdminIdentifier = (adminIdentifier) => {
    setAdminIdentifier(adminIdentifier);
  };

  return (
    <div>
      <Label text={state.context.email} />
      <div className="oui-d-flex oui-justify-content-between oui-w-100 oui-py-3">
        {authTypeResponse?.accounts?.map((customerAccount) => (
          <div
            key={customerAccount.adminIdentifier}
            className={cx({
              "product-btn": true,
              "product-btn__selected":
                adminIdentifier?.adminIdentifier ===
                customerAccount.adminIdentifier
            })}
            onClick={() => chooseAdminIdentifier(customerAccount)}
          >
            {Object.values(customerAccount?.licensedProducts).join(", ")}
          </div>
        ))}
      </div>
      <LoginButton
        dataTestId="submit-btn"
        label="Next"
        className="oui-mr-0 oui-mt-4"
        disabled={!adminIdentifier}
        onClick={(e) => {
          e.preventDefault();
          if (adminIdentifier) {
            send({
              type: "SUBMIT",
              adminIdentifier
            });
          }
        }}
      />
    </div>
  );
};

const ChooseProductScene = () => {
  const { send } = useLoginMachine();

  return (
    <AuthCard>
      <h4 data-testid="card-title">Select a product</h4>
      <FormFields />
      <div className="oui-py-3">
        <div className="oui-text-center">
          <div
            data-testid="back-btn"
            className="back-btn"
            onClick={() => {
              send({ type: "BACK" });
            }}
          >
            <LeftArrow width={12} />
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Back
            </a>
          </div>
        </div>
      </div>
    </AuthCard>
  );
};

export default ChooseProductScene;
