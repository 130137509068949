const state = {
  initial: "idle",
  states: {
    idle: {
      invoke: {
        id: "generateSoftwareToken",
        src: "generateSoftwareToken",
        input: ({ context: { loginResponse } }) => ({ loginResponse }),
        onDone: {
          target: "success",
          actions: "assignSoftwareTokenResponse"
        },
        onError: {
          target: "failure",
          actions: "assignSoftwareTokenError"
        }
      }
    },
    success: {
      on: {
        SUBMIT: "#login.enterOTPScene",
        CANCEL: {
          target: "#login.emailScene",
          actions: "clearCredentials"
        }
      }
    },
    failure: {
      on: {
        CANCEL: {
          target: "#login.emailScene",
          actions: "clearCredentials"
        }
      }
    }
  }
};

export default state;
