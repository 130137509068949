import { createMachine, assign, fromPromise } from "xstate";
import guards from "./guards";
import * as actions from "./actions";
import {
  emailScene,
  enterOTPScene,
  passwordScene,
  chooseProductScene,
  accountLockedScene,
  setupTOTPScene,
  loginSuccessScene,
  setupSMSScene,
  changePasswordScene,
  samlScene,
  fedrampDisclaimerScene,
  forgotPasswordSuccessScene
} from "./states";
import { EXPIRE_OTP_AFTER_SECONDS } from "constants/common";
import { acceptFedrampDisclaimer, fetchAuthType } from "apis/admin.apis";
import {
  forgotPassword,
  generateSoftwareToken,
  loginUsingPassword,
  loginUsingSaml,
  logout,
  resendEmailOTP,
  updatePhoneNumber,
  validateSecurityCode
} from "apis/login.apis";

function buildActorFn(fn, ...args) {
  return async ({ input }) => {
    return await fn.apply(
      null,
      args.map((arg) => input[arg])
    );
  };
}

export const initialContext = {
  email: "",
  notUsingSSO: false,
  password: "",
  otp: "",
  authTypeResponse: null,
  authTypeError: null,
  adminIdentifier: null,
  samlLoginResponse: null,
  samlLoginError: null,
  loginResponse: null,
  loginError: null,
  forgotPasswordResponse: null,
  forgotPasswordError: null,
  softwareTokenResponse: null,
  softwareTokenError: null,
  selectedOTPType: "TOTP",
  submitOTPResponse: null,
  submitOTPError: null,
  logoutForOtherAccError: null,
  callerProductId: null,
  sourceUrl: null,
  expireOTPAfterSeconds: EXPIRE_OTP_AFTER_SECONDS,

  acceptFedrampResponse: null,
  acceptFedrampError: null,
  declineFedrampResponse: null,
  declineFedrampError: null,

  phoneNumber: "",
  updatePhoneNumberError: null
};

const loginMachine = createMachine(
  {
    id: "login",
    initial: "emailScene",
    context: ({ input }) => ({
      ...initialContext,
      ...input
    }),
    on: {
      GO_TO_LOGIN: {
        target: "#login.emailScene",
        actions: assign(initialContext)
      }
    },
    states: {
      emailScene,
      samlScene,
      chooseProductScene,
      passwordScene,
      accountLockedScene,
      setupTOTPScene,
      setupSMSScene,
      enterOTPScene,
      loginSuccessScene,
      changePasswordScene,
      fedrampDisclaimerScene,
      forgotPasswordSuccessScene,
      forgotPasswordErrorScene: {},
      termsAndConditionsScene: {}
    }
  },
  {
    guards,
    actions: {
      ...actions,
      clearCredentials: assign({ otp: "", password: "" })
    },
    actors: {
      fetchAuthType: fromPromise(
        buildActorFn(fetchAuthType, "email", "callerProductId", "sourceUrl")
      ),
      logout: fromPromise(buildActorFn(logout)),
      validateSecurityCode: fromPromise(
        buildActorFn(validateSecurityCode, "otp", "redirectUrl")
      ),
      resendEmailOTP: fromPromise(buildActorFn(resendEmailOTP)),
      loginUsingPassword: fromPromise(
        buildActorFn(
          loginUsingPassword,
          "email",
          "password",
          "adminIdentifier",
          "callerProductId",
          "sourceUrl"
        )
      ),
      forgotPassword: fromPromise(
        buildActorFn(forgotPassword, "email", "adminIdentifier")
      ),
      generateSoftwareToken: fromPromise(buildActorFn(generateSoftwareToken)),
      updatePhoneNumber: fromPromise(
        buildActorFn(updatePhoneNumber, "phoneNumber")
      ),
      acceptFedrampDisclaimer: fromPromise(
        buildActorFn(acceptFedrampDisclaimer, "redirectUrl")
      ),
      loginUsingSaml: fromPromise(
        buildActorFn(loginUsingSaml, "account", "callerProductId", "sourceUrl")
      )
    }
  }
);

export { loginMachine };
