import { configureStore } from "@reduxjs/toolkit";
import appReducer from "slices/app.slice";
import modalReducer from "slices/modal.slice";
import toastReducer from "slices/toast.slice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    modals: modalReducer,
    toasts: toastReducer
  }
});
