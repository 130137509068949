import { store } from "../../../store";
import { notifyError } from "slices/toast.slice";

const shouldLoginUsingPassword = ({ context }) => {
  const { authTypeResponse, notUsingSSO } = context;
  const auth = authTypeResponse;
  const oneAccountExist = auth?.accounts?.length === 1;

  if (
    notUsingSSO &&
    !auth?.redirectUrl &&
    oneAccountExist &&
    auth?.accounts[0].authType === "SAMLTOKEN"
  ) {
    if (auth?.accounts[0].allowPasswordIfSaml) {
      return true;
    } else {
      store.dispatch(
        notifyError(
          "Login using credentials not allowed. Please login using Single Sign-On."
        )
      );
      return false;
    }
  }

  return !auth?.redirectUrl && auth?.loginUsingPassword === true;
};

const shouldLoginUsingSaml = ({
  context: { authTypeResponse, notUsingSSO }
}) => {
  const auth = authTypeResponse;
  const oneAccountExist = auth?.accounts?.length === 1;

  if (
    notUsingSSO === false &&
    oneAccountExist &&
    auth?.accounts[0].authType === "SAMLTOKEN"
  ) {
    return true;
  }

  return false;
};

const otherAccShouldLoginUsingPassword = ({ context: { adminIdentifier } }) => {
  return adminIdentifier.authType !== "SAMLTOKEN";
};

const otherAccShouldLoginUsingSaml = ({ context: { adminIdentifier } }) => {
  return adminIdentifier.authType === "SAMLTOKEN";
};

const shouldShowChooseProduct = ({ context: { authTypeResponse } }) =>
  !authTypeResponse?.redirectUrl && authTypeResponse?.accounts?.length > 1;

export {
  shouldLoginUsingPassword,
  shouldLoginUsingSaml,
  shouldShowChooseProduct,
  otherAccShouldLoginUsingPassword,
  otherAccShouldLoginUsingSaml
};
