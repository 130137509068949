import Page from "orion/lib/page";
import Button from "orion/lib/button";
import AppLayout from "components/AppLayout";

const FedrampDisclaimerContent = ({
  isAccepting,
  accept,
  isDeclining,
  decline
}) => {
  return (
    <AppLayout>
      <Page.Main>
        <Page.Body className="oui-pb-4 oui-d-flex oui-align-items-center oui-justify-content-center">
          <div className="oui-d-flex oui-flex-column">
            <div className="fedram-disclaimer-content">
              <h4>System Use Notification</h4>
              <h5 className="text-center">
                WARNING!!! This Is A U.S. Government Computer System Subject To
                Federal Law
              </h5>
              <p>
                Unauthorized attempts to upload or otherwise alter data,
                programming language, or any other part of these systems are
                strictly prohibited and are subject to civil action and/or
                criminal prosecution.
              </p>
              <p className="oui-mb-0">
                This system is for the use of authorized users only. Individuals
                using this computer system without authority, or in excess of
                their authority, are subject to having all of their activities
                on this system monitored and recorded by system personnel. In
                the course of monitoring individuals improperly using this
                system, or in the course of system maintenance, the activities
                of authorized users may also be monitored. Anyone using this
                system expressly consents to such monitoring and is advised that
                if such monitoring reveals possible evidence of criminal
                activity, the company you work for may provide the evidence of
                such monitoring to law enforcement officials.
              </p>
            </div>
            <div className="oui-py-4 oui-text-right">
              <Button
                label="Accept"
                type="primary"
                isLoading={isAccepting}
                disabled={isAccepting}
                onClick={accept}
                dataTestId="accept-fedramp-agreement"
              />
              <Button
                label="Decline"
                isLoading={isDeclining}
                disabled={isDeclining}
                onClick={decline}
                dataTestId="decline-fedramp-agreement"
              />
            </div>
          </div>
        </Page.Body>
      </Page.Main>
    </AppLayout>
  );
};

export default FedrampDisclaimerContent;
