import Loader from "orion/lib/loader";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";

import { useFetchPasswordPolicyQuery } from "queries/login.queries";
import AuthLayout from "components/AuthLayout";
import AuthCard from "shared/AuthCard";
import FormFields from "./FormFields";

const ChangePassword = () => {
  const { data = {}, isFetching } = useFetchPasswordPolicyQuery();
  const { passwordHistorySize } = data;

  return (
    <AuthLayout noFooterLinks noFooterBanners>
      <Loader isLoading={isFetching}>
        <AuthCard
          dataTestId="change-password-form-card"
          className="oui-h-auto"
          hasWarningFooter
          footer={
            passwordHistorySize
              ? () => (
                  <div className="oui-py-1 oui-px-1 oui-d-flex">
                    <InfoGreyOutline className="oui-mr-2" />
                    <div>
                      New password should not match previous{" "}
                      {passwordHistorySize} passwords
                    </div>
                  </div>
                )
              : null
          }
        >
          <h4 data-testid="card-title">Change your password</h4>
          <div>
            <FormFields data={data} />
          </div>
        </AuthCard>
      </Loader>
    </AuthLayout>
  );
};

export default ChangePassword;
