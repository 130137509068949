import { Routes, Route } from "react-router-dom";
import Login from "components/Login/Login";
import Logout from "components/Logout";
import SAMLError from "components/SAMLError";
import SetPassword from "components/SetPassword";
import ForgotPassword from "components/ForgotPassword";
import { FedrampDisclaimerWithoutMachine } from "components/FedrampDisclaimer";
import CheckGlobalSession from "components/CheckGlobalSession";
import LicenseAgreement from "components/LicenseAgreement";
import {
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_OTHER_ACCOUNTS,
  ROUTE_SET_PASSWORD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_FEDRAMP_DISCLAIMER,
  ROUTE_SAML_ERROR,
  ROUTE_LICENSE_AGREEMENT
} from "constants/routes";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CheckGlobalSession />}>
        <Route path={ROUTE_OTHER_ACCOUNTS} element={<Login />} />
        <Route path={ROUTE_LOGIN} element={<Login />} />
        <Route
          path={ROUTE_FEDRAMP_DISCLAIMER}
          element={<FedrampDisclaimerWithoutMachine />}
        />
        <Route path={ROUTE_LICENSE_AGREEMENT} element={<LicenseAgreement />} />
      </Route>
      <Route path={ROUTE_LOGOUT} element={<Logout />} />
      <Route path={ROUTE_SAML_ERROR} element={<SAMLError />} />
      <Route path={ROUTE_SET_PASSWORD} element={<SetPassword />} />
      <Route path={ROUTE_FORGOT_PASSWORD} element={<ForgotPassword />} />
    </Routes>
  );
}

export default AppRoutes;
