const state = {
  initial: "idle",
  on: {
    ACCEPT: {
      target: ".accepting"
    }
  },
  states: {
    idle: {},
    accepting: {
      invoke: {
        id: "acceptFedrampDisclaimer",
        src: "acceptFedrampDisclaimer",
        input: ({ context }) => ({
          redirectUrl: context.loginResponse?.redirectUrl
        }),
        onDone: {
          target: "success",
          actions: "assignAcceptFedrampResponse"
        },
        onError: {
          target: "failure",
          actions: "assignAcceptFedrampError"
        }
      }
    },
    success: {
      always: [
        {
          target: "#login.changePasswordScene",
          guard: "changePasswordRequiredFromFedrampAccept"
        },
        {
          target: "#login.loginSuccessScene",
          guard: "isSuccessfullyLoggedInFromAcceptFedramp"
        }
      ]
    },
    failure: {}
  }
};

export default state;
