import Page from "orion/lib/page";

import { ReactComponent as PasswordTokenExpiredSvg } from "assets/svgs/password-token-expired.svg";
import useProductConfig from "hooks/useProductConfig";
import AppLayout from "components/AppLayout";

const PasswordTokenExpired = () => {
  const { brandName } = useProductConfig();

  return (
    <AppLayout>
      <Page.Main>
        <Page.Body className="oui-d-flex oui-align-items-center oui-justify-content-center">
          <div
            className="oui-d-flex oui-flex-column oui-align-items-center"
            style={{ width: "350px" }}
          >
            <PasswordTokenExpiredSvg title="Password Token Expired" />
            <h5>Password Error</h5>
            <p className="oui-text-muted">
              This password link is either expired or invalid. Contact your{" "}
              {brandName} administrator for a new one.
            </p>
          </div>
        </Page.Body>
      </Page.Main>
    </AppLayout>
  );
};

export default PasswordTokenExpired;
