import { ReactComponent as LeftArrow } from "assets/svgs/back.svg";
import { useLoginMachine } from "./LoginMachineProvider";

const BackToLogin = () => {
  const { send } = useLoginMachine();

  return (
    <div className="oui-w-100 oui-text-center">
      <div
        data-testid="back-btn"
        className="back-btn"
        onClick={() => {
          send({ type: "GO_TO_LOGIN" });
        }}
      >
        <LeftArrow width={12} />
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Go to Login
        </a>
      </div>
    </div>
  );
};

export default BackToLogin;
