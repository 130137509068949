import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "orion/lib/loader";
import { useLoginUsingPasswordTokenQuery } from "queries/login.queries";
import {
  ERR_PASSWORD_TOKEN_UNAUTHORIZED,
  ERR_PASSWORD_TOKEN_NOT_PROVIDED,
  ERR_NO_PRODUCT_LICENSE_ATTACHED,
  ERR_ACTIVATION_LINK_EXPIRED,
  ERR_CLIENT_IP_NOT_ALLOWED
} from "constants/errors";
import { setCurrentAccount } from "slices/app.slice";
import { WARN_ACCEPT_TERMS, WARN_FEDRAMP_DISCLAIMER } from "constants/warnings";
import PasswordTokenExpired from "components/PasswordTokenExpired";
import ProductNotLicensed from "components/ProductNotLicensed";
import LicenseAgreement from "components/LicenseAgreement";
import RestrictedGeofencing from "components/RestrictedGeofencing";
import { FedrampDisclaimerWithoutMachine } from "components/FedrampDisclaimer";
import SetPasswordForm from "./SetPasswordForm";
import ActivationLinkExpired from "./ActivationLinkExpired";

const SetPassword = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const passwordToken = searchParams.get("password_token");
  const { isLoading, data, error } =
    useLoginUsingPasswordTokenQuery(passwordToken);
  const errorCode = error?.code;
  const warningCode = data?.warning?.code;

  useEffect(() => {
    if (data) {
      dispatch(setCurrentAccount(data?.currentAccount));
    }
  }, [data, dispatch]);

  if (!data && !error && isLoading) {
    return <Loader fullPage isLoading />;
  }

  if (
    errorCode === ERR_PASSWORD_TOKEN_UNAUTHORIZED ||
    errorCode === ERR_PASSWORD_TOKEN_NOT_PROVIDED
  ) {
    return <PasswordTokenExpired />;
  } else if (errorCode === ERR_ACTIVATION_LINK_EXPIRED) {
    return <ActivationLinkExpired />;
  } else if (errorCode === ERR_NO_PRODUCT_LICENSE_ATTACHED) {
    return <ProductNotLicensed />;
  } else if (errorCode === ERR_CLIENT_IP_NOT_ALLOWED) {
    return <RestrictedGeofencing />;
  } else if (warningCode === WARN_ACCEPT_TERMS) {
    return <LicenseAgreement />;
  } else if (warningCode === WARN_FEDRAMP_DISCLAIMER) {
    return <FedrampDisclaimerWithoutMachine />;
  }

  return <SetPasswordForm />;
};

export default SetPassword;
