import Row from "orion/lib/row";
import Col from "orion/lib/col";
import Heading from "orion/lib/heading";
import { DELL_CLOUD_PURCHASE_TERMS_URL } from "constants/common";

const LicenseAgreementDellEMC = () => (
  <div className="agreement-body did-license-agreement-body">
    <Row>
      <Col className="oui-text-center">
        <Heading type="5" text="TERMS AND CONDITIONS" />
      </Col>
    </Row>
    <Row>
      <Col className="oui-text-center oui-mt-4 oui-mb-5">
        By clicking <strong>Accept and Continue</strong>, you acknowledge that
        you have carefully read, understood, and agree to the{" "}
        <a
          href={DELL_CLOUD_PURCHASE_TERMS_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Dell Technologies Cloud Services Terms of Service
        </a>
        .
      </Col>
    </Row>
  </div>
);

export default LicenseAgreementDellEMC;
