import { assign } from "xstate";
import { notifyError } from "slices/toast.slice";
import errorMap from "helpers/errorMap";
import { ERR_INVALID_CREDENTIALS } from "constants/errors";
import { buildRemainingAttemptsMessageForPassword } from "utils/errorMessages";
import { store } from "../../../store";

const notifyLoginError = ({ context: { loginError } }) => {
  const code = loginError?.code;
  const validationFailures = loginError?.errors;
  if (code === ERR_INVALID_CREDENTIALS) {
    let message = "";

    if (validationFailures.length) {
      message = buildRemainingAttemptsMessageForPassword(validationFailures);
    } else {
      message = errorMap[code];
    }

    store.dispatch(notifyError(message));
  } else if (errorMap[code]) {
    store.dispatch(notifyError(errorMap[code]));
  } else {
    store.dispatch(notifyError(loginError?.message));
  }
};

const assignPassword = assign({
  password: ({ event }) => event.password
});

const clearPassword = assign({ password: "" });

const clearAdminIdentifier = assign({ clearAdminIdentifier: "" });

const assignLoginResponse = assign({
  loginResponse: ({ event }) => event.output
});

const assignLoginError = assign({
  loginError: ({ event }) => event.error
});

export {
  assignPassword,
  clearPassword,
  clearAdminIdentifier,
  assignLoginResponse,
  assignLoginError,
  notifyLoginError
};
