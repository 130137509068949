import Page from "orion/lib/page";
import Button from "orion/lib/button";

import { ReactComponent as InvalidIpSvg } from "assets/svgs/invalid-ip.svg";
import AppLayout from "components/AppLayout";
import queryClient from "helpers/queryClient";

const RestrictedGeofencing = () => {
  const data = queryClient.getQueryData(["fetch-ui-customizations"]);

  return (
    <AppLayout>
      <Page.Main>
        <Page.Body className="oui-d-flex oui-align-items-center oui-justify-content-center">
          <div
            className="oui-d-flex oui-flex-column oui-align-items-center"
            style={{ width: "350px" }}
          >
            <InvalidIpSvg title="Password Token Expired" />
            <h5>Invalid IP address</h5>
            <p className="oui-text-muted oui-text-center ">
              The IP Address through which you are trying to log in is either
              invalid or blocked.
            </p>
            <Button
              type="secondary"
              label="Learn More"
              className="oui-h-auto"
              dataTestId="invalid-ip"
              onClick={() => {
                window.location = `https://${data?.fqdns.docPortal}?cid=DCP_Invalid_IP`;
              }}
            />
          </div>
        </Page.Body>
      </Page.Main>
    </AppLayout>
  );
};

export default RestrictedGeofencing;
