import { rest } from "msw";

export const uiCustomizationsResponse = (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      method: "GetUXProfile",
      response: {
        subdomainPrefix: "",
        navURLs: {
          dcp: {
            login: "https://login-dcp-devset2.drtst.in",
            console: "https://d1-server-dcp-devset2.drtst.in"
          },
          phoenix: {
            landingPage: "https://phoenix-devset2.drtst.in",
            marketingPage: "https://phoenix-devset2.drtst.in/trial/Phoenix"
          },
          insync: {
            landingPage: "https://insync-devset2.drtst.in",
            marketingPage: "https://insync-devset2.drtst.in/trial/inSync"
          },
          cloudRanger: {
            landingPage:
              "https://cloudranger-dcp-devset2.drtst.in/#/set_product_session",
            marketingPage:
              "https://cloudranger-dcp-devset2.drtst.in/#/set_product_session"
          }
        },
        fqdns: {
          phoenix: "phoenix-devset2.drtst.in",
          docPortal: "docs.druva.com",
          loginServer: "login-dcp-devset2.drtst.in",
          insync: "insync-devset2.drtst.in",
          consoleServer: "d1-server-dcp-devset2.drtst.in"
        },
        navSections: {
          sectionList: {
            sections: [
              "Home",
              "Data Protection",
              "Data Governance",
              "Cyber Resilience",
              "Administration"
            ]
          }
        },
        emailCustomizations: {
          druvaIconUrl: "https://dcpcdn.druva.com/logo/druvaLogo.png",
          cloudAdminSenderEmail: "cloud.admin@druva.com",
          productName: "Druva Cloud Platform",
          supportSenderEmail: "support@druva.com",
          provider: "Druva",
          brandIconUrl: "https://dcpcdn.druva.com/logo/druvaLogo.png"
        },
        navSectionDetails: [
          {
            sectionItem: {
              items: [
                {
                  heading: "Home",
                  links: [
                    {
                      displayName: "Home",
                      url: "dashboard",
                      productName: "druvaOne",
                      services: [],
                      slug: "home",
                      marketo_url: "",
                      productId: 4097
                    }
                  ]
                },
                {
                  heading: "Cyber Resilience",
                  links: [
                    {
                      displayName: "Ransomware Recovery",
                      url: "realize/ransomwarerecovery",
                      productName: "realize",
                      services: [],
                      slug: "ransomware-recovery",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Devices-Trial-Offer.html",
                      productId: 4097
                    }
                  ]
                },
                {
                  heading: "Administration",
                  links: [
                    {
                      displayName: "Reports",
                      url: "reports",
                      productName: "druvaOne",
                      services: [],
                      slug: "reports",
                      marketo_url: "",
                      productId: 4097
                    },
                    {
                      displayName: "Manage Administrators",
                      url: "admin/administrators",
                      productName: "druvaOne",
                      services: [],
                      slug: "manage-administrators",
                      marketo_url: "",
                      productId: 4097
                    },
                    {
                      displayName: "Druva Cloud Settings",
                      url: "admin/settings",
                      productName: "druvaOne",
                      services: [],
                      slug: "cloud-settings",
                      marketo_url: "",
                      productId: 4097
                    },
                    {
                      displayName: "Account Details",
                      url: "admin/account-details",
                      productName: "druvaOne",
                      services: [],
                      slug: "account-details",
                      marketo_url: "",
                      productId: 4097
                    },
                    {
                      displayName: "Integration Center",
                      url: "admin/integration-center",
                      productName: "druvaOne",
                      services: [],
                      slug: "integration-center",
                      marketo_url: "",
                      productId: 4097
                    }
                  ]
                }
              ]
            },
            knownProductId: 4097
          },
          {
            sectionItem: {
              items: [
                {
                  heading: "Data Protection",
                  links: [
                    {
                      displayName: "Endpoints",
                      url: "admin/app/#/endpoints",
                      productName: "inSync",
                      services: [],
                      slug: "endpoints",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Devices-Trial-Offer.html",
                      productId: 8193
                    },
                    {
                      displayName: "SaaS Apps",
                      url: null,
                      productName: "inSync",
                      services: [
                        {
                          url: "admin/app/#/cloudapps/office365",
                          displayName: "Microsoft 365",
                          slug: "m-365",
                          marketo_url:
                            "https://go.druva.com/TO-Product-Portal-Cloud-Apps-Trial-Offer.html"
                        },
                        {
                          url: "admin/app/#/cloudapps/gsuite",
                          displayName: "Google Workspace",
                          slug: "google-workspace",
                          marketo_url:
                            "https://go.druva.com/TO-Product-Portal-Cloud-Apps-Trial-Offer.html"
                        },
                        {
                          url: "admin/app/#/cloudapps/salesforce",
                          displayName: "Salesforce",
                          slug: "salesforce",
                          marketo_url:
                            "https://go.druva.com/TO-Product-Portal-Cloud-Apps-Trial-Offer.html"
                        },
                        {
                          url: "admin/app/#/cloudapps/slack",
                          displayName: "Slack",
                          slug: "slack",
                          marketo_url:
                            "https://go.druva.com/TO-Product-Portal-Cloud-Apps-Trial-Offer.html"
                        }
                      ],
                      slug: "saas-apps",
                      marketo_url: "",
                      productId: 8193
                    },
                    {
                      displayName: "Share",
                      url: "admin/insync/#op=share-manage",
                      productName: "inSync",
                      services: [],
                      slug: "share",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Devices-Trial-Offer.html",
                      productId: 8193
                    }
                  ]
                },
                {
                  heading: "Data Governance",
                  links: [
                    {
                      displayName: "eDiscovery",
                      url: "admin/app/#/governance/legalhold",
                      productName: "inSync",
                      services: [],
                      slug: "e-discovery",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-eDiscovery-Trial-Offer.html",
                      productId: 8193
                    },
                    {
                      displayName: "Federated Search",
                      url: "admin/app/#/governance/mds",
                      productName: "inSync",
                      services: [],
                      slug: "federated-search",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Federated-Search-Trial-Offer.html",
                      productId: 8193
                    },
                    {
                      displayName: "Sensitive Data Governance",
                      url: "admin/app/#/governance/compliance",
                      productName: "inSync",
                      services: [],
                      slug: "sensitive-data-governance",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Compliance-Trial-Offer.html",
                      productId: 8193
                    }
                  ]
                },
                {
                  heading: "Cyber Resilience",
                  links: [
                    {
                      displayName: "Data Loss Prevention",
                      url: "admin/insync/#op=safepoint-manage",
                      productName: "druvaOne",
                      services: [],
                      slug: "data-loss",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Compliance-Trial-Offer.html",
                      productId: 8193
                    }
                  ]
                }
              ]
            },
            knownProductId: 8193
          },
          {
            sectionItem: {
              items: [
                {
                  heading: "Data Protection",
                  links: [
                    {
                      displayName: "Hybrid Workloads",
                      url: "",
                      productName: "Phoenix",
                      services: [],
                      slug: "hybrid-workloads",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Disaster-Recovery_Trial-Offer.html",
                      productId: 12289
                    }
                  ]
                }
              ]
            },
            knownProductId: 12289
          },
          {
            sectionItem: {
              items: [
                {
                  heading: "Data Protection",
                  links: [
                    {
                      displayName: "Native Workloads",
                      url: "https://cloudranger.druva.com/set_product_session",
                      productName: "CloudRanger",
                      services: [],
                      slug: "cloud-ranger",
                      marketo_url:
                        "https://console.cloudranger.com/?utm_source=druva&utm_medium=referral&utm_campaign=dcp#/signup",
                      productId: 20481
                    }
                  ]
                }
              ]
            },
            knownProductId: 20481
          }
        ],
        uiCustomizations: {
          brand: {
            favIconUrl: "https://dcpcdn.druva.com/favicon/druva.ico",
            title: "Druva",
            loginPageIconUrl: "https://dcpcdn.druva.com/logo/druva.svg",
            brandIconUrl: "https://dcpcdn.druva.com/logo/druva.svg"
          },
          showMarketingBanners: true,
          theme: {
            primaryColor: "#FFFFFF"
          },
          links: {
            supportLink: "https://support.druva.com",
            legalNoticeLink: "",
            privacyPolicyLink: "https://www.druva.com/privacy-policy"
          }
        },
        parentDetails: {
          parentType: 0,
          parentId: ""
        }
      }
    })
  );
};

export const uiCustomizationsDellResponse = (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      method: "GetUXProfile",
      response: {
        subdomainPrefix: "",
        navURLs: {
          dcp: {
            login: "https://login-dcp-devset2.drtst.in",
            console: "https://d1-server-dcp-devset2.drtst.in"
          },
          phoenix: {
            landingPage: "https://phoenix-devset2.drtst.in",
            marketingPage: "https://phoenix-devset2.drtst.in/trial/Phoenix"
          },
          insync: {
            landingPage: "https://insync-devset2.drtst.in",
            marketingPage: "https://insync-devset2.drtst.in/trial/inSync"
          },
          cloudRanger: {
            landingPage:
              "https://cloudranger-dcp-devset2.drtst.in/#/set_product_session",
            marketingPage:
              "https://cloudranger-dcp-devset2.drtst.in/#/set_product_session"
          }
        },
        fqdns: {
          phoenix: "phoenix-devset2.drtst.in",
          docPortal: "docs.druva.com",
          loginServer: "login-dcp-devset2.drtst.in",
          insync: "insync-devset2.drtst.in",
          consoleServer: "d1-server-dcp-devset2.drtst.in"
        },
        navSections: {
          sectionList: {
            sections: [
              "Home",
              "Data Protection",
              "Data Governance",
              "Cyber Resilience",
              "Administration"
            ]
          }
        },
        emailCustomizations: {
          druvaIconUrl: "https://dcpcdn.druva.com/logo/druvaLogo.png",
          cloudAdminSenderEmail: "cloud.admin@druva.com",
          productName: "Druva Cloud Platform",
          supportSenderEmail: "support@druva.com",
          provider: "Druva",
          brandIconUrl: "https://dcpcdn.druva.com/logo/druvaLogo.png"
        },
        navSectionDetails: [
          {
            sectionItem: {
              items: [
                {
                  heading: "Home",
                  links: [
                    {
                      displayName: "Home",
                      url: "dashboard",
                      productName: "druvaOne",
                      services: [],
                      slug: "home",
                      marketo_url: "",
                      productId: 4097
                    }
                  ]
                },
                {
                  heading: "Cyber Resilience",
                  links: [
                    {
                      displayName: "Ransomware Recovery",
                      url: "realize/ransomwarerecovery",
                      productName: "realize",
                      services: [],
                      slug: "ransomware-recovery",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Devices-Trial-Offer.html",
                      productId: 4097
                    }
                  ]
                },
                {
                  heading: "Administration",
                  links: [
                    {
                      displayName: "Reports",
                      url: "reports",
                      productName: "druvaOne",
                      services: [],
                      slug: "reports",
                      marketo_url: "",
                      productId: 4097
                    },
                    {
                      displayName: "Manage Administrators",
                      url: "admin/administrators",
                      productName: "druvaOne",
                      services: [],
                      slug: "manage-administrators",
                      marketo_url: "",
                      productId: 4097
                    },
                    {
                      displayName: "Druva Cloud Settings",
                      url: "admin/settings",
                      productName: "druvaOne",
                      services: [],
                      slug: "cloud-settings",
                      marketo_url: "",
                      productId: 4097
                    },
                    {
                      displayName: "Account Details",
                      url: "admin/account-details",
                      productName: "druvaOne",
                      services: [],
                      slug: "account-details",
                      marketo_url: "",
                      productId: 4097
                    },
                    {
                      displayName: "Integration Center",
                      url: "admin/integration-center",
                      productName: "druvaOne",
                      services: [],
                      slug: "integration-center",
                      marketo_url: "",
                      productId: 4097
                    }
                  ]
                }
              ]
            },
            knownProductId: 4097
          },
          {
            sectionItem: {
              items: [
                {
                  heading: "Data Protection",
                  links: [
                    {
                      displayName: "Endpoints",
                      url: "admin/app/#/endpoints",
                      productName: "inSync",
                      services: [],
                      slug: "endpoints",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Devices-Trial-Offer.html",
                      productId: 8193
                    },
                    {
                      displayName: "SaaS Apps",
                      url: null,
                      productName: "inSync",
                      services: [
                        {
                          url: "admin/app/#/cloudapps/office365",
                          displayName: "Microsoft 365",
                          slug: "m-365",
                          marketo_url:
                            "https://go.druva.com/TO-Product-Portal-Cloud-Apps-Trial-Offer.html"
                        },
                        {
                          url: "admin/app/#/cloudapps/gsuite",
                          displayName: "Google Workspace",
                          slug: "google-workspace",
                          marketo_url:
                            "https://go.druva.com/TO-Product-Portal-Cloud-Apps-Trial-Offer.html"
                        },
                        {
                          url: "admin/app/#/cloudapps/salesforce",
                          displayName: "Salesforce",
                          slug: "salesforce",
                          marketo_url:
                            "https://go.druva.com/TO-Product-Portal-Cloud-Apps-Trial-Offer.html"
                        },
                        {
                          url: "admin/app/#/cloudapps/slack",
                          displayName: "Slack",
                          slug: "slack",
                          marketo_url:
                            "https://go.druva.com/TO-Product-Portal-Cloud-Apps-Trial-Offer.html"
                        }
                      ],
                      slug: "saas-apps",
                      marketo_url: "",
                      productId: 8193
                    },
                    {
                      displayName: "Share",
                      url: "admin/insync/#op=share-manage",
                      productName: "inSync",
                      services: [],
                      slug: "share",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Devices-Trial-Offer.html",
                      productId: 8193
                    }
                  ]
                },
                {
                  heading: "Data Governance",
                  links: [
                    {
                      displayName: "eDiscovery",
                      url: "admin/app/#/governance/legalhold",
                      productName: "inSync",
                      services: [],
                      slug: "e-discovery",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-eDiscovery-Trial-Offer.html",
                      productId: 8193
                    },
                    {
                      displayName: "Federated Search",
                      url: "admin/app/#/governance/mds",
                      productName: "inSync",
                      services: [],
                      slug: "federated-search",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Federated-Search-Trial-Offer.html",
                      productId: 8193
                    },
                    {
                      displayName: "Sensitive Data Governance",
                      url: "admin/app/#/governance/compliance",
                      productName: "inSync",
                      services: [],
                      slug: "sensitive-data-governance",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Compliance-Trial-Offer.html",
                      productId: 8193
                    }
                  ]
                },
                {
                  heading: "Cyber Resilience",
                  links: [
                    {
                      displayName: "Data Loss Prevention",
                      url: "admin/insync/#op=safepoint-manage",
                      productName: "druvaOne",
                      services: [],
                      slug: "data-loss",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Endpoint-Compliance-Trial-Offer.html",
                      productId: 8193
                    }
                  ]
                }
              ]
            },
            knownProductId: 8193
          },
          {
            sectionItem: {
              items: [
                {
                  heading: "Data Protection",
                  links: [
                    {
                      displayName: "Hybrid Workloads",
                      url: "",
                      productName: "Phoenix",
                      services: [],
                      slug: "hybrid-workloads",
                      marketo_url:
                        "https://go.druva.com/TO-Product-Portal-Disaster-Recovery_Trial-Offer.html",
                      productId: 12289
                    }
                  ]
                }
              ]
            },
            knownProductId: 12289
          },
          {
            sectionItem: {
              items: [
                {
                  heading: "Data Protection",
                  links: [
                    {
                      displayName: "Native Workloads",
                      url: "https://cloudranger.druva.com/set_product_session",
                      productName: "CloudRanger",
                      services: [],
                      slug: "cloud-ranger",
                      marketo_url:
                        "https://console.cloudranger.com/?utm_source=druva&utm_medium=referral&utm_campaign=dcp#/signup",
                      productId: 20481
                    }
                  ]
                }
              ]
            },
            knownProductId: 20481
          }
        ],
        uiCustomizations: {
          brand: {
            favIconUrl: "https://dcpcdn.druva.com/favicon/druva.ico",
            title: "Druva",
            loginPageIconUrl: "https://dcpcdn.druva.com/logo/druva.svg",
            brandIconUrl: "https://dcpcdn.druva.com/logo/druva.svg"
          },
          showMarketingBanners: true,
          theme: {
            primaryColor: "#FFFFFF"
          },
          links: {
            supportLink: "https://support.druva.com",
            legalNoticeLink: "",
            privacyPolicyLink: "https://www.druva.com/privacy-policy"
          }
        },
        parentDetails: {
          parentType: 1,
          parentId: ""
        }
      }
    })
  );
};

const handler = rest.get("/api/commonlogin/ping", uiCustomizationsResponse);

export default handler;
