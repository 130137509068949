const state = {
  initial: "idle",
  states: {
    idle: {
      on: {
        UPDATE: {
          actions: "assignPhoneNumber",
          target: "updatePhoneNumber"
        },
        BACK: {
          target: "#login.emailScene"
        }
      }
    },
    updatePhoneNumber: {
      invoke: {
        src: "updatePhoneNumber",
        input: ({ context: { phoneNumber } }) => ({ phoneNumber }),
        id: "updatePhoneNumber",
        onDone: [
          {
            target: "#login.enterOTPScene"
          }
        ],
        onError: [
          {
            actions: "updatePhoneNumberError",
            target: "failure"
          }
        ]
      }
    },
    failure: {
      entry: "notifyPhoneNumberError",
      always: {
        target: "#login.setupSMSScene"
      }
    }
  }
};

export default state;
