import { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "orion/lib/loader";
import OrionProvider from "orion/lib/orionContext";
import { useDispatch } from "react-redux";
import useProductConfig from "hooks/useProductConfig";
import { useFetchUiCustomizationsQuery } from "queries/common.queries";
import { setProductConfig } from "slices/app.slice";

const AppProvider = ({ children }) => {
  const dispatch = useDispatch();
  const productConfig = useSelector((state) => state.app.productConfig);
  const { data, isFetching } = useFetchUiCustomizationsQuery();
  const { orionBrand, isMsp } = useProductConfig();

  useEffect(() => {
    dispatch(setProductConfig(data));
  }, [data, dispatch]);

  useLayoutEffect(() => {
    if (data) {
      const favicon = document.getElementById("product-favicon");
      const brand = data?.uiCustomizations?.brand;

      document.title = brand?.title;
      if (favicon) {
        favicon.href = brand?.favIconUrl;
      }
    }
  }, [data]);

  if (isFetching || !productConfig) {
    return <Loader fullPage isLoading={isFetching} />;
  }

  return (
    <OrionProvider key={orionBrand} brand={orionBrand} dellDoubleHeader={isMsp}>
      {children}
    </OrionProvider>
  );
};

export default AppProvider;
