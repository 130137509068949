import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productConfig: null,
  currentAccount: null,
  otherAccounts: null,
  redirection: null,
  redirectUrl: null
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setProductConfig: (state, action) => {
      state.productConfig = action.payload;
    },

    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload;
    },

    setOtherAccounts: (state, action) => {
      state.otherAccounts = action.payload;
    },

    setRedirection: (state, action) => {
      state.redirection = action.payload;
    },

    setRedirectURL: (state, action) => {
      state.redirectUrl = action.payload;
    }
  }
});

export const {
  setProductConfig,
  setCurrentAccount,
  setOtherAccounts,
  setRedirection,
  setRedirectURL
} = appSlice.actions;

export default appSlice.reducer;
