const buildRemainingAttemptsMessageForPassword = (validationFailures) => {
  let message = null;
  if (validationFailures.length) {
    const attemptsRemaining = validationFailures[0].message;
    message =
      attemptsRemaining === 1
        ? "Your account will be temporarily locked if you fail this last sign in attempt"
        : `Invalid sign in attempt. ${attemptsRemaining} of ${validationFailures[1].message} attempts remaining`;
  }

  return message;
};

const buildRemainingAttemptsMessageForOTP = (validationFailures) => {
  let message = null;
  if (validationFailures.length) {
    const attemptsRemaining = validationFailures[0].message;
    message =
      attemptsRemaining === 1
        ? "Last 1 attempt remaining. Your account will be temporarily locked if you fail this last log in attempt."
        : `Last ${attemptsRemaining} attempts remaining`;
  }

  return message;
};

export {
  buildRemainingAttemptsMessageForPassword,
  buildRemainingAttemptsMessageForOTP
};
