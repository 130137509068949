import {
  useQuery as useReactQuery,
  useMutation as useReactMutation
} from "react-query";
import { isFunction } from "helpers/utils";
import { useToast } from "slices/toast.slice";
import { ERR_INTERNAL_APP_ERROR } from "constants/errors";
import errorMap from "helpers/errorMap";

const handlers = (onError, onWarning, onSuccess, notifyError) => ({
  onSuccess: (result) => {
    // If it is session api, result would be raw response of axios, else it is response.data
    if (result?.config?.url.includes("session")) {
      const warning = result?.data?.warning;
      const error = result?.data?.error;

      if (error && isFunction(onError)) {
        onError(error.code, error);
      } else if (warning && isFunction(onWarning)) {
        onWarning(warning.code, warning);
      } else if (isFunction(onSuccess)) {
        onSuccess(result?.data);
      }
    } else {
      if (result?.warning && isFunction(onWarning)) {
        onWarning(result?.warning?.code, result?.warning);
      } else if (isFunction(onSuccess)) {
        onSuccess(result);
      }
    }
  },
  onError: (error) => {
    if (isFunction(onError)) {
      onError(error?.code, error);
    }

    if (error?.code === ERR_INTERNAL_APP_ERROR) {
      notifyError(errorMap[error?.code]);
    }
  }
});

const useQuery = ({ onError, onWarning, onSuccess, ...options }) => {
  const { notifyError } = useToast();
  const notifyErrorMessage = (message) => notifyError(message);

  return useReactQuery({
    ...options,
    ...handlers(onError, onWarning, onSuccess, notifyErrorMessage)
  });
};

const useMutation = ({ onError, onWarning, onSuccess, ...options }) => {
  const { notifyError } = useToast();
  const notifyErrorMessage = (message) => notifyError(message);

  return useReactMutation({
    ...options,
    ...handlers(onError, onWarning, onSuccess, notifyErrorMessage)
  });
};

export { useQuery, useMutation };
