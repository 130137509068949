import { useNavigate } from "react-router-dom";
import Page from "orion/lib/page";
import Heading from "orion/lib/heading";
import Label from "orion/lib/label";
import Button from "orion/lib/button";
import AppLayout from "components/AppLayout";
import { ReactComponent as BrokenLink } from "assets/svgs/broken-link.svg";
import { ROUTE_LOGIN } from "constants/routes";

const ForgotTokenExpired = () => {
  const navigate = useNavigate();

  return (
    <AppLayout>
      <Page.Main>
        <Page.Body className="oui-pt-0 oui-d-flex oui-justify-content-center oui-align-items-center">
          <div
            className="oui-d-flex oui-flex-column oui-align-items-center"
            style={{ width: "500px", textAlign: "center" }}
          >
            <BrokenLink style={{ width: "120px", height: "100px" }} />
            <Heading type={5} text="Sorry, this link is no longer valid" />
            <Label
              className="oui-mb-0"
              text="The password reset link has been expired. Request for a new link to reset your account password."
              muted
            />
            <Button
              className="oui-mt-3"
              label="Go To Login"
              onClick={() => navigate(ROUTE_LOGIN)}
            />
          </div>
        </Page.Body>
      </Page.Main>
    </AppLayout>
  );
};

export default ForgotTokenExpired;
