import { useQuery } from "hooks/reactQuery";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { fetchGlobalSession } from "apis/session.apis";
import { useLogoutMutation } from "queries/login.queries";
import { useToast } from "slices/toast.slice";
import errorMap from "helpers/errorMap";
import {
  ERR_SESSION_EXPIRED,
  ERR_LOGGED_OFF,
  ERR_PRODUCT_NOT_LICENSED,
  ERR_UNKNOWN_PRODUCT_NAME
} from "constants/errors";
import { WARN_ACCEPT_TERMS, WARN_FEDRAMP_DISCLAIMER } from "constants/warnings";
import {
  ROUTE_FEDRAMP_DISCLAIMER,
  ROUTE_LICENSE_AGREEMENT,
  ROUTE_LOGIN
} from "constants/routes";
import {
  PRODUCT_NOT_LICENSED_PAGE_MAPPING
  // PRODUCT_ID_NAME_MAPPING,
} from "constants/common";

const useCheckGlobalSessionQuery = (payload) => {
  const navigate = useNavigate();
  const { pathname, search: queryString } = useLocation();
  const { mutate: logout } = useLogoutMutation();
  const [searchParams] = useSearchParams();
  const callProductId = searchParams.get("caller_product_id");
  const { notifyError } = useToast();
  const isFedrampRoute = pathname.includes(ROUTE_FEDRAMP_DISCLAIMER);

  return useQuery({
    queryKey: ["check-global-session", payload],
    queryFn: fetchGlobalSession,
    retry: false,
    onSuccess(response) {
      if (
        response?.redirectUrl &&
        !response?.otherAccounts &&
        !response?.warning &&
        !response?.error
      ) {
        window.location.href = response?.redirectUrl;
      }
    },
    onWarning(code) {
      if (code === WARN_ACCEPT_TERMS) {
        navigate(`${ROUTE_LICENSE_AGREEMENT}${queryString}`);
      } else if (code === WARN_FEDRAMP_DISCLAIMER) {
        navigate(`${ROUTE_FEDRAMP_DISCLAIMER}${queryString}`);
      } else {
        if (
          !isFedrampRoute ||
          (isFedrampRoute && code !== WARN_FEDRAMP_DISCLAIMER)
        ) {
          logout();
        }
      }
    },
    onError(code) {
      if (code === ERR_SESSION_EXPIRED || code === ERR_LOGGED_OFF) {
        navigate(`${ROUTE_LOGIN}${queryString}`);
      }

      if (code === ERR_UNKNOWN_PRODUCT_NAME) {
        if (errorMap[code]) {
          notifyError(errorMap[code]);
        }
        // navigate(`${ROUTE_LOGIN}${queryString}`);
        return;
      }
      if (code === ERR_PRODUCT_NOT_LICENSED) {
        // TODO Hanlde Dell case
        const redirect = PRODUCT_NOT_LICENSED_PAGE_MAPPING[callProductId];
        window.location = redirect;
        return;
      }
    }
  });
};

export { useCheckGlobalSessionQuery };
