import TickGreenFilled from "@druva-apps/orion-icons/lib/TickGreenFilled";
import TickGreenOutline from "@druva-apps/orion-icons/lib/TickGreenOutline";
import { checkPasswordStrength } from "helpers/utils";

const PasswordCheckList = ({ password }) => {
  const passwordStrength = checkPasswordStrength(password);

  return (
    <div className="password-checklist">
      <h6>Password must contain atleast</h6>
      <ul className="password-checklist__list">
        <li>
          {passwordStrength.hasValidLength ? (
            <TickGreenFilled inheritFontSize className="oui-mr-2" />
          ) : (
            <TickGreenOutline
              inheritFontColor
              inheritFontSize
              className="oui-mr-2"
            />
          )}
          12 characters
        </li>
        <li>
          {passwordStrength.containsLowerLetters &&
          passwordStrength.containsUpperLetters ? (
            <TickGreenFilled inheritFontSize className="oui-mr-2" />
          ) : (
            <TickGreenOutline
              inheritFontColor
              inheritFontSize
              className="oui-mr-2"
            />
          )}
          One upper case and one lower case letter [a-z, A-Z]
        </li>
        <li>
          {passwordStrength.containsNumbers ? (
            <TickGreenFilled inheritFontSize className="oui-mr-2" />
          ) : (
            <TickGreenOutline
              inheritFontColor
              inheritFontSize
              className="oui-mr-2"
            />
          )}
          One number [0-9]
        </li>
        <li>
          {passwordStrength.containsSpecialChars ? (
            <TickGreenFilled inheritFontSize className="oui-mr-2" />
          ) : (
            <TickGreenOutline
              inheritFontColor
              inheritFontSize
              className="oui-mr-2"
            />
          )}
          One special character [e.g. #@*&%]
        </li>
      </ul>
    </div>
  );
};

export default PasswordCheckList;
