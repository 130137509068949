import { useState, useEffect } from "react";
import Label from "orion/lib/label";
import AuthCard from "shared/AuthCard";
import { useLoginMachine } from "./LoginMachineProvider";
import { ReactComponent as LoginBlocked } from "assets/svgs/login-blocked.svg";
import { ACCOUNT_LOCK_IN_MINS } from "constants/common";

const FormFields = () => {
  const { state, send } = useLoginMachine();
  const validationFailures = state.context.loginError?.errors;
  const mins =
    Array.isArray(validationFailures) && validationFailures[0]?.message
      ? Math.ceil(validationFailures[0].message / 60)
      : ACCOUNT_LOCK_IN_MINS;
  const [count, setCount] = useState(mins);

  useEffect(() => {
    const interval = setInterval(() => setCount(Math.max(0, count - 1)), 60000);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    if (count <= 0) {
      send({ type: "BACK" });
    }
  }, [count, send]);

  return (
    <div className="oui-d-flex oui-justify-content-center oui-align-center-center oui-flex-column oui-mt-4">
      <LoginBlocked style={{ height: "58px" }} alt="Account Locked" />
      <h5 className="oui-text-center oui-mt-4 oui-mb-2 account-locked-heading">
        Account Locked Temporarily
      </h5>
      <Label
        className="oui-text-center oui-m-0"
        text="Your account has been temporarily locked due to security reasons"
        muted
      />
      <Label
        className="oui-text-center oui-mt-2 font-sm"
        text={`Try again after ${count} minutes`}
      />
    </div>
  );
};

const AccountLockedScene = () => {
  return (
    <AuthCard>
      <FormFields />
    </AuthCard>
  );
};

export default AccountLockedScene;
