import { useMemo, createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useActor } from "@xstate/react";
import { DRUVAONE_PRODUCT_ID } from "constants/common";
import { loginMachine } from "machines/loginMachine";

const LoginMachineContext = createContext({});

const LoginMachineProvider = ({ children, initialContext = {} }) => {
  const [state, send] = useActor(loginMachine, {
    input: {
      ...initialContext
    }
  });
  const loginService = {
    send,
    state
  };

  return (
    <LoginMachineContext.Provider value={loginService}>
      {children}
    </LoginMachineContext.Provider>
  );
};

function useLoginMachine() {
  const context = useContext(LoginMachineContext);

  if (context === undefined) {
    throw new Error(
      "useLoginMachine should be used inside of LoginMachineContext"
    );
  }

  return context;
}

function useOtherAccount() {
  const [searchParams] = useSearchParams();
  const callerProductId = searchParams.get("caller_product_id");
  const otherAccounts = useSelector((state) => state.app.otherAccounts);
  const currentAccount = useSelector((state) => state.app.currentAccount);
  const result = useMemo(() => {
    if (callerProductId && otherAccounts?.length > 0) {
      const customer = otherAccounts.find((acc) => {
        return Object.keys(acc?.licensedProducts).includes(callerProductId);
      });

      const newProductNames = Object.keys(customer?.licensedProducts || {})
        .filter((pid) => pid !== String(DRUVAONE_PRODUCT_ID))
        .map((pid) => customer?.licensedProducts[pid])
        .join(", ");

      const oldProductNames = Object.keys(
        currentAccount?.licensedProducts || {}
      )
        .filter((pid) => pid !== String(DRUVAONE_PRODUCT_ID))
        .map((pid) => currentAccount?.licensedProducts[pid])
        .join(", ");

      if (customer) {
        return {
          initialContext: {
            email: currentAccount?.adminEmail,
            authTypeResponse: { authType: customer?.authType },
            adminIdentifier: customer
          },
          newProductNames,
          oldProductNames
        };
      }
    }
    return {
      initialContext: {}
    };
  }, [otherAccounts, currentAccount, callerProductId]);

  return result;
}

export { useLoginMachine, LoginMachineProvider, useOtherAccount };
