const redirectToDashboardFromPasswordScene = ({ context }) => {
  if (context.loginResponse && !context.loginResponse?.warning) {
    window.location.href = context.loginResponse?.redirectUrl;
  }
};

const redirectToDashboardFromOTPScene = ({ context }) => {
  if (context.submitOTPResponse && !context.submitOTPResponse?.warning) {
    window.location.href = context.submitOTPResponse?.redirectUrl;
  }
};

const redirectToDashboardFromFedrampScene = ({ context }) => {
  if (
    context.acceptFedrampResponse &&
    !context.acceptFedrampResponse?.warning
  ) {
    window.location.href = context.acceptFedrampResponse?.redirectUrl;
  }
};

export {
  redirectToDashboardFromPasswordScene,
  redirectToDashboardFromOTPScene,
  redirectToDashboardFromFedrampScene
};
