import { assign } from "xstate";
import { store } from "../../../store";
import { notifyError } from "slices/toast.slice";
import errorMap from "helpers/errorMap";

const assignSamlLoginResponse = assign({
  samlLoginResponse: ({ event }) => event.output
});

const assignSamlLoginError = assign({
  samlLoginError: ({ event }) => event.error
});

const notifySamlLoginError = ({ context: { samlLoginError } }) => {
  const code = samlLoginError?.code;
  store.dispatch(notifyError(errorMap[code]));
};

const redirectToSamlProvider = ({ context }) => {
  if (context.samlLoginResponse?.redirectUrl) {
    window.location.href = context.samlLoginResponse?.redirectUrl;
  }
};

export {
  assignSamlLoginResponse,
  assignSamlLoginError,
  redirectToSamlProvider,
  notifySamlLoginError
};
