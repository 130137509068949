import Label from "orion/lib/label";
import Loader from "orion/lib/loader";
import AuthCard from "shared/AuthCard";
import { useLoginMachine } from "./LoginMachineProvider";
import BackToLogin from "./BackToLogin";

const ForgotPasswordSuccessScene = () => {
  const { state, send } = useLoginMachine();
  const isForgotPasswordLoading = state.matches(
    "forgotPasswordSuccessScene.loading"
  );

  return (
    <Loader isLoading={isForgotPasswordLoading}>
      <AuthCard
        dataTestId="forgot-password-success-card"
        cardContentClass="oui-py-3"
      >
        <div>
          <h4 data-testid="card-title">Verification Link Sent</h4>
          <div>
            <Label
              text="We have sent a verification link to your registered email address. Click on the link to reset your password."
              muted
            />
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                send({
                  type: "RESEND"
                });
              }}
            >
              Resend Email
            </a>
          </div>
        </div>
        <BackToLogin />
      </AuthCard>
    </Loader>
  );
};

export default ForgotPasswordSuccessScene;
