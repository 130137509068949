import { rest } from "msw";

const loginUsingPasswordResp = {
  invalidCredentials: {
    method: "LoginUsingPassword",
    error: {
      validationFailures: [
        { argumentName: "login_attempts_remaining", message: 3 },
        { argumentName: "allowed_login_count", message: 5 }
      ],
      code: 4295032832,
      extraData: null,
      message:
        "Invalid email address or password. (#100010000), input errors={'login_attempts_remaining': 3, 'allowed_login_count': 5} (#100010000), input errors={u'login_attempts_remaining': 3, u'allowed_login_count': 5}",
      type: "AUTH_ERR",
      isTemporary: false
    }
  },
  changePasswordWarning: {
    method: "LoginUsingPassword",
    warning: {
      code: 4296015892
    }
  },
  setupTOTPWarning: {
    method: "LoginUsingPassword",
    warning: {
      code: 4297064481
    }
  },
  noOtpRequired: {
    method: "LoginUsingPassword",
    response: {
      redirectURL:
        "https://d1-server-dcp-devset2.drtst.in/set-session?source_url=https%3A%2F%2Fd1-server-dcp-devset2.drtst.in%2Fdashboard&OTP=6d112788916473b4bee8",
      licensedProducts: {
        4097: "DruvaOne",
        8193: "inSync"
      }
    }
  },
  provideEmailOtp: {
    warning: {
      message: "Please provide valid security code to login. (#10020001f)",
      code: 4297064479,
      type: "Authenticate",
      isTemporary: false
    },
    method: "LoginUsingPassword",
    response: {
      redirectURL:
        "https://d1-server-dcp-devset2.drtst.in/set-session?source_url=https%3A%2F%2Fd1-server-dcp-devset2.drtst.in%2Fdashboard",
      licensedProducts: {
        4097: "DruvaOne",
        8193: "inSync"
      }
    }
  },
  provideSMSOtp: {
    warning: {
      message: "Please provide valid security code to login. (#100200029)",
      code: 4297064489,
      type: "Authenticate",
      isTemporary: false
    },
    method: "LoginUsingPassword",
    response: {
      redirectURL:
        "https://d1-server-dcp-devset2.drtst.in/set-session?source_url=https%3A%2F%2Fd1-server-dcp-devset2.drtst.in%2Fdashboard",
      licensedProducts: {
        4097: "DruvaOne",
        8193: "inSync"
      }
    }
  },
  setupSMSMFA: {
    warning: {
      message: "Please register a phone number to login. (#10020002a)",
      code: 4297064490,
      type: "Authenticate",
      isTemporary: false
    },
    method: "LoginUsingPassword",
    response: {
      redirectURL:
        "https://consoledmfa.drtst.org/set-session?source_url=https%3A%2F%2Fconsoledmfa.drtst.org%2Fdashboard",
      licensedProducts: { 4097: "DruvaOne", 12289: "Phoenix" }
    }
  },
  provideTotp: {
    warning: {
      message: "Please provide valid security code to login. (#100200022)",
      code: 4297064482,
      type: "Authenticate",
      isTemporary: false
    },
    method: "LoginUsingPassword",
    response: {
      redirectURL:
        "https://d1-server-dcp-devset2.drtst.in/set-session?source_url=https%3A%2F%2Flogin-dcp-devset2.drtst.in%2F",
      licensedProducts: { 4097: "DruvaOne", 8193: "inSync" }
    }
  },
  fedramp: {
    warning: {
      message: "Post login must show splash page. (#10010001e)",
      code: 4296015902,
      type: "Authenticate",
      isTemporary: false
    },
    method: "LoginUsingPassword",
    response: {
      redirectURL:
        "https://consoledfedramp.drtst.org/set-session?source_url=https%3A%2F%2Fconsoledfedramp.drtst.org%2Fdashboard",
      licensedProducts: { 4097: "DruvaOne", 8193: "inSync" }
    }
  },
  lockedUser: {
    method: "LoginUsingPassword",
    error: {
      validationFailures: [
        { argumentName: "lock_duration_left", message: 1799.9810779094696 },
        {
          argumentName: "Password",
          message:
            "Number of invalid password attempts exceed the specified limit. Account locked till 10:30AM UTC"
        }
      ],
      code: 4296015893,
      extraData: null,
      message:
        "Account locked till 10:30AM UTC (#100100015), input errors={'lock_duration_left': 1799.9810779094696, 'Password': 'Number of invalid password attempts exceed the specified limit. Account locked till 10:30AM UTC'} (#100100015), input errors={u'lock_duration_left': 1799.9810779094696, u'Password': u'Number of invalid password attempts exceed the specified limit. Account locked till 10:30AM UTC'}",
      type: "AUTH_ERR",
      isTemporary: false
    }
  },
  showTermsAndConditions: {
    warning: {
      message: "Please accept the terms and conditions to proceed.",
      code: 4296015901,
      type: "Authenticate",
      isTemporary: false
    },
    method: "LoginUsingPassword",
    response: {}
  }
};

const validateSecurityCode = {
  invalidOtp: {
    method: "ValidateSecurityCode",
    error: {
      validationFailures: [
        { argumentName: "login_attempts_remaining", message: 4 },
        { argumentName: "allowed_login_count", message: 5 }
      ],
      code: 4297064474,
      extraData: null,
      message:
        "Invalid Verification OTP (#10020001a), input errors={'login_attempts_remaining': 4, 'allowed_login_count': 5} (#10020001a), input errors={u'login_attempts_remaining': 4, u'allowed_login_count': 5}",
      type: "AUTH_ERR",
      isTemporary: false
    }
  },
  validOtp: {
    method: "LoginUsingPassword",
    response: {
      redirectURL:
        "https://d1-server-dcp-devset2.drtst.in/set-session?source_url=https%3A%2F%2Fd1-server-dcp-devset2.drtst.in%2Fdashboard&OTP=6d112788916473b4bee8",
      licensedProducts: {
        4097: "DruvaOne",
        8193: "inSync"
      }
    }
  }
};

const loginUsingSAMLResp = {
  method: "LoginUsingSAML",
  response: {
    redirectURL:
      "https://dev-270340.oktapreview.com/app/dev-270340_dtotptest1_1/exk1777iktcxEZXSb0h8/sso/saml?SAMLRequest=fVJNb%2BIwEL3zK1Du%2BYTi1gIkCl1tpXY3AlpVvSDjDGCR2KlnkuXnr4n7fajli2fmPb%2F37DGKqqz5rKGDXsJLA0i9vlunqtTIu%2BYkaKzmRqBCrkUFyEny1ez%2BjmdRwmtryEhTBt9gP6MEIlhSRnvY7WISLJabJMt2g2HCWAqjQSa3V8OUSSm3o4uMDYfZYFRkQgoQHvQIFh3DJHCEvpJb06oC7B93nyO0TSv689I0RT8vBe2MrfzcL2MldJYnwU6UCK8qMHe6VAvfqtjArUYSmtxdTmOYsDAZrdMLnly6%2FeznFi46pQV1kg5ENfI4LqANM5Y4U5E5kqgttAr%2BRdJUsajrT%2B1NQYZqchzpJo3hdEwZY%2BpI8nTz%2FLTaJofLGNHE52TfvXa5XytdKL3%2FOe6tH0L%2Be73Ow%2Fzvau1JZm%2FPMDcamwrsCmyrJDws7z48lGbv4Gd5UWEJKTJ279Sr2LmojO7anTDpSYJpxz0%2Bl3iXnp0u5nn4wTOOP%2Fd6PX%2F%2B%2Bgun%2FwE%3D&Signature=txkpUB82YLLACYZpKXHXOCWyvVrs1jqoxGSc7khqHQliT%2Bp6CEBBackkRtVsRtBaoFO4bmQIkv%2Fahs3dp%2Bv5n80PdYFWeIorYb6RewA4CXcVUmjRfEybLwydm0cCExJdCjAeHcMgjns%2BIz%2BXCIIyHw0B8o9JC6MtI2yRxCzLPyg35b71BQJu%2BC5911F4IEIlyodyh7Uj8hEihzvIGiKbUuJIQZiB%2BaKKX7h%2FA2kD9waewfBKmxlwpZ6j58QTlq2noRiJzURoqIwycTTEO3AJ1UjLtraOho2Bmz6qdAEefSAMaMFGW7ZcgJ8ymIk34ROBMlBNdjkv2e4NsumybVbtKCTgduX%2BVhH8Xq2vqym9%2BCNYf7J5WS9%2BSx6tfY7L85%2F0pgPJe17HzRvfngX9H0nI9qmuhP%2BoL%2FLORlvwXILFpAmbn5HhSgK%2BGk6Dh3vrl92VNBZwAWcp0dNmpT%2FRhgX8ik4weX3FP7aqoBh7FXGaWvaxU1dyoXqjYloAD6rFhR2dqpzy1crfrWdAkQXPavP4J4TRVnLJZcC7iadJx8%2F6VZ20vc1pi4i6ESzRS%2B7TttJZ6C75IV6zJ4poJvaYPOb1HeO%2B1tcyZCzYO3Ir5yGXFIx43rGFQk3GrGHDNVoGOtuOlyefGQjwDHTrnoKrgRVpL7XBuAg6zC8xZio5F7EfVx8%3D&RelayState=40%3A114.143.238.10%3A12289%3A%3AFalse&SigAlg=http%3A%2F%2Fwww.w3.org%2F2001%2F04%2Fxmldsig-more%23rsa-sha256"
  }
};

const loginUsingPasswordTokenResp = {
  abc: {
    warning: {
      message: "Your password has expired. (#100100014)",
      code: 4296015892,
      type: "Authenticate",
      isTemporary: false
    },
    method: "LoginUsingPasswordToken",
    response: {
      redirectURL: "",
      currentAccount: {
        customerName: "manish.jangir@druva.com",
        adminEmail: "manish.jangir@druva.com"
      },
      licensedProducts: { 4097: "DruvaOne", 12289: "Phoenix" }
    }
  },
  def: {
    error: {
      code: 4296015912,
      extraData: null,
      isTemporary: false,
      message: "Invalid password reset URL",
      type: "AUTH_ERR",
      validationFailures: []
    },
    method: "LoginUsingPasswordToken"
  },
  ghi: {
    error: {
      code: 4296015881,
      extraData: null,
      isTemporary: false,
      message:
        "An error occurred while performing this operation. If the error persists, contact Druva Support.",
      type: "AUTH_ERR",
      validationFailures: []
    },
    method: "LoginUsingPasswordToken"
  },

  jkl: {
    warning: {
      message: "Please accept the terms and conditions to proceed.",
      code: 4296015901,
      type: "Authenticate",
      isTemporary: false
    },
    method: "LoginUsingPasswordToken",
    response: {}
  },
  mno: {
    method: "LoginUsingPasswordToken",
    error: {
      validationFailures: [],
      code: 4296015903,
      extraData: null,
      message: "Client IP not allowed. (#10010001f) (#10010001f)",
      type: "AUTH_ERR",
      isTemporary: false
    }
  }
};

const loginAsInsyncUserResp = {
  method: "LoginAsInsyncUser",
  response: {
    redirectURL: "https://ihy.drtst.org:443/home/"
  }
};

const logoutResp = {
  method: "Logout",
  error: {
    validationFailures: [],
    code: 4294971397,
    extraData: null,
    message:
      "Your session has expired. Please login again. (#100001005) (#100001005)",
    type: "AUTH_ERR",
    isTemporary: false
  }
};

const handler = rest.post("/api/commonlogin/login", (req, res, ctx) => {
  const method = req.body.request.method;

  if (method === "LoginUsingPassword") {
    const email = req.body.request.kwargs.auth_input.email;
    const adminIdentifier = req.body.request.kwargs.auth_input.admin_identifier;
    const password = req.body.request.kwargs.auth_input.password;

    if (password === "123456") {
      return res(
        ctx.status(200),
        ctx.json(loginUsingPasswordResp.changePasswordWarning)
      );
    }

    if (email === "setuptotp@d.in" && password === "1234567") {
      return res(
        ctx.status(200),
        ctx.json(loginUsingPasswordResp.setupTOTPWarning)
      );
    }

    if (email === "lockeduser@d.in") {
      return res(ctx.status(200), ctx.json(loginUsingPasswordResp.lockedUser));
    }

    if (email === "promotedadmin@d.in") {
      return res(
        ctx.status(200),
        ctx.json(loginUsingPasswordResp.showTermsAndConditions)
      );
    }

    if (password !== "Druv@1234567") {
      return res(
        ctx.status(200),
        ctx.json(loginUsingPasswordResp.invalidCredentials)
      );
    }

    if (email === "manish.jangir@druva.com" || email === "migrated@d.in") {
      return res(
        ctx.status(200),
        ctx.json(loginUsingPasswordResp.provideEmailOtp)
      );
    }

    if (
      email === "withoutotp@d.in" ||
      adminIdentifier === "8:11:otheradmin@d.in" ||
      adminIdentifier === "10:12:otheradmin@d.in"
    ) {
      return res(
        ctx.status(200),
        ctx.json(loginUsingPasswordResp.noOtpRequired)
      );
    }

    if (email === "migratedtotp@d.in") {
      return res(ctx.status(200), ctx.json(loginUsingPasswordResp.provideTotp));
    }

    if (email === "fedrampuser@d.in") {
      return res(ctx.status(200), ctx.json(loginUsingPasswordResp.fedramp));
    }

    if (email === "smswarning@d.in") {
      return res(
        ctx.status(200),
        ctx.json(loginUsingPasswordResp.provideSMSOtp)
      );
    }
    if (email === "setupsmsmfa@d.in") {
      return res(ctx.status(200), ctx.json(loginUsingPasswordResp.setupSMSMFA));
    }
    return res(ctx.status(200), ctx.json(loginUsingPasswordResp.noOtpRequired));
  }

  if (method === "LoginUsingPasswordToken") {
    const password_token = req.body.request.args[0];
    return res(
      ctx.status(200),
      ctx.json(loginUsingPasswordTokenResp[password_token])
    );
  }

  if (method === "ValidateSecurityCode") {
    const otp = req.body.request.kwargs.security_code;

    if (otp === 123456) {
      return res(ctx.status(200), ctx.json(validateSecurityCode.validOtp));
    } else {
      return res(ctx.status(200), ctx.json(validateSecurityCode.invalidOtp));
    }
  }

  if (method === "ResendSecurityCode") {
    return res(
      ctx.status(200),
      ctx.json({
        success: true
      })
    );
  }

  if (method === "LoginUsingSAML") {
    return res(ctx.status(200), ctx.json(loginUsingSAMLResp));
  }

  if (method === "LoginAsInsyncUser") {
    return res(ctx.status(200), ctx.json(loginAsInsyncUserResp));
  }
  if (method === "Logout") {
    return res(ctx.status(200), ctx.json(logoutResp));
  }
  return res(ctx.status(200), ctx.json({}));
});

export default handler;
