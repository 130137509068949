const state = {
  initial: "idle",
  states: {
    idle: {
      on: {
        BACK: "#login.emailScene",
        SUBMIT: [
          {
            target: "#login.passwordScene",
            guard: "chooseAdminIdentifierForPasswordLogin",
            actions: ["assignAdminIdentifier"]
          },
          {
            target: "#login.samlScene",
            guard: "chooseAdminIdentifierForSamlLogin",
            actions: ["assignAdminIdentifier"]
          }
        ]
      }
    }
  }
};

export default state;
