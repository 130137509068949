const state = {
  initial: "idle",
  states: {
    idle: {
      on: {
        BACK: {
          target: "#login.emailScene",
          actions: "clearCredentials"
        }
      }
    }
  }
};

export default state;
