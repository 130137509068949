const chooseAdminIdentifierForPasswordLogin = ({ context, event }) =>
  event.adminIdentifier.authType === "PASSWORD" ||
  (event.adminIdentifier.authType === "SAMLTOKEN" && context.notUsingSSO);

const chooseAdminIdentifierForSamlLogin = ({ context, event }) =>
  event.adminIdentifier.authType === "SAMLTOKEN" && !context.notUsingSSO;

export {
  chooseAdminIdentifierForPasswordLogin,
  chooseAdminIdentifierForSamlLogin
};
