import { assign } from "xstate";
import { notifyError } from "slices/toast.slice";
import errorMap from "helpers/errorMap";
import { store } from "../../../store";

const assignPhoneNumber = assign({
  phoneNumber: ({ event }) => event.phoneNumber
});

const updatePhoneNumberError = assign({
  updatePhoneNumberError: ({ event }) => event.error
});

const notifyPhoneNumberError = ({ context: { updatePhoneNumberError } }) => {
  const code = updatePhoneNumberError?.code;
  store.dispatch(notifyError(errorMap[code]));
};

export { assignPhoneNumber, updatePhoneNumberError, notifyPhoneNumberError };
