import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Page from "orion/lib/page";
import Container from "orion/lib/container";
import Button from "orion/lib/button";

import { useLogoutMutation } from "queries/login.queries";
import { useAcceptLicenseAgreementMutation } from "queries/admin.queries";
import useProductConfig from "hooks/useProductConfig";
import AppLayout from "components/AppLayout";
import SetPasswordForm from "components/SetPassword/SetPasswordForm";
import LicenseAgreementDruva from "./LicenseAgreementDruva";
import LicenseAgreementDell from "./LicenseAgreementDell";
import "./licenseAgreement.scss";

const LicenseAgreement = () => {
  const { isDell } = useProductConfig();
  const [searchParams] = useSearchParams();
  const redirectUrl =
    useSelector((state) => state.app.redirectUrl) ||
    searchParams.get("target_url");
  const { mutate: logout, isLoading: loggingOut } = useLogoutMutation();
  const {
    mutate: accept,
    isLoading: isAccepting,
    data
  } = useAcceptLicenseAgreementMutation();
  const disabledButtons = isAccepting || loggingOut;

  const handleAccept = () => {
    accept(redirectUrl);
  };

  if (data?.warning) {
    return <SetPasswordForm />;
  }

  return (
    <AppLayout>
      <Page.Main>
        <Page.Body className="oui-pt-5">
          <Container className="oui-h-100 oui-d-flex oui-flex-column oui-pt-3">
            <div className="oui-overflow-auto oui-border oui-p-3">
              {isDell ? <LicenseAgreementDell /> : <LicenseAgreementDruva />}
            </div>
            <div className="oui-py-4 oui-text-right">
              <Button
                label="Accept & Continue"
                type="primary"
                isLoading={isAccepting}
                disabled={disabledButtons}
                onClick={disabledButtons ? null : handleAccept}
                dataTestId="accept-license-agreement"
              />
              <Button
                label="Cancel"
                isLoading={loggingOut}
                disabled={disabledButtons}
                onClick={disabledButtons ? null : () => logout()}
                dataTestId="decline-license-agreement"
              />
            </div>
          </Container>
        </Page.Body>
      </Page.Main>
    </AppLayout>
  );
};

export default LicenseAgreement;
