import Headerbar from "orion/lib/headerbar";
import useProductConfig from "hooks/useProductConfig";

const TopNav = () => {
  const { brandName, logo } = useProductConfig();

  return (
    <Headerbar
      brand={{
        name: brandName,
        onClick: null,
        logoUrl: logo
      }}
    />
  );
};

export default TopNav;
