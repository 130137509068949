import { ERR_ACCOUNT_LOCKED } from "constants/errors";
import {
  WARN_PASSWORD_EXPIRED,
  WARN_FEDRAMP_DISCLAIMER
} from "constants/warnings";

const accountLockedFromOTP = ({ context: { submitOTPError } }) =>
  submitOTPError?.code === ERR_ACCOUNT_LOCKED;

const changePasswordRequiredFromOTP = ({ context: { submitOTPResponse } }) =>
  submitOTPResponse?.warning?.code === WARN_PASSWORD_EXPIRED;

const showFedrampDisclaimerFromOTPScreen = ({
  context: { submitOTPResponse }
}) => submitOTPResponse?.warning?.code === WARN_FEDRAMP_DISCLAIMER;

const isSuccessfullyLoggedInFromOTP = ({ context: { submitOTPResponse } }) =>
  !submitOTPResponse?.warning &&
  !submitOTPResponse?.error &&
  submitOTPResponse?.redirectUrl;

export {
  changePasswordRequiredFromOTP,
  accountLockedFromOTP,
  isSuccessfullyLoggedInFromOTP,
  showFedrampDisclaimerFromOTPScreen
};
