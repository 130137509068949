import { rest } from "msw";

const responses = {
  GetAuthType: {
    "manish.jangir@druva.com": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "promotedadmin@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "withoutotp@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "migrated@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "migratedtotp@d.in": {
      method: "GetAuthType",
      response: { loginUsingPassword: true, redirectURL: "" }
    },
    "setuptotp@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "otheradmin@d.in": {
      method: "GetAuthType",
      response: {
        accounts: [
          {
            redirectURL: "",
            authType: "PASSWORD",
            adminIdentifier: "8:11:otheradmin@d.in",
            allowPasswordIfSAML: true,
            licensedProducts: { 8193: "inSync" }
          },
          {
            redirectURL: "",
            authType: "PASSWORD",
            adminIdentifier: "10:12:otheradmin@d.in",
            allowPasswordIfSAML: true,
            licensedProducts: { 12289: "Phoenix" }
          }
        ]
      }
    },
    "fedrampuser@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "delluser@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL:
          "https://dell-logindtest2.drtst.org/login?email=ps282%40d.in",
        isForgotPasswordAllowed: true
      }
    },
    "samluser@d.in": {
      method: "GetAuthType",
      response: {
        redirectURL: "",
        accounts: [
          {
            authType: "SAMLTOKEN",
            adminIdentifier: "40:812:samlcertold@d.in",
            licensedProducts: { 12289: "Phoenix" },
            isForgotPasswordAllowed: false,
            redirectURL: "",
            allowPasswordIfSAML: true
          }
        ]
      }
    },
    "lockeduser@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "smswarning@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "setupsmsmfa@d.in": {
      method: "GetAuthType",
      response: {
        loginUsingPassword: true,
        redirectURL: "",
        isForgotPasswordAllowed: true
      }
    },
    "commonloginhydra@d.in": {
      method: "GetAuthType",
      response: {
        accounts: [
          {
            authType: "SAMLTOKEN",
            adminIdentifier: "38555:53703:commonloginhydra@d.in",
            licensedProducts: { 12289: "Phoenix" },
            isForgotPasswordAllowed: true,
            redirectURL: "",
            allowPasswordIfSAML: true
          },
          {
            authType: "SAMLTOKEN",
            adminIdentifier: "38556:53705:commonloginhydra@d.in",
            licensedProducts: { 8193: "inSync" },
            isForgotPasswordAllowed: true,
            redirectURL: "",
            allowPasswordIfSAML: true
          }
        ]
      }
    }
  },
  ChangePassword: {
    "Druv@1234567": {},
    "Hacker@12345": {
      method: "ChangePassword",
      error: {
        code: 4296015883,
        message: ""
      }
    },
    "Logout@123456": {
      method: "ChangePassword",
      error: {
        code: 4294971396,
        message: ""
      }
    }
  },
  GenerateSoftwareToken: {
    method: "GenerateSoftwareToken",
    response: {
      software_token: "abcdefgh"
    }
  },
  PostLoginSplash: {
    method: "PostLoginSplash",
    response: {
      redirectURL:
        "https://consoledfedramp.drtst.org/set-session?source_url=https%3A%2F%2Fconsoledfedramp.drtst.org%2Fdashboard"
    }
  },
  ForgotPassword: {
    "manish.jangir@druva.com": {
      method: "ForgotPassword",
      response: {}
    },

    "fedrampuser@d.in": {
      method: "ForgotPassword",
      error: {
        code: 4297064484,
        message: "API attempts limit exceeded for user"
      }
    },
    "setuptotp@d.in": {
      method: "ForgotPassword",
      error: {
        code: 4297064488,
        message: "Admin needs to complete forgot password flow"
      }
    },
    "withoutotp@d.in": {
      method: "ForgotPassword",
      error: {
        code: 4297064485,
        message: "Admin is not activated"
      }
    },
    "lockeduser@d.in": {
      method: "ForgotPassword",
      error: {
        code: 12345,
        message: ""
      }
    }
  },
  ValidateForgotPasswordToken: {
    withToken: {
      response: {
        passwordPolicy: {
          minSymbols: 1,
          minUpperChars: 1,
          minChangeInPassword: 0,
          minLowerChars: 1,
          id: 2,
          accountLockDuration: 1800,
          forceStrongPassword: true,
          minPasswordAge: 0,
          passwordHistorySize: 5,
          policyEnabled: true,
          forceChangeTempPassword: true,
          policyMandatory: false,
          maxPasswordAge: 60,
          minDigits: 1,
          wrongPasswordRetryThreshold: 5,
          customerId: 2,
          minPasswordLength: 12,
          isIdmgmtMigrated: false
        },
        email: "manish.jangir@druva.com"
      }
    },
    withoutToken: {
      method: "ValidateForgotPasswordToken",
      error: {
        type: "ADMIN_ERR",
        code: 4296015881,
        validationFailures: [
          {
            argumentName: "token",
            message: "Invalid token"
          }
        ],
        message:
          "Invalid inputs to method. (#100100009), input errors={'token': 'Invalid token'}",
        isTemporary: false,
        extraData: null
      }
    }
  },
  AcceptLogin: {
    method: "AcceptLogin",
    response: {
      redirectURL: "/login"
    }
  },
  ConfirmForgotPassword: {
    "Druv@1234567": {},
    "Error1@12345": {
      method: "ConfirmForgotPassword",
      error: {
        code: 4297064486,
        message: ""
      }
    },
    "Error2@12345": {
      method: "ConfirmForgotPassword",
      error: {
        code: 4297064487,
        message: ""
      }
    },
    "Error3@12345": {
      method: "ConfirmForgotPassword",
      error: {
        code: 4297064488,
        message: ""
      }
    },
    "Error4@12345": {
      method: "ConfirmForgotPassword",
      error: {
        code: 4296015895,
        message: ""
      }
    },
    "DefaultError@12": {
      method: "ConfirmForgotPassword",
      error: {
        code: 1234567,
        message: "Internal application error"
      }
    }
  },
  phone: {
    method: "phone",
    response: { method: "phone", response: null }
  }
};

const getHandler = rest.get("/api/commonlogin/admin", (req, res, ctx) => {
  const input = decodeURIComponent(req.url.search.replace("?input=", ""));
  const inputJson = JSON.parse(input);
  const email = inputJson.request.args[0];
  const method = inputJson.request.method;
  return res(ctx.status(200), ctx.json(responses[method][email]));
});

const postHandler = rest.post("/api/commonlogin/admin", (req, res, ctx) => {
  const method = req.body.request.method;

  if (method === "ChangePassword") {
    const password = req.body.request.args[1];
    return res(
      ctx.status(200),
      ctx.json(responses["ChangePassword"][password])
    );
  }

  if (method === "GenerateSoftwareToken") {
    return res(ctx.status(200), ctx.json(responses["GenerateSoftwareToken"]));
  }

  if (method === "PostLoginSplash") {
    return res(ctx.status(200), ctx.json(responses["PostLoginSplash"]));
  }

  if (method === "ForgotPassword") {
    const email = req.body.request.kwargs.admin_input.admin_email;
    return res(ctx.status(200), ctx.json(responses["ForgotPassword"][email]));
  }

  if (method === "ValidateForgotPasswordToken") {
    const token = req.body.request.kwargs.token;

    return res(
      ctx.status(200),
      ctx.json(
        token
          ? responses["ValidateForgotPasswordToken"]["withToken"]
          : responses["ValidateForgotPasswordToken"]["withoutToken"]
      )
    );
  }

  if (method === "AcceptLogin") {
    return res(ctx.status(200), ctx.json(responses.AcceptLogin));
  }

  if (method === "ConfirmForgotPassword") {
    const password = req.body.request.kwargs.new_password;
    return res(
      ctx.status(200),
      ctx.json(responses["ConfirmForgotPassword"][password])
    );
  }
  if (method === "phone") {
    return res(ctx.status(200), ctx.json(responses["phone"]));
  }
  return res(ctx.status(200), ctx.json({}));
});

export { postHandler, getHandler };
