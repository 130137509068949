import { DRUVAONE_PRODUCT_ID } from "constants/common";
import { apiInstance } from "helpers/request.helpers";

const loginUsingPasswordToken = ({ queryKey }) =>
  apiInstance.post("commonlogin/login", {
    request: {
      method: "LoginUsingPasswordToken",
      args: [queryKey[1]]
    }
  });

const loginUsingSaml = (account, callerProductId, sourceUrl) => {
  if (account) {
    const adminIdentifier = account.adminIdentifier;
    const licensedProducts = Object.keys(account?.licensedProducts || {}).map(
      (id) => parseInt(id, 10)
    );

    if (!callerProductId) {
      callerProductId =
        licensedProducts.length === 1
          ? licensedProducts[0]
          : DRUVAONE_PRODUCT_ID;
    }

    return apiInstance.post("commonlogin/login", {
      request: {
        method: "LoginUsingSAML",
        args: [adminIdentifier, callerProductId, sourceUrl]
      }
    });
  }

  return Promise.reject(false);
};

const loginUsingPassword = (
  email,
  password,
  adminIdentifier,
  callerProductId,
  sourceUrl
) => {
  let auth_input = {
    email,
    password
  };

  if (adminIdentifier) {
    auth_input = {
      admin_identifier: adminIdentifier.adminIdentifier,
      password
    };
  }

  return apiInstance.post("commonlogin/login", {
    request: {
      method: "LoginUsingPassword",
      kwargs: {
        caller_product_id: callerProductId,
        source_url: sourceUrl,
        redirect_on_success: false,
        auth_input
      }
    }
  });
};

const validateSecurityCode = (otp, redirectUrl) =>
  apiInstance.post("commonlogin/login", {
    request: {
      method: "ValidateSecurityCode",
      kwargs: {
        security_code: otp,
        redirect_url: redirectUrl,
        redirect_on_success: false
      }
    }
  });

const logout = () =>
  apiInstance.post("commonlogin/login", {
    request: {
      method: "Logout"
    }
  });

const generateSoftwareToken = () => {
  const promise = apiInstance.post("commonlogin/admin", {
    request: {
      method: "GenerateSoftwareToken",
      args: []
    }
  });
  return promise;
};

const updatePhoneNumber = (phoneNumber) => {
  return apiInstance.post("commonlogin/admin", {
    request: {
      method: "phone",
      args: [phoneNumber]
    }
  });
};

const fetchInsyncProductUrl = (email) => {
  return apiInstance.post("commonlogin/login", {
    request: { method: "LoginAsInsyncUser", args: [email] }
  });
};

const fetchPasswordPolicy = ({ queryKey }) => {
  return apiInstance.get("commonlogin/customer/passwordpolicy", {
    params: {
      input: { request: { method: "GetPolicy" } }
    },
    queryKey
  });
};

const resendEmailOTP = () =>
  apiInstance.post("commonlogin/login", {
    request: { method: "ResendSecurityCode" }
  });

const forgotPassword = (email, adminIdentifier) => {
  let admin_input = {
    admin_email: email
  };

  if (adminIdentifier) {
    admin_input = {
      admin_identifier: adminIdentifier.adminIdentifier
    };
  }

  return apiInstance.post("commonlogin/admin", {
    request: {
      method: "ForgotPassword",
      kwargs: {
        admin_input
      }
    }
  });
};

export {
  loginUsingPasswordToken,
  loginUsingPassword,
  validateSecurityCode,
  logout,
  generateSoftwareToken,
  fetchInsyncProductUrl,
  fetchPasswordPolicy,
  loginUsingSaml,
  resendEmailOTP,
  forgotPassword,
  updatePhoneNumber
};
