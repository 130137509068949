import { apiInstance } from "helpers/request.helpers";

const fetchGlobalSession = ({ queryKey }) =>
  apiInstance.get("commonlogin/session", {
    params: {
      input: {
        request: {
          method: "CheckGlobalSession",
          kwargs: {
            redirect_on_success: false,
            ...queryKey[1]
          }
        }
      }
    }
  });

export { fetchGlobalSession };
