export const ACCESS_TOKEN_KEY = "accessToken";
export const AUTHORIZATION_HEADER_NAME = "DRUVA_TOKEN token";
export const COGNITO_ISSUER = "Druva";

export const DEV_ENV_PREFIXES = ["login", "console", "d1"];

export const INSYNC_PRODUCT_ID = 8193;
export const DRUVAONE_PRODUCT_ID = 4097;

// Password strength checker
export const NUMBER_REGEX = /\d/;
export const LOWER_CASE_REGEX = /[a-z]/;
export const UPPER_CASE_REGEX = /[A-Z]/;
export const SPECIAL_CHAR_REGEX = /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;

export const PRODUCT_NOT_LICENSED_PAGE_MAPPING = {
  8193: "https://go.druva.com/druvaone-devices.html",
  12289: "https://go.druva.com/druvaone-physical_servers.html",
  16385: "https://go.druva.com/druvaone-Apollo.html"
};

export const PRODUCT_ID_NAME_MAPPING = {
  8193: "inSync",
  12289: "phoenix"
};

export const DRUVA_LOGIN_UI_DOMAIN = "login.druva.com";
export const DRUVA_HOME_PAGE_LINK = "https://www.druva.com";
export const DID_NOT_RECEIVED_EMAIL_URL = "en-US_DCP_Troubleshooting";
export const DELL_CLOUD_PURCHASE_TERMS_URL =
  "http://www.dell.com/dellemccloudterms";

export const EXPIRE_OTP_AFTER_SECONDS = 180;
export const RESEND_OTP_AFTER_SECONDS = 30;
export const ACCOUNT_LOCK_IN_MINS = 30;

export const CSRF_METHODS = [
  "ChangePasswordUsingToken",
  "ChangePassword",
  "AcceptLogin",
  "PostLoginSplash",
  "GenerateSoftwareToken",
  "ValidateSecurityCode",
  "ResendSecurityCode",
  "Logout",
  "GetPolicy",
  "phone"
];

export const IS_DEV =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const IS_FEDRAMP = process.env.REACT_APP_IS_FEDRAMP === "true";
export const IS_FIPS = process.env.REACT_APP_IS_FIPS === "true";

export const LOAD_GAINSIGHT = !(
  IS_FEDRAMP ||
  process.env.REACT_APP_LOAD_ANALYTICS === "false" ||
  IS_FIPS
);

export const GAINSIGHT_SCRIPT_DEV_URL =
  "https://dcpcdn-ddev.drtst.org/inproductexp/login-analytics-script.min.js";

export const GAINSIGHT_SCRIPT_PROD_URL =
  "https://dcpcdn.druva.com/inproductexp/login-analytics-script.min.js";

export const GAINSIGHT_SCRIPT_URL = IS_DEV
  ? GAINSIGHT_SCRIPT_DEV_URL
  : GAINSIGHT_SCRIPT_PROD_URL;

export const invalidPhoneNumberPattern =
  /^(([1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
