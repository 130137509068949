import { useSearchParams } from "react-router-dom";
import Label from "orion/lib/label";
import Form from "orion/lib/form";
import FormGroup from "orion/lib/formGroup";
import Tooltip from "orion/lib/tooltip";
import { useSelector } from "react-redux";

import { useSetPasswordMutation } from "queries/admin.queries";
import { isStrongPassword, isElementInvalid } from "helpers/utils";
import PasswordField from "shared/PasswordField";
import LoginButton from "shared/LoginButton";

const FormFields = ({ data }) => {
  const [searchParams] = useSearchParams();
  const { mutate: setPassword, isLoading } = useSetPasswordMutation();
  const currentAccount = useSelector((state) => state.app.currentAccount);
  const { forceStrongPassword } = data;
  const passwordToken = searchParams.get("password_token");

  return (
    <Form
      dataTestId="set-password-form"
      withValidation
      initialValues={{
        newPassword: "",
        confirmPassword: ""
      }}
      validationSchema={(Yup) =>
        Yup.object().shape({
          newPassword: Yup.string()
            .required("This field is required")
            .test(
              "password-strength-check",
              "New password does not meet password policy criteria.",
              (password) =>
                forceStrongPassword ? isStrongPassword(password) : true
            ),
          confirmPassword: Yup.string()
            .required("This field is required")
            .oneOf([Yup.ref("newPassword"), null], "Passwords do not match.")
        })
      }
      onSubmit={({ newPassword }) => {
        setPassword([passwordToken, newPassword, "", false]);
      }}
      renderChildren={(validationProps) => {
        return (
          <>
            <FormGroup className="oui-w-100">
              <Tooltip
                text={currentAccount.adminEmail}
                placement="auto"
                overflowOnly
              >
                <Label
                  text={currentAccount.adminEmail}
                  className="oui-w-100 oui-mt-1 oui-text-truncate oui-mb-3"
                />
              </Tooltip>
            </FormGroup>

            <FormGroup className="oui-w-100 form-group-height">
              <PasswordField
                dataTestId="new-password-input"
                focusOnMount
                name="newPassword"
                placeholder="New Password"
                validationProps={validationProps}
                showPasswordStrength
                forceStrongPassword={forceStrongPassword}
              />
            </FormGroup>
            <FormGroup className="oui-w-100 form-group-height">
              <PasswordField
                dataTestId="confirm-password-input"
                name="confirmPassword"
                placeholder="Confirm Password"
                validationProps={validationProps}
                forceStrongPassword={forceStrongPassword}
              />
            </FormGroup>
            <FormGroup className="oui-w-100 oui-m-0">
              <LoginButton
                dataTestId="submit-btn"
                label="Set Password"
                className="oui-mb-4"
                isLoading={isLoading}
                disabled={
                  isLoading ||
                  isElementInvalid(validationProps, "newPassword") ||
                  isElementInvalid(validationProps, "confirmPassword")
                }
              />
            </FormGroup>
          </>
        );
      }}
    />
  );
};

export default FormFields;
