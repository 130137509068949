import { useQuery } from "react-query";
import { useToast } from "slices/toast.slice";
import { fetchStashSamlResponse } from "apis/saml.apis.js";
import errorMap from "helpers/errorMap";

const useFetchStashSamlResponseQuery = (samlResponseToken) => {
  const { notifyError } = useToast();

  return useQuery({
    queryKey: ["fetch-stash-saml-response", samlResponseToken],
    queryFn: fetchStashSamlResponse,
    refetchOnMount: true,
    retry: false,
    onError: (error) => {
      if (error?.code && errorMap[error.code]) {
        notifyError(errorMap[error.code]);
      }
    }
  });
};

export { useFetchStashSamlResponseQuery };
