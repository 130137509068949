const LicenseAgreementDruva = () => (
  <div className="license-agreement-body">
    <div style={{ color: "#585858", fontSize: 11 }}>
      <p
        className="Normal oui-text-center"
        style={{
          marginTop: "5.0pt",
          marginBottom: "15.0pt",
          lineHeight: "10.5pt"
        }}
      >
        <b>
          <span
            style={{ fontSize: "18.0pt", fontWeight: "bold" }}
            className="oui-text-center"
          >
            Cloud Services Customer Agreement
          </span>
        </b>
      </p>
      <p
        className="Normal"
        style={{
          marginTop: "5.0pt",
          marginBottom: "5.0pt",
          lineHeight: "10.5pt"
        }}
      >
        THIS CUSTOMER AGREEMENT (“<b>AGREEMENT</b>”) IS EXECUTED BETWEEN DRUVA
        (AS DEFINED IN SECTION 14(b)) AND THE COMPANY, ORGANIZATION, OR OTHER
        LEGAL ENTITY THAT YOU REPRESENT (“
        <b>CUSTOMER </b>,”“ <b>YOU,</b>” OR “<b>YOUR</b>”) AND GOVERNS YOUR USE
        OF THE CLOUD SERVICES (AS DEFINED BELOW). THIS AGREEMENT IS EFFECTIVE AS
        OF THE DATE YOU CLICK “I AGREE” OR OTHERWISE ACCESS OR USE THE CLOUD
        SERVICES (“<b>EFFECTIVE DATE</b>”).
        <br />
        <br />
        BY ACCESSING OR USING THE CLOUD SERVICES, YOU: (A) ACKNOWLEDGE THAT YOU
        HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT AND WARRANT THAT
        YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT
        (AND, THAT IF YOU ARE ENTERING INTO AN AGREEMENT ON BEHALF OF A LEGAL
        ENTITY, THAT YOU HAVE THE RIGHT, POWER AND AUTHORITY TO BIND SUCH ENTITY
        TO THIS AGREEMENT); AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE
        LEGALLY BOUND BY ITS TERMS TO THE EXCLUSION OF ALL OTHER TERMS.
        <br />
        <br />
        YOUR USE OF OR PARTICIPATION IN CERTAIN CLOUD SERVICES MAY ALSO BE
        SUBJECT TO ADDITIONAL POLICIES, RULES, AND/OR CONDITIONS (“
        <b>ADDITIONAL TERMS</b>”), WHICH ARE INCORPORATED HEREIN BY REFERENCE.
        YOU UNDERSTAND AND AGREE THAT BY USING OR PARTICIPATING IN ANY SUCH
        SERVICES, YOU AGREE TO ALSO COMPLY WITH THESE ADDITIONAL TERMS.
        <br /> <br />
        IF YOU DO NOT ACCEPT THESE TERMS, YOU MAY NOT ACCESS OR USE THE CLOUD
        SERVICES IN ANY MANNER. IF THE TERMS OF THIS AGREEMENT ARE CONSIDERED AN
        OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH TERMS.
        <br />
        <br />
      </p>
      <ol type="1">
        <li>
          <b>Definitions</b>
          <ol type="a">
            <li>
              <b>“Affiliate”</b> means any entity that directly or indirectly
              controls, is controlled by, or is under common control with the
              subject entity. <b>“Control”</b> for purposes of this definition
              means direct or indirect ownership or control of more than 50% of
              the voting interests of the subject entity
            </li>
            <li>
              <b>“Authorized Users”</b> means a natural person or entity who is
              authorized by Customer to use the Cloud Services and who accesses
              the Cloud Services.
            </li>
            <li>
              <b>“Cloud Services”</b> means Druva’s software-as-a-service
              solution for managing data availability and information
              governance, any feature or functionality add-ons, and any modified
              versions of, and upgrades, updates and additions to such solution
              purchased by Customer from Druva or a Reseller. Cloud Services
              exclude professional services, support services, Free Trials, and
              training services.
            </li>
            <li>
              <b>“Cloud Storage Area”</b> means the geographic storage area
              provided by Druva where Customer Data may be stored per Customer’s
              instructions.
            </li>
            <li>
              <b>“Confidential Information”</b> means all confidential or
              proprietary information disclosed by a party (“Disclosing Party”)
              to the other party (“Receiving Party”), whether orally or in
              writing, that is designated as confidential or that reasonably
              should be understood to be confidential given the nature of the
              information and the circumstances of disclosure. Customer’s
              Confidential Information excludes Customer Data. Confidential
              Information will not include information that: (i) is or becomes
              generally known to the public without breach of any obligation
              owed to the Disclosing Party, (ii) was known to the Receiving
              Party prior to its disclosure by the Disclosing Party without
              breach of any obligation owed to the Disclosing Party, (iii) is
              received from a third party without breach of any obligation owed
              to the Disclosing Party, or (iv) was independently developed by
              the Receiving Party without use of or reference to the Disclosing
              Party’s Confidential Information.
            </li>
            <li>
              <b>“Customer Data”</b> means data, information, and materials of
              Customer or its Authorized Users that Customer or its Authorized
              Users uploads to, stores on, or accesses with Druva’s Cloud
              Services.
            </li>
            <li>
              <b>“Customer Site”</b> means the geographic location at which
              Customer Data may be collected.
            </li>
            <li>
              <b>“Documentation”</b> means the published user guides, manuals,
              instructions and/or specifications provided or made available to
              Customer with respect to the Cloud Services on{" "}
              <a href="https://help.druva.com" target="_blank" rel="noreferrer">
                https://help.druva.com
              </a>
              , as amended from time to time. Customer may subscribe to alerts
              to receive changes to the Documentation in the customer
              documentation portal.
            </li>
            <li>
              <b>“Druva Order Form”</b> means a Druva order form signed by
              Customer reflecting Customer’s purchase of the Cloud Services.
            </li>
            <li>
              <b>“Free Trial”</b> means any Druva service or functionality that
              Druva makes available to Customer on a trial basis, at no
              additional charge, and which is clearly designated as “early
              availability,” “early access,” “beta,” “pilot,” “trial,” “free
              trial,” “evaluation,” “proof of concept (POC),” “promotion,” or by
              a similar designation.
            </li>
            <li>
              <b>“Indemnified Liabilities”</b> means any (i) settlement amounts
              approved by the indemnifying party; (ii) damages and costs in a
              final judgment awarded against the indemnified part(ies) by a
              competent court; and (iii) all attorneys’ fees, and court or
              tribunal costs incurred by either party with respect to defense
              and settlement of such third-party claim.
            </li>
            <li>
              <b>“Reseller”</b> means a reseller of Druva’s Cloud Services.
            </li>
            <li>
              <b>“Reseller Order Form”</b> means a (i) Druva order form signed
              by Reseller or (ii) purchase order of Reseller (provided that the
              Reseller has a signed reseller agreement in place with Druva) that
              references the corresponding order form number (and which purchase
              order shall exclude any pre-printed or linked terms and conditions
              set forth in such written document that are in addition to,
              inconsistent or in conflict with, or different than, this
              Agreement), reflecting Customer’s purchase of the Cloud Services.
            </li>
            <li>
              <b>“Term”</b> means the term of this Agreement as set forth in
              Section 12(a).
            </li>
            <li>
              <b>“Third-Party Legal Proceeding”</b> means any formal legal
              proceeding filed by an unaffiliated third party before a court.
            </li>
          </ol>
        </li>
        <li>
          <b>Cloud Services License; Affiliates.</b>
          <ol type="a">
            <li>
              <b>License</b>. Subject to Customer’s compliance with this
              Agreement, Druva hereby grants Customer a non-transferable,
              non-exclusive, revocable, limited, and restricted license to
              access and use the Cloud Services for Customer’s own internal
              business purposes only in a manner pursuant to this Agreement and
              the applicable Documentation for the Term unless earlier
              terminated. Druva shall use commercially reasonable efforts to
              make the Cloud Services available to Customer in accordance with
              the service levels attached hereto as Exhibit A during the Term
              unless earlier terminated. Customer may install and use the Cloud
              Services on any of Customer’s compatible endpoint devices up to
              the maximum number of permitted Authorized Users and storage limit
              per Authorized User set forth in the Reseller Order Form or Druva
              Order Form, as applicable. Customer may make copies of the
              Documentation for its own internal use in connection with its use
              of the Cloud Services in accordance with this Agreement, but no
              more than the amount reasonably necessary.
            </li>
            <li>
              <b>Customer Affiliates</b>. Customer may extend its rights,
              benefits, and protections to its Affiliates and to contractors
              acting on its or its Affiliates’ behalf, so long as Customer
              remains responsible for their compliance hereunder, in which case
              all references to Customer in this Agreement shall include
              Customer’s Affiliates.
            </li>
            <li>
              <b>Government Customers</b>. If Customer is the United States
              government, or anyone that is licensing the Cloud Services
              pursuant to a United States government contract or with United
              States government funds, Druva will license the Cloud Services and
              the Documentation solely in accordance with the following:  the
              Cloud Services and the Documentation are "commercial items" as
              that term is defined in 48 C.F.R. 2.101, consisting of "commercial
              computer software" and "commercial computer software
              documentation" as such terms are used in 48 C.F.R. 12.212.
              Consistent with 48 C.F.R. 12.211, 12.212, 227.7102-1 through
              227.7102-3 and 227.7202-1 through 227.7202-4, as applicable, or
              its successor provisions, all government end users acquire only
              those rights in the Cloud Services and the Documentation that are
              expressly granted in this Agreement. In the case of any other
              government, Druva's rights are protected to the maximum extent
              possible as set forth in this Agreement, but in any event to at
              least the same extent they would be protected under the
              immediately preceding sentences.
            </li>
          </ol>
        </li>
        <li>
          <b>Privacy and Confidentiality.</b>
          <ol type="a">
            <li>
              <b>Privacy.</b> Druva agrees to comply with its data processor
              obligations under privacy laws applicable to the provision of the
              Cloud Services and this Agreement. Customer authorizes Druva to
              process Customer Data to provide the Cloud Services to Customer
              and Customer’s Affiliates. For additional information on Druva’s
              privacy practices and the personal information Druva may collect
              in its role as the data controller, please visit our{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://www.druva.com/privacy-policy/"
              >
                <span style={{ fontSize: "8.5pt" }}>Privacy Policy</span>
              </a>
              . Customer is responsible for maintaining its own compliance with
              applicable privacy laws.
            </li>
            <li>
              <b>Confidentiality.</b> The Receiving Party agrees to use the same
              degree of care that it uses to protect the confidentiality of its
              own confidential information of like kind, but not less than
              reasonable care. Each party shall not (i) disclose the other
              party’s Confidential Information to any third parties other than
              as expressly provided in this Agreement, (ii) use the other
              party’s Confidential Information for purposes outside the scope of
              this Agreement, or (iii) disclose the other party’s Confidential
              Information, unless to directors, employees, or consultants who
              have a need to know such information and are subject to
              confidentiality obligations that are at least as restrictive as
              those contained in this Agreement.
            </li>
            <li>
              <b>Data Storage.</b> The Cloud Services will process and store
              Customer Data in the Cloud Storage Area selected by Customer,
              except as necessary to comply with law or a valid binding order of
              a law enforcement agency. In the event that Druva has the
              capability and desires to change the location of the Cloud Storage
              Area for a Customer Site, Druva agrees to promptly notify Customer
              in writing and provide all relevant details of the desired change
              to the location of the Cloud Storage Area, and not change the
              location of the Cloud Storage Area without Customer’s prior
              written approval, which Customer may withhold in its sole
              discretion.
            </li>
            <li>
              <b>Usage and Configuration Metrics.</b> In connection with Druva’s
              performance of this Agreement, Druva and its Affiliates may
              collect information regarding number of users, number of devices,
              number of servers, per user storage capacity, aggregate storage
              usage and storage locations of the Customer and may use such
              information only for internal business purposes, including to
              perform their obligations under this Agreement and to ensure
              compliance with this Agreement. Any information collected pursuant
              to this Section shall not include any Customer Data, or any
              “personally identifiable information” or “protected health
              information” therein, as such terms are defined in applicable
              privacy laws. Druva and its Affiliates agree to keep all collected
              information confidential.
            </li>
            <li>
              <b>Data Security</b>. During the Term, Druva will maintain
              commercially reasonable technical and organizational measures,
              including disaster recovery and business continuity procedures, to
              protect the security and integrity of Customer Data against
              accidental or unlawful destruction, loss, alteration, unauthorized
              disclosure of, or access to, Customer Data.
            </li>
          </ol>
        </li>
        <li>
          <b>Customer Ownership, Responsibility.</b>
          <ol type="a">
            <li>
              <b>Ownership.</b> Customer retains title to and ownership of all
              right, title, and interest in the Customer Data.
            </li>
            <li>
              <b>Customer Responsibility.</b> Customer is solely responsible for
              (i) maintaining the confidentiality of its Authorized Users’
              credentials, passwords, and encryption keys associated with its
              accounts, (ii) properly configuring the settings of the Cloud
              Services and taking its own steps to maintain appropriate security
              and protection of passwords and encryption keys and settings for
              any backup of Customer Data, (iii) all activities that occur with
              respect to Customer’s accounts, other than activities of an
              unauthorized third party, (iv) its and its Authorized Users’
              access and use of the Cloud Services and compliance with this
              Agreement and the applicable Documentation, (v) all content of
              Customer Data, and (vi) all product settings, which may override
              individual end point settings of Authorized Users, if applicable.
              Druva is not responsible for any alteration, compromise,
              corruption, or loss of Customer Data that arises from any access
              to, sharing, or use of Customer’s accounts, credentials,
              passwords, or encryption keys by Customer.
            </li>
          </ol>
        </li>
        <li>
          <b>Druva Ownership.</b>
          <ul type="none">
            <li>
              The Cloud Services, the Documentation, and any authorized copies
              thereof made by Customer are the intellectual property of and are
              owned by Druva, its Affiliates and their licensors, and constitute
              the Confidential Information of Druva. Druva and its Affiliates
              retain title to and ownership of all right, title and interest in
              the Cloud Services and the Documentation, including all
              intellectual property and other proprietary rights therein,
              subject to the applicable limited licenses expressly granted by
              Druva to Customer in Section 2 (Cloud Services License). Customer
              does not have any right, title, or interest in the Cloud Services
              or the Documentation. To the extent that the Cloud Services
              contain or may be provided with components that are offered under
              an open source license, Druva agrees to make that license
              available to Customer and the provisions of that license may
              expressly override some of the terms set forth in this Agreement
              for such components. All rights not expressly granted in this
              Agreement are reserved by Druva and its Affiliates and their
              licensors.
            </li>
          </ul>
        </li>
        <li>
          <b>Restrictions and Requirements.</b>
          <ol type="a">
            <li>
              <b>Proprietary Notices.</b> Customer and its Authorized Users will
              not remove or modify any trademarks, trade names, service marks,
              service names, logos or brands, or copyright or other proprietary
              notices on the Cloud Services or the Documentation, or add any
              other markings or notices to the Cloud Services or the
              Documentation.
            </li>
            <li>
              <b>Use Obligations.</b> Customer and its Authorized Users (i) will
              access and use the Cloud Services in accordance with this
              Agreement and the applicable Documentation, (ii) will not use or
              permit the Cloud Services to perform any file storage or other
              services for any third party, (iii) will not knowingly use or
              permit the Cloud Services to upload or backup any Customer Data
              (A) that infringes the intellectual property rights or other
              proprietary rights of any third party, (B) that is unlawful
              material, or (C) that contains any known software viruses or other
              harmful or deleterious computer code, files, or programs, such as
              trojan horses, worms, time bombs, or cancelbots, (iv) will not
              knowingly use or permit the use of any software, hardware,
              application, or process that (A) interferes with the Cloud
              Services, (B) interferes with or disrupts servers, systems, or
              networks connected to the Cloud Services, (C) accesses or attempts
              to access another customer’s accounts, servers, systems, or
              networks without authorization, or (D) harasses or interferes with
              another customer’s use and enjoyment of the Cloud Services, and
              (v) will not tamper with or breach the security of the Cloud
              Services.
            </li>
            <li>
              <b>Prohibited Activities.</b> Customer and its Authorized Users
              will not (i) modify, port, adapt, translate or create any
              derivative work based upon the Cloud Services or the
              Documentation, (ii) reverse engineer, decompile, disassemble, or
              otherwise derive or attempt to derive the source code of the Cloud
              Services, except for any non-waivable right to decompile the Cloud
              Services expressly permitted by applicable mandatory law, (iii)
              copy, distribute, sell, assign, pledge, sublicense, lease, loan,
              rent, timeshare, use or offer the Cloud Services on a service
              bureau basis, deliver or otherwise transfer the Cloud Services, in
              whole or in part, or (iv) access Cloud Services to create
              competitive products to Druva or engage in the competitive
              analysis of the Cloud Services.
            </li>
          </ol>
        </li>
        <li>
          <b>Limited Warranty.</b>
          <ol type="a">
            <li>
              <b>Authority.</b> Each party represents and warrants that (i) this
              Agreement has been duly entered into and constitutes a valid and
              binding agreement enforceable against such party in accordance
              with its terms; (ii) no authorization or approval from any third
              party is required in connection with such party’s entering into or
              performance of this Agreement; and (iii) the entering into and
              performance of this Agreement does not violate the terms or
              conditions of any other agreement to which it is a party or by
              which it is otherwise bound.
            </li>
            <li>
              <b>Limited Warranty.</b> During an applicable subscription term,
              Druva warrants that the Cloud Services will perform substantially
              in accordance with the applicable published specifications when
              used in accordance with this Agreement and the Documentation.
              Non-substantial variations of performance from the published
              specifications or other Documentation do not establish a warranty
              right. This limited warranty is void if failure of the Cloud
              Services has resulted from (i) installation, deployment, use,
              maintenance or support not in accordance with this Agreement or
              the Documentation, (ii) modification by Customer, an Authorized
              User, or a third party not authorized by Druva, (iii) force
              majeure, or (iv) any material breach of this Agreement by Customer
              or an Authorized User (other than non-payment). In the event of a
              Cloud Services warranty claim under this Section 7(b), Customer’s
              sole and exclusive remedy and Druva’s entire obligation and
              liability shall be, at Druva’s sole option, to either (x) provide
              a correction, update or upgrade of the Cloud Services, (y) correct
              or replace the Cloud Services, or (z) terminate Customer’s
              subscription for the impacted Cloud Services (by written notice)
              and refund Customer, directly or through the Reseller with which
              Customer contracted, the unused fees pre-paid by Customer for the
              impacted Cloud Services covering the whole months remaining after
              the effective date of termination. Any corrected, upgraded or
              updated version of the Cloud Services will be warranted for the
              remainder of the applicable subscription term. All warranty claims
              must be made to Druva in writing within such subscription term.
            </li>
            <li>
              <b>General Disclaimer.</b> EXCEPT AS EXPRESSLY SET FORTH IN
              SECTIONS 7(a) (AUTHORITY) AND 7(b) (LIMITED WARRANTY), THE CLOUD
              SERVICES ARE PROVIDED “AS IS” AND (i) DRUVA SPECIFICALLY DISCLAIMS
              ANY AND ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR
              OTHERWISE, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
              NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE; AND (ii) DRUVA DOES NOT WARRANT THAT THE CLOUD SERVICES
              OR ANY PART THEREOF, OR USE THEREOF WILL BE UNINTERRUPTED,
              ERROR-FREE, UNBREACHABLE OR VIRUS FREE, OR WILL MEET CUSTOMER’S
              QUALITY AND PERFORMANCE REQUIREMENTS. CUSTOMER ASSUMES THE ENTIRE
              RISK OF AND SHALL NOT HOLD DRUVA RESPONSIBLE FOR ANY ALTERATION,
              COMPROMISE, CORRUPTION OR LOSS OF CUSTOMER DATA ATTRIBUTABLE TO
              CUSTOMER. THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS
              SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. SOME STATES
              DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO
              THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. DRUVA DOES NOT
              DISCLAIM ANY WARRANTY OR OTHER RIGHT THAT DRUVA IS PROHIBITED FROM
              DISCLAIMING UNDER APPLICABLE LAW.
            </li>
          </ol>
          <b>Indemnification.</b>
          <ol type="a">
            <li>
              <b>Customer’s Indemnification Obligations.</b> Customer, if
              notified promptly in writing and given authority, control,
              information and assistance at Customer’s expense for defense and
              settlement of same, shall defend and indemnify Druva, Druva’s
              Affiliates, and its/their employees, officers, directors, agents,
              successors, and assigns against any Indemnified Liabilities, in
              any Third Party Legal Proceeding so far as it relates to the
              content of Customer Data, including intellectual property
              infringement right claims. If Druva has given authority, control,
              information and assistance of a matter in accordance with this
              Section 8(a) to Customer, Customer shall not settle such matter
              without the prior written approval of Druva which approval shall
              not be unreasonably withheld.
            </li>
            <li>
              <b>Druva’s Indemnification Obligations.</b> Subject to Section
              8(c) (Indemnification Exceptions), Druva, if notified promptly in
              writing and given authority, control, information and assistance
              at Druva’s expense for defense and settlement of same, shall
              defend and indemnify Customer against Indemnified Liabilities, in
              any Third Party Legal Proceeding so far as it is based on a claim
              that the use of the Cloud Services furnished under this Agreement
              infringes third-party intellectual property rights. If Druva
              reasonably believes that Customer’s use of the Cloud Services is
              likely to be enjoined, or if the Cloud Services are held to
              infringe such intellectual property rights and all use of such
              Cloud Services by Customer is thereby enjoined, Druva shall, at
              its expense and at its sole option: (i) procure for Customer the
              right to continue using the Cloud Services, (ii) replace the Cloud
              Services with other non-infringing software or services of
              substantially equivalent functionality, or (iii) modify the Cloud
              Services so that there is no infringement, provided that such
              modified software or services provide substantially equivalent
              functionality. If, in Druva’s opinion, the remedies in clauses
              (i), (ii) and (iii) above are infeasible or commercially
              impracticable, Druva may, in its sole discretion, terminate
              Customer’s subscription for the impacted Cloud Services (by
              written notice) and refund Customer, directly or through the
              Reseller with which Customer contracted, the unused fees pre-paid
              by Customer for the impacted Cloud Services covering the whole
              months remaining after the effective date of termination. If
              Customer has given authority, control, information and assistance
              of a matter in accordance with this Section 8(b) to Druva, Druva
              shall not settle such matter without the prior written approval of
              Customer which approval shall not be unreasonably withheld.
            </li>
            <li>
              <b>Indemnification Exceptions.</b> The indemnification obligation
              in Section 8(b) (Druva’s Indemnification Obligations) will not
              apply to the extent the infringement is caused by any of the
              following: (i) the modification of the Cloud Services in an
              unauthorized manner by Customer, (ii) the combination of the Cloud
              Services with other unauthorized software, hardware, application
              or process by Customer, (iii) use of the Cloud Services in
              violation of this Agreement or the Documentation by Customer, (iv)
              any third party deliverables or components contained within the
              Cloud Services that are not provided by Druva, or (v) any
              materials, data or information provided by Customer, including
              Customer Data.
            </li>
            <li>
              <b>Sole Remedy.</b> THIS SECTION 8 SETS FORTH CUSTOMER’S SOLE AND
              EXCLUSIVE REMEDY AND DRUVA’S ENTIRE OBLIGATION AND LIABILITY WITH
              RESPECT TO ANY CLAIM OF INTELLECTUAL PROPERTY INFRINGEMENT.
            </li>
          </ol>
        </li>
        <li>
          <b>Limitation of Liability.</b>{" "}
          <ul type="none">
            <li>
              EXCEPT FOR EITHER PARTY’S INDEMNIFICATION OBLIGATIONS UNDER
              SECTION 8 (INDEMNIFICATION), OBLIGATIONS RELATING TO CONFIDENTIAL
              INFORMATION UNDER SECTION 3(B), GROSS NEGLIGENCE OR WILLFUL
              MISCONDUCT, AND CUSTOMER’S MISAPPROPRIATION OF INTELLECTUAL
              PROPERTY RIGHTS, IN NO EVENT SHALL EITHER PARTY OR ITS AFFILIATES
              BE LIABLE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY,
              INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY TYPE OR KIND (INCLUDING
              LOSS OF BUSINESS, GOODWILL, REVENUE, USE OR OTHER ECONOMIC
              ADVANTAGE, BUSINESS INTERRUPTION, OR ANY ALTERATION, COMPROMISE,
              CORRUPTION OR LOSS OF CUSTOMER DATA) ARISING OUT OF, OR IN ANY WAY
              CONNECTED WITH THE CLOUD SERVICES, THE DOCUMENTATION, OR THE USE
              THEREOF, OR THIS AGREEMENT, WHETHER BASED ON CONTRACT, TORT OR ANY
              OTHER LEGAL THEORY, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. EXCEPT FOR THE EXCLUSIONS SET FORTH IN THE PRECEDING
              SENTENCE, EACH PARTY’S AND ALL OF ITS AFFILIATES AGGREGATE
              LIABILITY UNDER THIS AGREEMENT SHALL BE LIMITED TO THE FEES PAID
              AND PAYABLE BY CUSTOMER FOR THE CLOUD SERVICES FOR THE TWELVE
              MONTHS IMMEDIATELY PRIOR TO THE EVENT GIVING RISE TO THE CLAIM.
              THE ABOVE LIMITATIONS SHALL NOT LIMIT CUSTOMER’S PAYMENT
              OBLIGATIONS UNDER THIS AGREEMENT. The limitations of liability and
              exclusions of damages in this Section 9 form an essential basis of
              the bargain between the parties and shall survive and apply even
              if any remedy specified in this Agreement is found to have failed
              its essential purpose.
            </li>
          </ul>
        </li>
        <li>
          <b>Insurance.</b>
          <ul type="none">
            <li>
              During the Term, Druva shall maintain, at its expense, workers’
              compensation insurance as required by applicable law, and
              commercial general liability insurance, errors and omissions
              liability insurance, cyber security insurance, and umbrella
              liability insurance from financially sound insurance companies
              having coverages and limits of liability that are commercially
              reasonable and consistent with industry standards. 
            </li>
          </ul>
        </li>
        <li>
          <b>Suspension.</b>
          <ol type="a">
            <li>
              <b>For Breach.</b> In the event of any breach of this Agreement by
              Customer (including non-payment), without limiting Druva’s other
              rights and remedies and notwithstanding anything in this Agreement
              to the contrary, Druva may temporarily suspend Customer’s use of
              the Cloud Services. Druva will use commercially reasonable efforts
              to provide at least ten (10) days’ notice prior to any suspension
              under this Section, except where Druva reasonably believes
              immediate suspension is necessary. Druva shall use commercially
              reasonable efforts to resume providing access to the Cloud
              Services as soon as reasonably possible after the event giving
              rise to the suspension is cured. If Customer does not fully
              address the reasons for the suspension within thirty (30) days
              after suspension, Druva may terminate Customer's access to the
              Cloud Services.
            </li>
            <li>
              <b>For Credit Depletion (if applicable).</b> For consumption-based
              Cloud Services, Druva may suspend Customer’s use of the applicable
              Cloud Services within fifteen (15) days from Customer’s depletion
              of the pre-purchased credits. Druva shall make reasonable efforts
              to provide a courtesy notice to Customer upon depletion of such
              credits. Notwithstanding the foregoing, it is Customer’s sole
              responsibility to ensure timely purchase of credits for
              uninterrupted service.
            </li>
          </ol>
        </li>
        <li>
          <b>Term and Termination.</b>
          <ol type="a">
            <li>
              <b>Term of Agreement.</b> This Agreement commences on the
              Effective Date and continues until all subscriptions hereunder
              have expired or have been terminated.
            </li>
            <li>
              <b>Term of Subscriptions.</b> The term of each subscription is set
              forth in the Reseller Order Form or Druva Order Form, as
              applicable. Except as otherwise specified in the Reseller Order
              Form or the Druva Order Form, any subscription will automatically
              renew for additional periods equal to the expiring subscription
              term or one year (whichever is shorter, but in no event less than
              one (1) year), unless either party gives the other notice of
              non-renewal at least thirty (30) days before the end of the
              relevant subscription term. Notwithstanding the foregoing, any
              promotional or one-time priced subscription will not be subject to
              auto-renewal.
            </li>
            <li>
              <b>Termination.</b> This Agreement may only be terminated by a
              party upon written notice to the other party (i) if the other
              party breaches a material term of this Agreement that is uncured
              within thirty (30) days after delivery of notice of such breach,
              or (ii) if the other party becomes the subject of a petition in
              bankruptcy or any other proceeding relating to insolvency,
              receivership, liquidation or assignment for the benefit of
              creditors not dismissed within thirty (30) days. For license-based
              Cloud Services in the event the cost of providing Cloud Services
              exceeds the fees paid and payable by Customer, Druva will notify
              Customer and Customer shall work with Druva in good faith to
              remediate the issue. If no resolution is found within 60 days from
              the date of the notice to Customer, then Druva may terminate the
              Agreement. For consumption-based Cloud Services, Druva may
              terminate the Agreement within thirty (30) days from the end of
              the suspension period for Cloud Services pursuant to Section 11(b)
              (Suspension for Credit Depletion). Notwithstanding the above,
              Druva may immediately terminate this Agreement without prior
              written notice or an opportunity to cure in the event of an actual
              or threatened breach of Sections 2 (Cloud Services License), 5
              (Druva Ownership) or 6 (Restrictions and Requirements).
            </li>
            <li>
              <b>Refund or Payment on Expiration or Termination.</b> Upon
              expiration of this Agreement, Customer will pay Druva, directly or
              through the Reseller with which Customer contracted, any unpaid
              and undisputed amounts that are owed to Druva for the Term. Upon
              early termination of this Agreement based on Customer’s breach
              (following any applicable cure period), Customer will pay Druva,
              directly or through the Reseller with which Customer contracted,
              any unpaid and undisputed amounts that would have been owed to
              Druva for the remainder of Customer’s subscriptions if such early
              termination had not occurred as well as any other amounts owed to
              Druva under this Agreement, without limiting Druva’s other rights
              and remedies. Upon early termination of this Agreement based on
              Druva’s breach (following any applicable cure period), Druva will
              refund Customer, directly or through the Reseller with which
              Customer contracted, any unused amounts pre-paid under this
              Agreement for the Cloud Services covering the whole months
              remaining after the effective date of termination. In the event of
              an invoice dispute, Customer shall notify Druva in writing no
              later than ten (10) days after the date of the disputed invoice,
              and any invoices not so disputed shall be deemed accepted. For
              clarity, an invoice dispute does not authorize Customer to
              withhold any amounts not in dispute.
            </li>
            <li>
              <b>Data Retrieval.</b> Upon expiration or termination of this
              Agreement, the rights granted by Druva to Customer under this
              Agreement will lapse and Customer will immediately cease all use
              of the Cloud Services and delete (or, at Druva’s request, return)
              related Documentation, passwords, and any Druva Confidential
              Information in its possession or control. Upon expiration or
              termination of this Agreement (other than termination by Druva for
              Customer’s breach), Customer may (i) access the Cloud Services for
              thirty (30) days solely to retrieve a back-up of the Customer Data
              at no cost, and (ii) access the Cloud Services for an additional
              thirty (30) day period to retrieve a back-up of the Customer Data
              at a cost equal to the current annual list price, pro-rated to a
              monthly rate.  If the Agreement is terminated by Druva for
              Customer’s breach, on or prior to the 30th day after the
              expiration or termination of this Agreement, Customer may request
              that Druva provide a copy of the Customer Data to Customer at
              Druva’s standard export fee. Druva will have no obligation to
              maintain or provide access to the Customer Data after the above
              periods have expired and will delete such data unless legally
              prohibited.
            </li>
            <li>
              <b>Survival.</b> Sections 1 (Definitions), 2(b) (Affiliates), 3(b)
              (Confidentiality), 4 (Customer Information), 5 (Ownership), 6
              (Restrictions and Requirements), 7 (Limited Warranty), 8
              (Indemnification), 9 (Limitation of Liability), 11 (Suspension),
              12 (Term and Termination except 12(c)), 13 (Provisions Applicable
              to Direct Customers) and 14 (General) will survive the expiration
              or termination of this Agreement.
            </li>
          </ol>
        </li>
        <li>
          <b>Payment Terms.</b>
          <ol type="a">
            <li>
              <b>Indirect Purchases.</b> Where Customer purchases the Cloud
              Services through a Reseller, Customer’s payment terms are with the
              Reseller and not with Druva; however, a breach of Customer’s
              payment obligations to a Reseller will be deemed to be a breach of
              this Section 13(a).
            </li>
            <li>
              <b>Direct Purchases.</b> Where Customer purchases the Cloud
              Services directly from Druva, payment terms are set forth in
              Exhibit B, which are hereby incorporated into this Agreement by
              reference.
            </li>
          </ol>
        </li>
        <li>
          <b>General.</b>
          <ol type="a">
            <li>
              <b>Parties.</b> Druva and Customer are independent contractors.
              Nothing in this Agreement shall be deemed to constitute a joint
              venture or partnership between the parties, nor constitute any
              party as the agent of the other party for any purpose or entitle
              any party to commit or bind the other party in any manner. Nothing
              in this Agreement, express or implied, (nor if this Agreement is
              governed by Singapore law, under the Contracts (Rights of Third
              Parties) Act 2001, Chapter 53B of Singapore; nor if this Agreement
              is governed by the law of England and Wales, under the Contracts
              (Rights of Third Parties) Act 1999) is intended to confer upon any
              party other than the parties hereto, Druva’s Affiliates and their
              licensors and their respective successors and permitted assigns,
              any rights or obligations.
            </li>
            <li>
              <b>Governing Law, Jurisdiction and Attorneys’ Fees.</b> The Druva
              contracting and invoicing entity and the applicable law will
              depend on where Customer is domiciled as set forth in the table
              below.
              <div>
                <table className="oui-w-100 oui-my-3">
                  <tbody>
                    <tr>
                      <th>If Customer is domiciled in:</th>
                      <th>Customer is contracting with:</th>
                      <th>The governing law is:</th>
                      <th>The courts having exclusive jurisdiction are:</th>
                    </tr>
                    <tr>
                      <td>A country in North America or South America</td>
                      <td>Druva Inc.</td>
                      <td>
                        California and controlling United States federal law
                      </td>
                      <td>Santa Clara, California, U.S.A</td>
                    </tr>
                    <tr>
                      <td>A country in Asia Pacific (except Japan)</td>
                      <td>Druva Singapore Pte. Ltd.</td>
                      <td>Singapore law</td>
                      <td>Singapore</td>
                    </tr>
                    <tr>
                      <td>Japan</td>
                      <td>Druva Singapore Pte. Ltd.</td>
                      <td>Japan law</td>
                      <td>Tokyo, Japan</td>
                    </tr>
                    <tr>
                      <td>
                        A country in India subcontinent (which includes India,
                        Pakistan, Sri Lanka, Bangladesh, Nepal and Bhutan)
                      </td>
                      <td>Druva Data Solutions Private Limited</td>
                      <td>India law</td>
                      <td>Mumbai, India</td>
                    </tr>
                    <tr>
                      <td>
                        A country in Europe, Middle East, or Africa (except
                        Germany)
                      </td>
                      <td>Druva Europe Limited</td>
                      <td>Wales and England law</td>
                      <td>London, England</td>
                    </tr>
                    <tr>
                      <td>Germany</td>
                      <td>Druva GmbH</td>
                      <td>German law</td>
                      <td>Frankfurt, Germany</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <b>Dispute Resolution.</b> TO THE EXTENT NOT PROHIBITED BY
              APPLICABLE LAW, THE PARTIES HEREBY WAIVE ANY RIGHTS THEY MAY HAVE
              TO TRIAL BY JURY. This Agreement shall not be governed by the
              conflict of law rules of any jurisdiction, the United Nations
              Convention on Contracts for the International Sale of Goods, or
              the Uniform Computer Information Transactions Act, the application
              of which is expressly excluded. If any action is pursued to
              enforce this Agreement, the prevailing party shall be entitled to
              reasonable attorneys’ fees and costs and any other relief to which
              such party may be entitled.
            </li>
            <li>
              <b>Export Laws.</b> Customer understands that the Cloud Services
              and the export and re-export of data via the Cloud Services may be
              controlled by the laws, regulations and rules of one or more
              countries governing technology use and transfer, including U.S.
              Export Administration Regulations, the International Traffic in
              Arms Regulations, and economic sanctions programs implemented by
              the Office of Foreign Assets Control. Customer and its Authorized
              Users will not use the Cloud Services or transfer any technology
              or data via the Cloud Services in violation of such laws,
              regulations or rules. Customer represents that neither it nor its
              Authorized Users are on any denied or restricted party list.
            </li>
            <li>
              <b>Publicity.</b> Customer authorizes Druva to use Customer’s
              name, logo, and/or trademark in connection with promotional,
              marketing, sales and public relations activities only. If
              requested by Druva, Customer shall cooperate with Druva in
              marketing-related activities, which may include a press release,
              case study, testimonial or customer reference (collectively, the
              “Testimonials”). If Customer provides any written or recorded
              Testimonials to Druva, Customer hereby authorizes Druva to
              reproduce, publish, distribute and/or translate such Testimonials
              for marketing purposes (i) on websites or social media channels
              operated by Druva, (ii) on the Druva video repository on{" "}
              <a target="_blank" rel="noreferrer" href="www.youtube.com">
                www.YouTube.com
              </a>
              , and/or (iii) in commercial presentations or events.
            </li>
            <li>
              <b>Entire Agreement; Amendment; Waiver.</b> This Agreement,
              together with the Exhibit(s), Reseller Order Form and Druva Order
              Form, as applicable, is the parties’ entire agreement with respect
              to its subject matter, and supersedes any prior communications,
              discussions, understandings, or agreements. Any term of this
              Agreement may be amended or waived only with the written consent
              of duly authorized representatives of the parties. Titles and
              headings of sections of this Agreement are for convenience only
              and shall not affect the construction of any provision of this
              Agreement.
            </li>
            <li>
              <b>Severability.</b> If any provision of this Agreement is held to
              be unenforceable, the unenforceable provision shall be replaced by
              an enforceable provision that comes closest to the parties’
              intentions underlying the unenforceable provision, and the
              remaining provisions of this Agreement shall remain in full force
              and effect. The unenforceability of any provision in any
              jurisdiction shall not affect the enforceability of such provision
              in any other jurisdiction.
            </li>
            <li>
              <b>Data Processors and Service Providers.</b> Druva may use, and
              Customer consents to Druva's use of, its Affiliates and third
              parties to provide certain parts of the Cloud Services under this
              Agreement. By way of example only, Druva uses Amazon Web Services
              (AWS) to provide Cloud hosting services. Druva shall be liable for
              its Affiliates and its third-party service providers who perform
              parts of the Cloud Services to the same extent as if such services
              were performed by Druva. Upon request, Druva will provide Customer
              with a list of its current sub-processors.
            </li>
            <li>
              <b> Assignment.</b> Druva may assign or transfer this Agreement,
              in whole or in part, to any Affiliate or in connection with any
              acquisition, consolidation, merger, reorganization, transfer of
              all or substantially all of its assets or other business
              combination, or by operation of law without Customer’s consent
              upon written notice. Druva shall notify Customer of any such
              assignment or transfer as soon as practicable, but such notice
              shall not be a condition to such assignment or transfer. Customer
              may terminate this Agreement by providing written notice of
              termination to Druva no later than thirty (30) days after
              receiving notice of such assignment or transfer, if (i) Druva or
              this Agreement is acquired by Customer’s direct competitor, (ii)
              Druva is acquired by a vendor with whom Customer has an active
              dispute and/or litigation, or (iii) Druva is acquired by a vendor
              that is subject to a pending state or federal regulatory
              investigation. Customer may not assign or transfer any part of
              this Agreement by business combination, operation of law or
              otherwise without Druva’s prior written consent, which consent
              shall not be unreasonably withheld. Subject to the foregoing, this
              Agreement will bind and benefit the parties and their respective
              successors and permitted assigns.
            </li>
            <li>
              <b>Force Majeure.</b> Except for Customer’s payment obligations,
              neither party shall be liable for its failure to perform caused by
              any condition beyond its reasonable control, including acts of God
              or nature, government acts, civil unrest, or acts of war or
              terrorism.
            </li>
            <li>
              <b>Free Trials.</b> If Customer uses a Free Trial, then the
              applicable provisions of this Agreement will govern that Free
              Trial, and Druva will make such Free Trial available to Customer
              on a trial basis, free of charge, until the earlier of (a) the end
              of the free trial period for which Customer agreed to use such
              Free Trial, (b) the start date of any Cloud Service subscription
              purchased by Customer for such Cloud Service, or (c) termination
              of the Free Trial by Druva in its sole discretion. A Free Trial
              period may be extended upon mutual agreement by Druva and
              Customer. Notwithstanding anything to the contrary in this
              Agreement, a Free Trial is provided “AS IS.” DRUVA MAKES NO
              REPRESENTATION OR WARRANTY OF ANY KIND AND SHALL HAVE NO
              INDEMNIFICATION OBLIGATIONS WITH RESPECT TO A FREE TRIAL. DRUVA
              SHALL HAVE NO LIABILITY OF ANY TYPE WITH RESPECT TO A FREE TRIAL,
              UNLESS SUCH EXCLUSION OF LIABILITY IS NOT ENFORCEABLE UNDER
              APPLICABLE LAW IN WHICH CASE DRUVA’S TOTAL AGGREGATE LIABILITY
              ARISING OUT OF OR RELATING TO A FREE TRIAL IS $1,000 USD. CUSTOMER
              SHALL NOT USE THE FREE TRIAL IN A MANNER THAT VIOLATES APPLICABLE
              LAWS OR ANY USE RESTRICTIONS OR REQUIREMENTS IN THE AGREEMENT.
              NOTWITHSTANDING ANYTHING TO THE CONTRARY IN SECTION 9 (“LIMITATION
              OF LIABILITY”), CUSTOMER WILL BE FULLY LIABLE FOR ANY DAMAGES
              CAUSED BY ITS USE OF A FREE TRIAL. ANY DATA AND CONFIGURATIONS
              ENTERED INTO CUSTOMER’S FREE TRIAL ACCOUNT MAY BE PERMANENTLY LOST
              UPON TERMINATION OF THE FREE TRIAL.
            </li>
            <li>
              <b>Notices.</b> All notices given under this Agreement shall be in
              writing and shall be deemed given upon receipt. All notices shall
              be sent to the parties at their respective address on the Reseller
              Order Form or Druva Order Form, as applicable, or to such email
              address or address as subsequently modified by written notice
              given in accordance with this section.
            </li>
            <li>
              <b>Counterparts.</b> This Agreement may be signed in counterparts,
              including via PDF, Docusign or other electronic reproduction, and
              any such counterpart will be valid and effective for all purposes.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <div style={{ color: "#585858", fontSize: 11 }} className="oui-mt-4">
      <p
        className="Normal oui-text-center"
        style={{
          marginTop: "5.0pt",
          marginBottom: "15.0pt",
          lineHeight: "10.5pt"
        }}
      >
        <br />
        <br />
        <br />
        <br />
        <b>
          <span
            style={{ fontSize: "12.0pt", fontWeight: "bold" }}
            className="oui-text-center"
          >
            Exhibit A{" "}
          </span>
          <br />
          <span
            style={{ fontSize: "12.0pt", fontWeight: "bold" }}
            className="oui-text-center"
          >
            Service Level Agreement (SLA) for InSync and Phoenix
          </span>
        </b>
      </p>
      <b>
        <u>Cloud Services Availability</u>
      </b>
      <br />
      The Cloud Services will be available 24 hours per day, 7 days per week,
      excluding any scheduled maintenance as described below.
      <br />
      <b>Category 1 – Scheduled Maintenance. </b>
      <ul type="disc">
        <li>
          <b>InSync:</b> A weekly scheduled maintenance period may be scheduled
          (i) in the case of InSync, every Saturday between{" "}
          <b> 9:00AMUTC to 3:00PMUTC </b>, and (ii) in the case of InSync
          GovCloud, every Friday between <b>5:00PM PT to 11:30PM PT</b>, in each
          case, to perform system maintenance, backup, and upgrade functions for
          the Cloud Services. If scheduled maintenance is required outside of
          the scheduled maintenance period described above, Druva will notify
          Customer at least three (3) business days in advance.
        </li>
        <li>
          <b>Phoenix:</b> A weekly scheduled maintenance period may be scheduled
          on the first and third Monday of each month at <b>5:00AMUTC</b>{" "}
          (Phoenix Cloud) and at <b>8:00AM UTC</b> (Phoenix GovCloud) for a
          maximum duration of 90 minutes to perform system maintenance, backup,
          and upgrade functions for the Cloud Services. If scheduled maintenance
          is required outside of the scheduled maintenance period described
          above, Druva will notify Customer at least three (3) business days in
          advance.
        </li>
      </ul>
      <b>Category 2 – Unscheduled Maintenance.</b>&nbsp; Unscheduled maintenance
      may be required to resolve issues that are critical for Customer and/or
      performance of the Cloud Services. Druva will use its commercially
      reasonable efforts to notify Customer at least six (6) hours prior to the
      unscheduled maintenance.
      <br />
      <br />
      <b>
        <u>Uptime and Service Credits </u>
      </b>
      <br />
      The following details the credits available to Customer in the event Cloud
      Services Availability falls below the indicated thresholds. A “Reporting
      Period” shall mean a calendar month.
      <br></br>
      <div className="oui-w-100 oui-text-center">
        <b>InSync</b>
      </div>
      <div className="oui-w-100 oui-align-items-center oui-my-3">
        <table className="oui-w-50 " align="center">
          <tbody>
            <tr>
              <th>Cloud Services Availability</th>
              <th>Credits</th>
            </tr>
            <tr>
              <td>&lt; 99.5% in one Reporting Period</td>
              <td>5% of one (1) month of subscription fees</td>
            </tr>
            <tr>
              <td>&lt; 99% in one Reporting Period</td>
              <td>10% of one (1) month of subscription fees</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="oui-w-100 oui-text-center">
        <b>Phoenix</b>
      </div>
      <div className="oui-w-100 oui-my-3">
        <table className="oui-w-50 " align="center">
          <tbody>
            <tr>
              <th>Cloud Services Availability</th>
              <th>Credits</th>
            </tr>
            <tr>
              <td>&lt; 99.5% in one Reporting Period</td>
              <td>5% of one (1) Phoenix Month* </td>
            </tr>
            <tr>
              <td>&lt; 99% in one Reporting Period</td>
              <td>10% of one (1) Phoenix Month*</td>
            </tr>
          </tbody>
        </table>
        <div className="oui-text-center">
          <span>
            * Phoenix Month is equivalent to the value of the Phoenix credits
            consumed by Customer for the affected month.
          </span>
        </div>
      </div>
      <span>
        Additionally, if the Cloud Services Availability falls below 95% for
        three (3) consecutive Reporting Periods, Customer shall have the right
        to terminate this Agreement and such right must be exercised within ten
        (10) days of the end of such three (3) month period or Customer shall be
        deemed to have waived its termination right with respect to that
        particular three (3) month period.
      </span>
      <br />
      <br />
      <span>
        <b>
          <u>Calculation of Cloud Services Availability</u>
        </b>
      </span>
      <br />
      <span>
        Cloud Services Availability = (Total Hours in Reporting Period –
        Unscheduled Maintenance which causes unavailability – Scheduled
        Maintenance) / (Total Hours in Reporting Period – Scheduled Maintenance)
        X 100%.
        <br />
        <br />
        The following shall be excluded when calculating Cloud Services
        Availability: (i) unavailability caused by force majeure; (ii) any
        problems resulting from Customer combining or merging the Cloud Services
        with any hardware or software not supplied by Druva or not identified by
        Druva in writing as compatible with the Cloud Services; (iii)
        interruptions or delays in providing the Cloud Services resulting from
        telecommunications or Internet service provider failures; or (iv) any
        interruption or unavailability resulting from Customer’s use of the
        Cloud Services in an unauthorized or unlawful manner or any interruption
        resulting from the misuse, improper use, alteration or damage of the
        Cloud Services.
      </span>
      <br />
      <br />
      <span>
        <b>
          <u>Request for Credit for Cloud Services Availability</u>
        </b>
      </span>
      <br />
      <span>
        Any Customer request for a credit under this SLA may only be made on a
        calendar month basis and must be submitted within ten (10) days after
        the end of the relevant calendar month or shall be deemed to have been
        waived by Customer. For those periods at the end of a subscription term
        that do not coincide with the end of a calendar month, Customer must
        make a claim for a credit within ten (10) days after the expiration of
        the subscription term or the claim for credit shall be deemed to have
        been waived by Customer.
        <br />
        <br />
        The right to a credit and/or the right to terminate this Agreement under
        this SLA and this Agreement shall be the sole and exclusive remedy
        available to Customer in the event of unavailability of the Cloud
        Services and, under no circumstance, shall the unavailability of the
        Cloud Services be deemed a breach by Druva of its obligations under this
        Agreement.
      </span>
    </div>
    <div style={{ color: "#585858", fontSize: 11 }} className="oui-mt-4">
      <p
        className="Normal oui-text-center"
        style={{
          marginTop: "5.0pt",
          marginBottom: "15.0pt",
          lineHeight: "10.5pt"
        }}
      >
        <br />
        <br />
        <br />
        <br />
        <b>
          <span
            style={{ fontSize: "12.0pt", fontWeight: "bold" }}
            className="oui-text-center"
          >
            Exhibit B
          </span>
          <br />
          <span
            style={{ fontSize: "12.0pt", fontWeight: "bold" }}
            className="oui-text-center"
          >
            Payment Terms Applicable to Direct Customers Only
          </span>
        </b>
      </p>
      <ol type="1">
        <li>
          <b>Definitions</b>
          <ol type="a">
            <li>
              <b>“Indirect Tax”</b> means without limitation, value-added tax,
              goods and services tax, sales and use and similar transaction
              taxes, and gross receipts tax, as the case may be.
            </li>
            <li>
              <b>“Taxes”</b> means taxes, levies, duties or similar national,
              federal, state, provincial, or local governmental assessments of
              any nature, including Indirect Tax, that are assessable by any
              jurisdiction under applicable law.
            </li>
          </ol>
        </li>
        <li>
          <b>Payment Terms</b>
          <ol type="a">
            <li>
              <b>Fees.</b> Customer shall pay Druva the subscription fees and
              other amounts for Druva’s products and services ordered by
              Customer, in the currency specified in the Druva Order Form)
              (collectively, the “Fees”). All payment obligations are
              non-cancellable, and all Fees paid to Druva are non-refundable
              except as expressly set forth in this Agreement.
            </li>
            <li>
              <b>Payment Terms.</b> All Fees will be invoiced in advance in
              accordance with the Druva Order Form. Unless otherwise set forth
              in the Druva Order Form, all Fees are due and payable Net 30 days
              after the date of the applicable invoice. All invoices that are
              not paid within Net 30 days, and all credit accounts that are
              delinquent, shall be assessed a 1.5% late payment charge (or, if
              less, the highest legal rate under applicable law) for each month
              the invoice is not paid or the account is delinquent.
            </li>
            <li>
              <b>Taxes.</b> With respect to the transactions and payments
              contemplated in this Agreement, each party shall be solely
              responsible to pay all Taxes and governmental fees and charges
              (and any penalties, interest, and other additions thereto) that
              each party is liable to pay under applicable law or otherwise
              under this Agreement. All Fees payable under this Agreement are
              exclusive of applicable Taxes. If either party has an obligation
              under applicable law or this Agreement to pay or collect Indirect
              Tax for which the other party is legally liable or responsible
              under this section, then the paying or collecting party will
              invoice the other party for such Indirect Tax, which the invoiced
              party will pay. The invoice will satisfy requirements under
              applicable law for a valid tax invoice. Each party will provide
              the other party with such information as is reasonably required to
              determine whether there is an obligation to pay or collect
              Indirect Tax. Neither party shall pay or collect any Indirect Tax
              from or on behalf of the other party for which, under applicable
              law, (i) the other party has previously provided to the paying or
              collecting party a properly completed and valid exemption
              certificate, or (ii) the parties may otherwise claim an available,
              valid exemption from such Indirect Tax.
            </li>
          </ol>
        </li>
        <li>
          <b>Conflict.</b> In the event of any conflict or inconsistency between
          the following documents, the order of precedence shall be: (1) the
          Druva Order Form, and (2) this Agreement. The parties agree that any
          term or condition in Customer’s purchase order is void and of no
          effect.
        </li>
      </ol>
    </div>
  </div>
);

export default LicenseAgreementDruva;
