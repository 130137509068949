import cx from "classnames";
import Spinner from "orion/lib/spinner";
import "./style.scss";

const LoginButton = ({
  label,
  className,
  isLoading,
  disabled,
  onClick = null,
  dataTestId
}) => {
  return (
    <button
      data-testid={dataTestId}
      type="submit"
      onClick={onClick}
      className={cx(
        "login-button",
        "oui-btn",
        "oui-btn-lg",
        "oui-btn-primary",
        "oui-btn-block",
        {
          "oui-disabled": disabled
        },
        className
      )}
    >
      {!isLoading && <span className="btn-label">{label}</span>}
      {isLoading && <Spinner className="btn-loading-spinner" size="sm" />}
    </button>
  );
};

export default LoginButton;
