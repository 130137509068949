import cx from "classnames";
import useProductConfig from "hooks/useProductConfig";

const AuthCardBody = ({ children, title, cardContentClass }) => {
  const { brand, theme, isDruva } = useProductConfig();

  return (
    <>
      <div
        className={cx("card-logo", {
          "druva-logo": isDruva
        })}
        style={{ backgroundColor: theme?.primaryColor }}
      >
        <img
          src={brand?.loginPageIconUrl}
          alt=""
          className={isDruva ? "oui-mt-3" : ""}
        />
      </div>
      <div
        className={cx(
          "oui-d-flex",
          "oui-flex-grow-1",
          "oui-flex-column",
          "oui-justify-content-between",
          "card-content",
          cardContentClass
        )}
      >
        {children}
      </div>
    </>
  );
};

export default AuthCardBody;
