import Page from "orion/lib/page";

import { ReactComponent as BrokenLinkSvg } from "assets/svgs/broken-link.svg";
import AppLayout from "components/AppLayout";

const ActivationLinkExpired = () => {
  return (
    <AppLayout>
      <Page.Main>
        <Page.Body className="oui-d-flex oui-align-items-center oui-justify-content-center">
          <div
            className="oui-d-flex oui-flex-column oui-align-items-center"
            style={{ width: "350px" }}
          >
            <BrokenLinkSvg title="Password Token Expired" />
            <h5>Your account activation link has expired</h5>
            <p className="oui-text-muted">
              We have sent you a new link, which will be active for 48 hours.
            </p>
          </div>
        </Page.Body>
      </Page.Main>
    </AppLayout>
  );
};

export default ActivationLinkExpired;
