import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import Label from "orion/lib/label";
import Button from "orion/lib/button";
import Loader from "orion/lib/loader";
import InputFeedback from "orion/lib/inputFeedback";
import Form from "orion/lib/form";

import { useLoginMachine } from "./LoginMachineProvider";
import AuthCard from "shared/AuthCard";
import LoginButton from "shared/LoginButton";
import { toHms } from "helpers/utils";
import {
  WARN_PROVIDE_EMAIL_OTP,
  WARN_ENTER_SMS_OTP,
  WARN_SETUP_SMS_OTP
} from "constants/warnings";
import {
  EXPIRE_OTP_AFTER_SECONDS,
  RESEND_OTP_AFTER_SECONDS
} from "constants/common";
import { ERR_INVALID_OTP } from "constants/errors";
import BackToLogin from "./BackToLogin";

const FormFields = () => {
  const { state, send } = useLoginMachine();
  const expireOTPAfterSeconds = state.context.expireOTPAfterSeconds;
  const [countdown, setCountDown] = useState(expireOTPAfterSeconds);

  const [activateResendOtp, setActivateResendOtp] = useState(false);
  const [otp, setupOTP] = useState("");
  const isLoading = state.matches("enterOTPScene.loading");
  const otpResentSuccessfully = state.matches("enterOTPScene.resendOTPSuccess");
  const isEmailOTPRequired =
    state.context.loginResponse?.warning?.code === WARN_PROVIDE_EMAIL_OTP;
  const isSMSOTPRequired =
    state.context.loginResponse?.warning?.code === WARN_ENTER_SMS_OTP ||
    state.context.loginResponse?.warning?.code === WARN_SETUP_SMS_OTP;
  const isInvalidOTPErrorExist =
    state.context?.submitOTPError?.code === ERR_INVALID_OTP;

  const resendOtp = (e) => {
    e.preventDefault();
    send({ type: "RESEND", seconds: countdown });
  };

  useEffect(() => {
    const interval = setInterval(
      () => setCountDown(Math.max(0, countdown - 1)),
      1000
    );

    if (countdown <= 0) {
      send({ type: "GO_TO_LOGIN" });
    }

    return () => clearInterval(interval);
  }, [countdown, send]);

  useEffect(() => {
    if (countdown <= EXPIRE_OTP_AFTER_SECONDS - RESEND_OTP_AFTER_SECONDS) {
      setActivateResendOtp(true);
    }
  }, [setActivateResendOtp, countdown]);

  useEffect(() => {
    if (state.matches("enterOTPScene.failure")) {
      setupOTP("");
    }
  }, [state]);

  useEffect(() => {
    if (otpResentSuccessfully) {
      setCountDown(expireOTPAfterSeconds);
    }
  }, [otpResentSuccessfully, expireOTPAfterSeconds]);

  let message = "Enter TOTP generated on the Authenticator App";

  if (isEmailOTPRequired) {
    message = "Enter OTP sent on your registered Email ID";
  }
  if (isSMSOTPRequired) {
    message = "Enter OTP sent on your registered mobile number";
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    send({ type: "SUBMIT", otp });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div data-testid="otp-form">
        <Label text={message} className="oui-w-100 oui-m-0 oui-my-4" />
        <div className="oui-w-100" style={{ marginBottom: "1.5rem" }}>
          <OtpInput
            value={otp}
            onChange={(otp) => setupOTP(otp)}
            numInputs={6}
            separator={<span> </span>}
            inputStyle="oui-form-control otp-input"
            isInputNum
            errorStyle={{ borderColor: "#dc3545" }}
            containerStyle={{ marginBottom: "0.5rem" }}
            hasErrored={state.matches("enterOTPScene.failure")}
            shouldAutoFocus
            data-testid="otp-input"
          />
          {isInvalidOTPErrorExist && (
            <InputFeedback show message="Invalid OTP" />
          )}
        </div>
        <LoginButton
          dataTestId="submit-btn"
          label="Verify"
          className="oui-mr-0 oui-mt-2"
          disabled={!(otp?.length === 6) || isLoading}
          isLoading={isLoading}
        />
        <div className="oui-mt-3 oui-w-100">
          <span style={{ fontSize: "0.75rem" }}>
            The session will expire in&nbsp;
            <strong>{toHms(countdown)}</strong>&nbsp;mins.
          </span>{" "}
          {!otpResentSuccessfully && isEmailOTPRequired && (
            <Button
              type="link"
              label="Resend Email"
              onClick={resendOtp}
              disabled={!activateResendOtp}
              className="oui-pt-1 oui-pl-2"
            />
          )}
        </div>
      </div>
    </Form>
  );
};

const EnterOTPScene = () => {
  const { state } = useLoginMachine();
  return (
    <Loader isLoading={state.matches("enterOTPScene.resendingOTP")}>
      <AuthCard dataTestId="otp-form-card" title="">
        <FormFields />
        <div className="oui-py-3">
          <BackToLogin />
        </div>
      </AuthCard>
    </Loader>
  );
};

export default EnterOTPScene;
