import { useQuery } from "react-query";
import { fetchUiCustomizations } from "apis/common.apis.js";

const useFetchUiCustomizationsQuery = () => {
  return useQuery({
    queryKey: "fetch-ui-customizations",
    queryFn: fetchUiCustomizations,
    refetchOnMount: false
  });
};

export { useFetchUiCustomizationsQuery };
