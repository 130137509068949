import Toast from "orion/lib/toast";
import { useSelector, useDispatch } from "react-redux";
import { hideToast as hideToastAction } from "slices/toast.slice";

const ToastManager = (props) => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toasts.toasts);
  const hideToast = (toast) => dispatch(hideToastAction(toast.message));

  return <Toast alerts={toasts} onClose={hideToast} {...props} />;
};

export default ToastManager;
