import { useState, useEffect, useRef } from "react";
import Input from "orion/lib/input";
import Popover from "orion/lib/popover";
import { isElementInvalid } from "helpers/utils";
import PasswordCheckList from "./PasswordCheckList";
import MaxCharsFeedback from "./MaxCharsFeedback";
import "./style.scss";

const PasswordField = ({
  name,
  validationProps,
  placeholder,
  forceStrongPassword,
  showPasswordStrength,
  focusOnMount,
  dataTestId
}) => {
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const toggle = () => setOpen(!open);
  const inputRef = useRef(null);
  const value = validationProps.values[name];

  useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusOnMount]);

  return showPasswordStrength ? (
    forceStrongPassword ? (
      <Popover
        hideOnOutsideClick
        toggle={toggle}
        isOpen={open}
        placement="right"
        size="auto"
        body={() => (
          <PasswordCheckList password={validationProps.values[name]} />
        )}
      >
        <Input
          data-testid={dataTestId}
          innerRef={inputRef}
          name={name}
          type="password"
          validationProps={validationProps}
          isInvalid={isElementInvalid(validationProps, name) && !focused}
          placeholder={placeholder}
          onFocus={() => {
            setFocused(true);
            setTimeout(() => {
              setOpen(true);
            }, 200);
          }}
          onBlur={() => {
            setFocused(false);
            setOpen(false);
          }}
        />
        {forceStrongPassword && focused ? (
          <MaxCharsFeedback password={value} />
        ) : null}
      </Popover>
    ) : (
      <Input
        data-testid={dataTestId}
        innerRef={inputRef}
        name={name}
        type="password"
        validationProps={validationProps}
        isInvalid={isElementInvalid(validationProps, name) && !focused}
        placeholder={placeholder}
        onFocus={() => {
          setFocused(true);
          setTimeout(() => {
            setOpen(true);
          }, 200);
        }}
        onBlur={() => {
          setFocused(false);
          setOpen(false);
        }}
      />
    )
  ) : (
    <>
      <Input
        data-testid={dataTestId}
        innerRef={inputRef}
        name={name}
        type="password"
        validationProps={validationProps}
        isInvalid={isElementInvalid(validationProps, name) && !focused}
        placeholder={placeholder}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
      {forceStrongPassword && value.length && focused ? (
        <MaxCharsFeedback password={value} />
      ) : null}
    </>
  );
};
export default PasswordField;
