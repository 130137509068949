export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_LOGOUT = "/postlogin/logout";
export const ROUTE_OTHER_ACCOUNTS = "/postlogin/accounts";
export const ROUTE_CHANGE_PASSWORD = "/postlogin/reset-password";
export const ROUTE_SET_PASSWORD = "/login/set-password";
export const ROUTE_FORGOT_PASSWORD = "/login/forgot-password";
export const ROUTE_LICENSE_AGREEMENT = "/postlogin/license-agreement";
export const ROUTE_FEDRAMP_DISCLAIMER = "/postlogin/fedramp-disclaimer";
export const ROUTE_SAML_ERROR = "/login/saml-error";
